import { CREATE_UNCONFIRMED_SIMILAR_OFFER_SEARCH_MUTATION } from '@domains/ad/graphql/mutations/createUnconfirmedSimilarOfferSearchMutation';
import { AD_SIMILAR_OFFER_REGISTER_PREFIX } from '@domains/ad/hooks/useAdSimilarOfferRegister';
import { logError } from '@domains/shared/helpers/logger';
import { useLocalStorageCache } from '@domains/shared/hooks/useLocalStorageCache/useLocalStorageCache';
import { useMutation } from 'urql';

type OnSuccessfulEmailRegister = (adId: number, email: string) => void;

export const useSimilarOfferSuccessfulEmailRegister = (): OnSuccessfulEmailRegister => {
    const [, createUnconfirmedSimilarOfferSearch] = useMutation(CREATE_UNCONFIRMED_SIMILAR_OFFER_SEARCH_MUTATION);
    const [, setAdSimilarOfferRegister] = useLocalStorageCache(AD_SIMILAR_OFFER_REGISTER_PREFIX);
    function onSuccessfulEmailRegister(adId: number, email: string): void {
        createUnconfirmedSimilarOfferSearch({ advertID: adId, email })
            .then((response) => {
                const similarOffer = response.data?.createUnconfirmedSimilarOfferSearch;

                const isOperationError =
                    similarOffer?.__typename === 'OperationSearchError' && similarOffer.error !== undefined;
                const isOperationSuccess =
                    similarOffer?.__typename === 'OperationSearchSuccess' && similarOffer.success;

                if (isOperationError) {
                    setAdSimilarOfferRegister({ adId, email, toastType: 'error' });
                    logError('Saving similar ad offer on registration failed', { errorMessage: similarOffer.error });

                    return;
                }

                if (isOperationSuccess) {
                    setAdSimilarOfferRegister({ adId, email, toastType: 'success' });

                    return;
                }
            })
            .catch((error) => {
                setAdSimilarOfferRegister({ adId, email, toastType: 'error' });
                logError('Saving similar ad offer on registration failed', { error });
            });
    }

    return onSuccessfulEmailRegister;
};
