import type { GenericGraphQLError } from '@lib/graphql/error';
import { gql } from '@urql/core';

interface SimilarAdSearchExistResponse {
    similarAdSearchExists:
        | GenericGraphQLError
        | {
              __typename: 'SearchExists';
              exists: boolean;
          };
}

interface SimilarAdSearchExistVariables {
    adID: number;
}

export const SIMILAR_AD_SEARCH_EXISTS_QUERY = gql<SimilarAdSearchExistResponse, SimilarAdSearchExistVariables>`
    query SimilarAdSearchExists($adID: ID!) {
        similarAdSearchExists(adID: $adID) {
            ... on SearchExists {
                exists
            }
        }
    }
`;
