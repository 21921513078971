import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import type { FC } from 'react';
import { useContext } from 'react';

export const AdBottomAdvert: FC = () => {
    const { isDesktop } = useContext(RWDContext);
    useBaxterAdvertising();

    if (!isDesktop) {
        return null;
    }

    return <AdvertSlot id="baxter-a-bottom" />;
};
