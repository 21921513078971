import { ToastLabelWithUndoButton } from '@domains/shared/components/ToastLabelWithUndoButton/ToastLabelWithUndoButton';
import { OBSERVE_AD_DATA_CACHE } from '@domains/shared/hooks/useFavoritesSubscriptionHash/useFavoritesSubscriptionHash';
import { useLocalStorageCache } from '@domains/shared/hooks/useLocalStorageCache/useLocalStorageCache';
import { useTracking } from '@lib/tracking/useTracking';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import { useCallback } from 'react';

interface ToggleSubscription {
    id: number;
    trackingData?: Record<string, unknown>;
    toastLabelWithUndoButton?: JSX.Element;
    savedAdNote?: string;
    adCreationTimestamp?: number;
}

interface UseSubscribeButtonHandlerProps {
    id: number;
    isFavorite: boolean;
    isUserLoggedIn?: boolean;
    trackingParams: {
        ad_id: number;
    };
    setIsRedirectToLoginModalOpen?: (value: boolean) => void;
    setSimilarAdsPromptId?: SetSimilarAdsPromptId;
    toggleSubscription: (params: ToggleSubscription) => Promise<boolean | undefined>;
    onStateChange?: (isFavourite: boolean, id: number) => void;
    onClick?: () => void;
    shouldShowUndoButtonInToast?: boolean;
}

export const useSubscribeButtonHandler = ({
    id,
    isFavorite,
    isUserLoggedIn,
    trackingParams,
    setIsRedirectToLoginModalOpen,
    setSimilarAdsPromptId,
    toggleSubscription,
    onStateChange,
    onClick,
    shouldShowUndoButtonInToast,
}: UseSubscribeButtonHandlerProps): { handleSubscribeButtonClick: () => void } => {
    const { trackEvent } = useTracking();
    const [, setObserveAdData] = useLocalStorageCache(OBSERVE_AD_DATA_CACHE);

    const handleTracking = useCallback((): void => {
        if (!isFavorite) {
            trackEvent('favourite_ad_click', trackingParams);
        }
    }, [isFavorite, trackEvent, trackingParams]);

    const handleUserNotLoggedIn = useCallback((): void => {
        setObserveAdData({ id, trackingData: trackingParams });
        setIsRedirectToLoginModalOpen?.(true);
    }, [id, setIsRedirectToLoginModalOpen, setObserveAdData, trackingParams]);

    const handleSubscriptionToggle = useCallback((): Promise<void> => {
        const toastLabelWithUndoButton = shouldShowUndoButtonInToast ? (
            <ToastLabelWithUndoButton adId={id} />
        ) : undefined;

        return toggleSubscription({
            id,
            trackingData: trackingParams,
            toastLabelWithUndoButton,
        }).then((isFavourite) => {
            if (isFavourite !== undefined) {
                if (isFavourite) {
                    setSimilarAdsPromptId?.(id);
                }
                onStateChange?.(isFavourite, id);
            }
        });
    }, [id, onStateChange, setSimilarAdsPromptId, shouldShowUndoButtonInToast, toggleSubscription, trackingParams]);

    const handleSubscribeButtonClick = useCallback((): void => {
        onClick?.();
        handleTracking();

        if (!isUserLoggedIn) {
            handleUserNotLoggedIn();

            return;
        }

        handleSubscriptionToggle();
    }, [onClick, handleTracking, isUserLoggedIn, handleSubscriptionToggle, handleUserNotLoggedIn]);

    return { handleSubscribeButtonClick };
};
