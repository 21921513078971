export const calculateMortgage = (
    mortgageInterest: number,
    mortgageYears: number,
    presentValue: number,
    futureValue: number,
    type: 1 | 0,
): number => {
    const ratePerPeriod = mortgageInterest / 12; // Annual interest into months
    const numberOfPayments = mortgageYears * 12; // Total months for life of loan

    if (ratePerPeriod !== 0) {
        // Interest rate exists
        const pvif = Math.pow(1 + ratePerPeriod, numberOfPayments); //Present value interest factor

        return (ratePerPeriod * (futureValue + pvif * presentValue)) / ((-1 + pvif) * (1 + ratePerPeriod * type));
    } else if (numberOfPayments !== 0) {
        // No interest rate, but number of payments exists
        return (futureValue + presentValue) / numberOfPayments;
    }

    return 0;
};
