import { Button } from '@domains/shared/components/Button/Button';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { PhoneNumber } from '@domains/shared/components/PhoneNumber/PhoneNumber';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { SIZE, WEIGHT } from '@lib/styles/partials/typography';

import { CONTACT_FORM_WIDGET_THEME } from '../theme/[[[SITECODE]]]';

export const ContainerDefault = styled.div`
    display: grid;
    grid-template-areas:
        'avatar name'
        'avatar adType'
        'avatar phoneNumber';
    grid-template-columns: 52px auto;
    grid-template-rows: repeat(3, auto);
    align-items: start;
    width: 100%;
    background-color: ${CONTACT_FORM_WIDGET_THEME.contactPersonBanner.backgroundColor};
    color: ${CONTACT_FORM_WIDGET_THEME.contactPersonBanner.color};

    @media (min-width: ${BREAKPOINT.sm}) {
        grid-template-areas:
            'avatar name'
            'avatar adType'
            'avatar phoneNumber';
        grid-template-rows: repeat(3, auto);
    }
`;

export const ContainerStandAlone = styled(ContainerDefault)`
    display: grid;
    grid-template-areas:
        'avatar name'
        'avatar adType'
        '. logotype'
        '. phoneNumber'
        'contactButton contactButton';
    grid-template-rows: repeat(2, 20px) auto repeat(2, 50px);
    align-items: center;
    min-height: 72px;
    padding: 16px;

    @media (min-width: ${BREAKPOINT.sm}) {
        grid-template-areas:
            'avatar name logotype phoneNumber contactButton'
            'avatar adType logotype phoneNumber contactButton';
        height: 70px;
    }
`;

export const PhoneIcon = styled(Icon)`
    margin-right: 8px;
    color: ${CONTACT_FORM_WIDGET_THEME.contactPersonBanner.phoneIcon.color};
`;

export const AvatarContainer = styled.div`
    grid-area: avatar;
`;

export const ContactPersonName = styled.span`
    grid-area: name;
    overflow: hidden;
    font-weight: ${WEIGHT.bold};
    text-overflow: ellipsis;
    white-space: nowrap;
`;

export const PhoneNumberContainer = styled.div`
    display: flex;
    grid-area: phoneNumber;
    align-items: center;
    width: 220px;
`;

export const PhoneNumberDisplay = styled(PhoneNumber)<{ variant: string }>`
    color: ${CONTACT_FORM_WIDGET_THEME.contactPersonBanner.phoneNumberDisplay.color};
    font-size: ${({ variant }): string => (variant === 'default' ? 'inherit' : SIZE.p2)};
`;

export const AdTypeContainer = styled.div`
    grid-area: adType;
`;

export const LogotypeContainer = styled.img`
    grid-area: logotype;
    max-width: 120px;
    max-height: 44px;
    padding: 16px 0;

    @media (min-width: ${BREAKPOINT.sm}) {
        padding: 0;
    }
`;

export const ContactButton = styled(Button)`
    grid-area: contactButton;
    padding: 8px 4px;

    @media (min-width: ${BREAKPOINT.sm}) {
        max-width: 180px;
    }
`;
