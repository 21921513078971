import type { CreationSource } from '@domains/ad/types/Ad';

interface Props {
    creationSource?: CreationSource;
    externalId?: string;
    referenceId?: string;
}

export const getExternalId = ({ creationSource, referenceId, externalId }: Props): string | null => {
    if (!creationSource) {
        return null;
    }

    if (creationSource === 'import') {
        return externalId || null;
    }

    return referenceId || null;
};
