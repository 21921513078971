import { AD_CONFIG } from '@config/ad/adConfig';
import { EXPERIMENT } from '@domains/ad/consts/experiment';
import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import { getAdDetailsMainSection } from '@domains/ad/helpers/getAdDetails';
import { useGetAdLocationAddress } from '@domains/ad/hooks/useGetAdLocationAddress';
import { extractHighlightsFromExtras } from '@domains/ad/nexusComponents/AdDetails/helpers/extractHighlightsFromExtras';
import { AdDetailsHighlights } from '@domains/ad/nexusComponents/AdDetailsHighlights/AdDetailsHighlights';
import { getAdUnitRenderConditions } from '@domains/ad/shared/helpers/getAdUnitRenderConditions';
import type { Ad } from '@domains/ad/types/Ad';
import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useTracking } from '@lib/tracking/useTracking';
import { ADVERT_TYPE } from '@type/ad/attributes/advertType';
import type { JSX } from 'react';
import { useContext, useRef } from 'react';

import {
    AdUnit,
    AdUnitContainer,
    Container,
    ExclusiveOfferTag,
    PriceSection,
    TagSection,
    Title,
} from './AdHeader.theme';
import { AdPrice } from './components/AdPrice/AdPrice';
import { FinancingButton } from './components/FinancingButton/FinancingButton';
import { InvestmentUnit } from './components/InvestmentUnit/InvestmentUnit';
import { MapLink } from './components/MapLink/MapLink';
import { OpenDayTag } from './components/OpenDayTag/OpenDayTag';

interface AdHeaderProps {
    ad: Ad;
    shouldShowMortgageCalculator: boolean;
}

export const AdHeader = ({ ad, shouldShowMortgageCalculator }: AdHeaderProps): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const {
        title,
        category,
        location,
        characteristics,
        advertType,
        developmentId,
        exclusiveOffer: isExclusiveOffer,
        developmentUrl,
        developmentTitle,
        openDay,
        adCategory,
    } = ad;

    const getData = getAdCharacteristic(characteristics);
    const isInvestment = advertType === ADVERT_TYPE.developer;
    const isInvestmentUnit = !!developmentId;
    const price = isInvestment ? getData('price_per_m_from') : getData('price');
    const pricePerMeter = getData('price_per_m');
    const isForRent = category.id % 2 === 0;

    useBaxterAdvertising();

    const { isVariantEnabled } = useExperiments();
    const isEURE25554VariantB = isVariantEnabled(EXPERIMENT.eure25554, 'b');
    const isEURE25554VariantC = isVariantEnabled(EXPERIMENT.eure25554, 'c');
    const isEURE2554Enabled = isEURE25554VariantB || isEURE25554VariantC;

    const { shouldRenderBaxterAdsMobileAdUnit, shouldRenderBaxterAdsDesktopAdUnit } = getAdUnitRenderConditions({
        isDesktop: !!isDesktop,
        isForRent,
    });

    const isDesktopAdRendered = shouldRenderBaxterAdsDesktopAdUnit;
    const isMobileAdRendered = shouldRenderBaxterAdsMobileAdUnit;

    const locationAddress = useGetAdLocationAddress(location);

    const investmentTagButtonRef = useRef(null);
    useTrackImpression(investmentTagButtonRef, () => {
        trackEvent('parent_investment_link_impression', {
            touch_point_button: 'under_gallery',
        });
    });

    const adHeaderRef = useRef<HTMLDivElement | null>(null);
    useTrackImpression(adHeaderRef, () => {
        trackEvent('ad_page_impression', {
            touch_point_button: 'main_info',
        });
    });

    const isOpenDay = !!openDay?.date;

    const { adMortgageSimulatorConfig } = AD_CONFIG;

    const { type: transaction } = adCategory;

    const shouldShowFinancingButton =
        shouldShowMortgageCalculator && adMortgageSimulatorConfig && price && price.value !== '';

    const rent = characteristics.find((item) => item.key === 'rent');

    const mainSectionKeys = isEURE2554Enabled ? getAdDetailsMainSection(ad.adCategory) : { highlights: [] };
    const adHighlights =
        isEURE2554Enabled && mainSectionKeys
            ? extractHighlightsFromExtras(
                  mainSectionKeys.highlights.map((highlight) => highlight.key),
                  ad.additionalInformation.find((item) => item.label === 'extras_types'),
                  t,
              )
            : [];

    return (
        <Container ref={adHeaderRef}>
            {!isEURE2554Enabled && (
                <Title data-cy="adPageAdTitle" data-testid="ad-title">
                    {title}
                </Title>
            )}
            <PriceSection>
                <AdPrice
                    price={price}
                    isInvestment={isInvestment}
                    pricePerMeter={pricePerMeter}
                    transaction={transaction}
                    rent={rent}
                />
                {shouldShowFinancingButton ? (
                    <FinancingButton price={price} simulatorConfig={adMortgageSimulatorConfig} />
                ) : null}
            </PriceSection>
            <MapLink address={locationAddress} />
            {isEURE2554Enabled && mainSectionKeys && adHighlights && (
                <AdDetailsHighlights
                    propertyHighlights={mainSectionKeys.highlights}
                    fullAdDetails={[...adHighlights, ...ad.additionalInformation, ...ad.topInformation]}
                />
            )}
            {isExclusiveOffer || isOpenDay ? (
                <TagSection>
                    {isExclusiveOffer ? (
                        <ExclusiveOfferTag content={t('frontend.ad.header.exclusive-offer-tag-tertiary')} />
                    ) : null}
                    {isOpenDay ? <OpenDayTag openDay={openDay} /> : null}
                </TagSection>
            ) : null}
            <InvestmentUnit
                isInvestmentUnit={isInvestmentUnit}
                href={developmentUrl}
                label={developmentTitle}
                buttonRef={investmentTagButtonRef}
            />
            {isDesktopAdRendered ? (
                <AdUnitContainer>
                    {shouldRenderBaxterAdsDesktopAdUnit ? (
                        <AdUnit data-testid="baxter-ad-unit">
                            <AdvertSlot id="baxter-a-under-price" />
                        </AdUnit>
                    ) : null}
                </AdUnitContainer>
            ) : null}
            {isMobileAdRendered ? (
                <AdUnitContainer>
                    {shouldRenderBaxterAdsMobileAdUnit ? (
                        <AdUnit data-testid="baxter-ad-unit">
                            <AdvertSlot id="baxter-a-under-price" />
                        </AdUnit>
                    ) : null}
                </AdUnitContainer>
            ) : null}
        </Container>
    );
};
