import type { AdInformation, ExtendedAdInformation } from '@type/ad/adDetails';

const SAFETY_KEY = 'safety';
const SECURITY_KEY = 'security_types';

const BUILDING_SAFETY_FEATURES = new Set(['security_types::closed_area', 'security_types::monitoring']);

export const mapSafetyFeatures = (
    adInformation: ExtendedAdInformation[],
): (AdInformation | ExtendedAdInformation)[] => {
    const securityFeatures = adInformation.find((information) => information.label === SECURITY_KEY);
    if (!securityFeatures || securityFeatures.values.length === 0) {
        return [];
    }

    const { values } = securityFeatures;

    const safety: string[] = [];
    const security: string[] = [];

    for (const value of values) {
        const valueAsString = String(value);
        if (BUILDING_SAFETY_FEATURES.has(valueAsString)) {
            safety.push(valueAsString);
        } else {
            security.push(valueAsString);
        }
    }

    return [
        {
            unit: '',
            label: SAFETY_KEY,
            values: safety,
        },
        {
            unit: '',
            label: SECURITY_KEY,
            values: security,
        },
    ];
};
