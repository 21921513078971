import type {
    OpenGalleryFunction,
    OpenGalleryRef,
} from '@domains/ad/components/ProjectPage/components/ProjectMediaGallery/openGalleryRef';
import { getContactFormAdvertProps } from '@domains/ad/helpers/getContactSectionAdvertData';
import { getGalleryData } from '@domains/ad/helpers/getGalleryData';
import { ImageGalleryRenderItem } from '@domains/ad/shared/components/ImageGallery/ImageGalleryRenderItem';
import type { Ad } from '@domains/ad/types/Ad';
import type { ImageSet } from '@domains/ad/types/ImageSet';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useClickOutside } from '@domains/shared/hooks/useClickOutside/useClickOutside';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { CONTACT_FORM_TOUCH_POINT_BUTTON } from '@widgets/contactForm/types/contactForm';
import type { Dispatch, JSX, MutableRefObject, RefObject, SetStateAction } from 'react';
import { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import type { ReactImageGalleryItem } from 'react-image-gallery';
import type ReactImageGallery from 'react-image-gallery';
import { useUpdateEffect } from 'react-use';

interface Arguments {
    advert: Ad;
    isAdvancedGallery: boolean;
    shouldDisplayPredefinedContactMessages?: boolean;
    openGalleryRef?: OpenGalleryRef;
    galleryTrackingData?: Record<string, string>;
}

export interface ProjectImageGalleryMethods {
    currentIndex: number;
    galleryImageSets: ReactImageGalleryItem[];
    galleryRef: MutableRefObject<ReactImageGallery | null>;
    galleryWrapperRef: RefObject<HTMLDivElement>;
    isContactFormSlide: boolean;
    isDesktop: boolean;
    isFullScreen: boolean;
    isGallery: boolean;
    isKeyboardNavigationEnabled: boolean;
    isTransitionInProgress: boolean;
    memoizedInitialImageSets: ReactImageGalleryItem[];
    transition: number;
    goToContactFormSlide: () => void;
    onImageClick: () => void;
    reassignGalleryImages: (isFullScreen: boolean) => void;
    setIsFullScreen: Dispatch<SetStateAction<boolean>>;
    setCurrentIndex: Dispatch<SetStateAction<number>>;
    setGalleryImageSets: Dispatch<SetStateAction<ReactImageGalleryItem[]>>;
    setIsContactFormSlide: Dispatch<SetStateAction<boolean>>;
    setIsGallery: Dispatch<SetStateAction<boolean>>;
    setIsKeyboardNavigationEnabled: Dispatch<SetStateAction<boolean>>;
    setIsTransitionInProgress: Dispatch<SetStateAction<boolean>>;
    setTransition: Dispatch<SetStateAction<number>>;
}

export const useProjectImageGallery = ({
    advert,
    isAdvancedGallery,
    shouldDisplayPredefinedContactMessages,
    openGalleryRef,
    galleryTrackingData,
}: Arguments): ProjectImageGalleryMethods => {
    const galleryWrapperRef = useRef<HTMLDivElement>(null);
    const galleryRef = useRef<ReactImageGallery | null>(null);

    const [transition, setTransition] = useState(450);
    const { trackEvent } = useTracking();
    const [t] = useTranslations();

    const { plans, images, title, id, advertiserType, agency, contactDetails, owner } = advert;

    const [isTransitionInProgress, setIsTransitionInProgress] = useState(false);

    const { isDesktop } = useContext(RWDContext);

    const [isKeyboardNavigationEnabled, setIsKeyboardNavigationEnabled] = useState(false);

    const memoizedInitialImageSets = useMemo(() => {
        return getGalleryData({
            images: plans?.length ? [...images, ...plans] : images,
            title,
            t,
        });
    }, [plans, images, t, title]);

    const [currentIndex, setCurrentIndex] = useState(0);
    const [isFullScreen, setIsFullScreen] = useState(false);
    const [galleryImageSets, setGalleryImageSets] = useState(memoizedInitialImageSets);
    const [isContactFormSlide, setIsContactFormSlide] = useState(false);
    /**
     * We need this new piece of state because `ReactImageGallery` adds `fullscreen` event listener.
     * When user opened the MapView ('ad/components/AdMap/MapView') in full screen
     * this `onScreenChange` from `ReactImageGallery` was called with `false` value
     * and we tracked not proper event - 'gallery_close'.
     */
    const [isGallery, setIsGallery] = useState(false);

    const openFullScreen = useCallback<OpenGalleryFunction>(
        (customTrackingData, apartmentPlansIndex): void => {
            setIsGallery(true);
            galleryRef?.current?.fullScreen();
            // Clicking on Contact form slide doesn't close the gallery,
            // but keeps tracking 'gallery_open' event.
            // To prevent it, 'gallery_open' is not tracked when Contact form slide
            // is being clicked around.
            // Contact form is available only in fullscreen mode,
            // so 'gallery_open' event will be tracked anyways.
            // Contact form slide should be refactored so that clicking around the Form
            // would trigger 'gallery_close', but when clicked on the Form - only form-related
            // events. The task has been created.
            if (!isContactFormSlide && !isFullScreen) {
                trackEvent('gallery_open', customTrackingData);
            }

            if (apartmentPlansIndex) {
                setCurrentIndex(apartmentPlansIndex);
            }
        },
        [isContactFormSlide, trackEvent, isFullScreen],
    );

    const onImageClick = useCallback(
        (): void => openFullScreen(galleryTrackingData),
        [openFullScreen, galleryTrackingData],
    );

    useEffect(() => {
        setGalleryImageSets(memoizedInitialImageSets);
    }, [memoizedInitialImageSets]);

    useEffect(() => {
        if (openGalleryRef) {
            openGalleryRef.current = openFullScreen;
        }

        return (): void => {
            if (openGalleryRef) {
                openGalleryRef.current = null;
            }
        };
    }, [openFullScreen, openGalleryRef]);

    useClickOutside(galleryWrapperRef, () => {
        setIsKeyboardNavigationEnabled(false);
    });

    useUpdateEffect(() => {
        // restore transition-duration after exiting fullscreen mode
        // BUT shortly after the images have been updated (setTimeout);
        const timeoutId = setTimeout(() => setTransition(450), 50);

        return (): void => clearTimeout(timeoutId);
    }, [transition]);

    const goToContactFormSlide = useCallback((): void => {
        galleryRef.current?.slideToIndex(galleryImageSets.length - 1);

        trackEvent('reply_message_form_click', {
            touch_point_button: CONTACT_FORM_TOUCH_POINT_BUTTON.galleryOpen,
        });
    }, [galleryImageSets.length, trackEvent]);

    const reassignGalleryImages = useCallback(
        (isFullScreen: boolean): void => {
            setGalleryImageSets((): ReactImageGalleryItem[] | ImageSet[] => {
                const shouldAddContactForm = isAdvancedGallery && isDesktop && isFullScreen;

                const initial = memoizedInitialImageSets.map((set) => ({
                    ...set,
                    imageSet: shouldAddContactForm ? [] : (set as ImageSet).originalImageSet,
                }));
                if (!shouldAddContactForm) {
                    return initial;
                }

                const source = initial[0].original;

                return [
                    ...initial,
                    {
                        renderItem: (): JSX.Element => (
                            <ImageGalleryRenderItem
                                source={source}
                                advertId={id}
                                advertiserType={advertiserType}
                                contactDetails={contactDetails}
                                ownerType={owner.type}
                                agencyLogoUrl={agency?.imageUrl}
                                contactFormAdvertProps={getContactFormAdvertProps(advert)}
                                shouldDisplayPredefinedContactMessages={shouldDisplayPredefinedContactMessages}
                            />
                        ),
                        original: '',
                    },
                ];
            });
        },
        [
            advert,
            advertiserType,
            agency?.imageUrl,
            contactDetails,
            id,
            isAdvancedGallery,
            isDesktop,
            memoizedInitialImageSets,
            owner.type,
            shouldDisplayPredefinedContactMessages,
        ],
    );

    return {
        currentIndex,
        galleryImageSets,
        galleryRef,
        galleryWrapperRef,
        isContactFormSlide,
        isDesktop: Boolean(isDesktop),
        isFullScreen,
        isGallery,
        isKeyboardNavigationEnabled,
        isTransitionInProgress,
        memoizedInitialImageSets,
        transition,
        goToContactFormSlide,
        onImageClick,
        reassignGalleryImages,
        setTransition,
        setCurrentIndex,
        setGalleryImageSets,
        setIsContactFormSlide,
        setIsFullScreen,
        setIsGallery,
        setIsKeyboardNavigationEnabled,
        setIsTransitionInProgress,
    };
};
