import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { P3, P5 } from '@nexus/lib-react/dist/core/Typography';
import { FooterButtons } from '@widgets/CompareAds/CompareAdsDialog/components/FooterButtons/FooterButtons';
import { Message } from '@widgets/CompareAds/CompareAdsDialog/components/Message/Message';
import type { SelectedRate } from '@widgets/CompareAds/CompareAdsDialog/components/RateButtons/RateButtons';
import { RateButtons } from '@widgets/CompareAds/CompareAdsDialog/components/RateButtons/RateButtons';
import type { JSX } from 'react';
import { useState } from 'react';

export const DialogContent = (): JSX.Element => {
    const [t] = useTranslations();
    const [selectedRate, setSelectedRate] = useState<SelectedRate>(null);
    const [message, setMessage] = useState('');
    const [shouldShowRateError, setShouldShowRateError] = useState(false);
    const [shouldShowMessageError, setShouldShowMessageError] = useState(false);

    return (
        <>
            <P3>{t('frontend.widget.compare-ads.description')}</P3>
            <RateButtons
                selectedRate={selectedRate}
                onChangeSelectedRate={setSelectedRate}
                showError={shouldShowRateError}
            />
            <Message
                message={message}
                selectedRate={selectedRate}
                onMessageChange={setMessage}
                showError={shouldShowMessageError}
            />
            <P5>{t('frontend.widget.compare-ads.privacy-policy-info')}</P5>
            <FooterButtons
                onSelectedRateError={() => setShouldShowRateError(true)}
                selectedRate={selectedRate}
                message={message}
                onMessageError={() => setShouldShowMessageError(true)}
            />
        </>
    );
};
