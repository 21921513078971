import type { SecondarySection } from '@domains/ad/helpers/getAdDetails';
import type { AdFullInformation } from '@domains/ad/types/adFullInformation';

export const hasItems = (sectionItems: SecondarySection, adInformation: AdFullInformation): boolean => {
    return Object.values(sectionItems.keys).some((item) => {
        const adDetail = adInformation.find(({ label }) => label === item);

        return adDetail && adDetail.values.length > 0;
    });
};
