import { RecommendedAdItemDetails } from '@domains/ad/nexusComponents/RecommendedAdItem//RecommendedAdItemDetails';
import { getAdvertHref } from '@domains/ad/nexusComponents/RecommendedAdItem/helpers/getAdvertHref';
import {
    Container,
    ImageWrapper,
    StyledImage,
    StyledLink,
} from '@domains/ad/nexusComponents/RecommendedAdItem/RecommendedAdItem.theme';
import type { RecommendedAd } from '@domains/ad/types/Ad';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { useAdvertImpressionsTracking } from '@domains/shared/hooks/useAdvertImpressionsTracking';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useRef } from 'react';

interface Props {
    ad: RecommendedAd;
    adsType: 'similar-ads' | 'user-ads';
}

export const RecommendedAdItem = ({ ad, adsType }: Props): JSX.Element => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const { trackEvent } = useTracking();
    const trackingParam = adsType === 'similar-ads' ? 'similar_ads_recommendations' : 'more_from_seller';
    const { title, url, images, adId } = ad;

    const href = getAdvertHref(url);
    const thumbnail = images[0]?.small;

    useAdvertImpressionsTracking(containerRef, {
        touch_point_button: trackingParam,
        ad_id: adId,
    });

    const handleClick = (isNewTab: boolean): void => {
        const touchPointButton = isNewTab ? `${trackingParam}_new_tab` : trackingParam;

        trackEvent('ad_click', {
            touch_point_button: touchPointButton,
            ad_id: adId,
        });
    };

    return (
        <Container
            ref={containerRef}
            data-testid="recommended-ads-item"
            onClick={(): void => handleClick(false)}
            onContextMenu={(): void => handleClick(true)}
        >
            <LocalLink href={href} passHref>
                <StyledLink data-cy="similar-ad-link">
                    <ImageWrapper>{thumbnail ? <StyledImage src={thumbnail} alt={title} /> : null}</ImageWrapper>
                    <RecommendedAdItemDetails ad={ad} adsType={adsType} />
                </StyledLink>
            </LocalLink>
        </Container>
    );
};
