import { SITE_CONFIG } from '@config/siteConfig';
import { AdHeadMetadata } from '@domains/ad/components/AdHeadMetadata/AdHeadMetadata';
import { SIMILAR_OFFERS_PARAMETER } from '@domains/ad/components/AdSimilarOffersButton/AdSimilarOffersButton';
import { AdPageModalContextProvider } from '@domains/ad/contexts/AdPageModalContext';
import { LoginModalContext, LoginModalContextProvider } from '@domains/ad/contexts/LoginModalContext';
import { ReportAdModalContextProvider } from '@domains/ad/contexts/ReportAdModalContext';
import { SavedSimilarAdsSearchContextProvider } from '@domains/ad/contexts/SavedSimilarAdsSearchContext';
import { USER_FAVORITE_CHECK_QUERY } from '@domains/ad/graphql/queries/UserFavoriteCheckQuery';
import { isPhoneNumberAvailable } from '@domains/ad/helpers/isPhoneNumberAvailable';
import { useSimilarOfferSuccessfulEmailRegister } from '@domains/ad/hooks/useSimilarOfferSuccessfulEmailRegister';
import AdPageContent from '@domains/ad/nexusComponents/AdPageContent/AdPageContent';
import type { PagePropsBase } from '@domains/ad/types/PagePropsBase';
import { AdsProvider } from '@domains/shared/components/AdsProvider';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import { Toaster } from '@domains/shared/components/Toast/Toaster';
import { SavedAdsContextProvider } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { getSpecialOfferTypeTrackingValue } from '@domains/shared/helpers/getSpecialOfferTypeTrackingValue';
import { useFavoritesSubscriptionHash } from '@domains/shared/hooks/useFavoritesSubscriptionHash/useFavoritesSubscriptionHash';
import { useShouldPauseQueryForLogoutUser } from '@domains/shared/hooks/useShouldPauseQueryForLogoutUser/useShouldPauseQueryForLogoutUser';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { FontProvider } from '@domains/shared/theme/FontProvider';
import { BaxterScripts } from '@lib/baxter/BaxterScripts';
import type { LaquesisPageProps } from '@lib/experiments/types/laquesisPageProps';
import { withGraphQLClient } from '@lib/graphql/withGraphQLClient';
import { MainLayout } from '@lib/layouts/MainLayout/MainLayout';
import { ContentBackground } from '@lib/pages/contentBackground';
import { PageProviders } from '@lib/pages/contexts/PageProviders/PageProviders';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { useCallback, useContext, useMemo, useRef } from 'react';
import { useQuery } from 'urql';

import { Container } from './AdPage.theme';
import { ExpiredAdPage } from './ExpiredAdPage';
import { useAdOwner } from './hooks/useAdOwner';
import { useAdSimilarOfferRegister } from './hooks/useAdSimilarOfferRegister';
import { useLocationBreadcrumbs } from './hooks/useLocationBreadcrumbs';
import { ContactFormContextProvider } from './nexusComponents/ContactForm/contexts/ContactFormContext';

export type AdPageProps = PagePropsBase;

const LazyAdSimilarOfferLoginModal = dynamic(
    () => import('@domains/ad/components/AdSimilarOffersLoginModal/AdSimilarOffersLoginModal'),
    { ssr: false },
);

const LazyAdSimilarOfferRegistrationModal = dynamic(
    () => import('@domains/ad/components/AdSimilarOfferRegistrationModal/AdSimilarOfferRegistrationModal'),
    { ssr: false },
);

const BaseAdPage = ({ ad, relativeUrl = '', referer }: AdPageProps): JSX.Element => {
    const { lang } = useSiteSettings();
    const { dfp } = SITE_CONFIG;

    useBaxterAdvertising();

    const sidebarAdContainer = useRef<HTMLDivElement | null>(null);

    useAdSimilarOfferRegister(ad.id);

    const shouldPauseQuery = useShouldPauseQueryForLogoutUser();
    // This is only temporary solution, our backend statistic analysis relies on this endpoint (unused on otodom.pl)
    // When ad team prepare new statistic solution we will remove this request: SMR-1841
    useQuery({ query: USER_FAVORITE_CHECK_QUERY, pause: shouldPauseQuery, variables: { adId: ad.id } });

    // adding ad to observed if needed after login
    useFavoritesSubscriptionHash();

    const breadcrumbs = useLocationBreadcrumbs(ad);

    const afterLoginRedirectPath =
        typeof window === 'undefined' ? '' : `${window.location.href.replace(/#.*/, '')}#${SIMILAR_OFFERS_PARAMETER}`;

    const { toggleLoginModalState, loginModalState, viewType } = useContext(LoginModalContext);

    const handleSimilarOfferRegistrationModalClose = useCallback((): void => {
        toggleLoginModalState('none', 'AD');
    }, [toggleLoginModalState]);

    const handleSimilarOfferSuccessfulEmailRegister = useSimilarOfferSuccessfulEmailRegister();

    const modalCommonParams = {
        isOpen: true,
        closeModal: handleSimilarOfferRegistrationModalClose,
        afterLoginRedirectPath,
        viewType: viewType,
    };

    const similarAdsLoginModal =
        loginModalState === 'login' ? (
            <LazyAdSimilarOfferLoginModal
                {...modalCommonParams}
                showRegistrationModal={(): void => toggleLoginModalState('registration', viewType)}
            />
        ) : null;

    const similarAdsRegistrationModal =
        loginModalState === 'registration' ? (
            <LazyAdSimilarOfferRegistrationModal
                {...modalCommonParams}
                onSuccessfulRegister={(email: string): void => handleSimilarOfferSuccessfulEmailRegister(ad.id, email)}
            />
        ) : null;

    const { adOwner } = useAdOwner(ad.owner.id);

    return (
        <Container>
            <BaxterScripts shouldRenderAds />
            <AdHeadMetadata ad={ad} relativeUrl={relativeUrl} lang={lang} />
            <Toaster />
            <AdsProvider targetingArguments={ad.target}>
                <MainLayout hasStickyPageHeader={false} contentBackground={ContentBackground.default}>
                    <ContactFormContextProvider>
                        <FontProvider>
                            <AdPageContent
                                ad={ad}
                                referer={referer}
                                dfp={dfp}
                                sidebarAdContainer={sidebarAdContainer}
                                breadcrumbs={breadcrumbs}
                                sellerBadges={adOwner.sellerBadges}
                            />
                            {similarAdsLoginModal}
                            {similarAdsRegistrationModal}
                        </FontProvider>
                    </ContactFormContextProvider>
                </MainLayout>
            </AdsProvider>
        </Container>
    );
};

export const AdPageWithProviders = (props: AdPageProps & LaquesisPageProps): JSX.Element => {
    const defaultTrackingData = useMemo(
        () => ({
            touch_point_page: 'ad_page',
            phone_number_available: isPhoneNumberAvailable(props.ad),
        }),
        [props.ad],
    );

    const trackingData = useMemo(
        () => ({
            ...defaultTrackingData,
            ...props.adTrackingData,
            special_offer_type: getSpecialOfferTypeTrackingValue(props.ad.specialOffer),
            discount_value: props.ad.specialOffer?.discountValue || null,
        }),
        [props, defaultTrackingData],
    );
    const { shouldShowExpiredAdPage, id } = props.ad;

    return (
        <PageProviders
            defaultTrackingData={trackingData}
            experiments={props.experiments}
            laquesisResult={props.laquesisResult}
            trackPageName={shouldShowExpiredAdPage ? 'error_410' : 'ad_page'}
        >
            <SavedAdsContextProvider>
                <AdPageModalContextProvider>
                    <LoginModalContextProvider>
                        <SavedSimilarAdsSearchContextProvider adId={id}>
                            <ReportAdModalContextProvider>
                                {props.ad.shouldShowExpiredAdPage ? (
                                    <ExpiredAdPage {...props} />
                                ) : (
                                    <BaseAdPage {...props} />
                                )}
                            </ReportAdModalContextProvider>
                        </SavedSimilarAdsSearchContextProvider>
                    </LoginModalContextProvider>
                </AdPageModalContextProvider>
            </SavedAdsContextProvider>
        </PageProviders>
    );
};

export const AdPage = withGraphQLClient({
    component: AdPageWithProviders,
});
