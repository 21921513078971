import { SITE_CONFIG } from '@config/siteConfig';
import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';

export const getArea = (characteristics: AdvertCharacteristic[]): string | null => {
    const getCharacteristic = getAdCharacteristic(characteristics);
    const areaFromCharacteristic = getCharacteristic('m')?.value;

    return areaFromCharacteristic ? `${areaFromCharacteristic} ${SITE_CONFIG.defaultUnits.area}` : null;
};
