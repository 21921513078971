import { SITE_CONFIG } from '@config/siteConfig';
import { createRoomsNumberLabel } from '@domains/ad/helpers/createRoomsNumberLabel';
import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';

export const getRoomNumber = (characteristics: AdvertCharacteristic[], t: Translator, lang: Locale): string | null => {
    const { shouldUseTypologyInsteadOfNumberOfRooms } = SITE_CONFIG;
    const getCharacteristic = getAdCharacteristic(characteristics);
    const roomNumberFromCharacteristic = getCharacteristic('rooms_num')?.value;

    if (!roomNumberFromCharacteristic) return null;

    const value = roomNumberFromCharacteristic === 'more' ? 'rooms_num::more' : roomNumberFromCharacteristic;

    return createRoomsNumberLabel(t, lang, value, shouldUseTypologyInsteadOfNumberOfRooms);
};
