import { useRecommendedAdverts } from '@domains/ad/hooks/useRecommendedAdverts';
import { RecommendedAdItem } from '@domains/ad/nexusComponents/RecommendedAdItem/RecommendedAdItem';
import {
    Container,
    Heading,
    ItemsContainer,
    StyledPopover,
    StyledQuestionMark,
    TooltipText,
} from '@domains/ad/nexusComponents/SimilarAdsSection/SimilarAdsSection.theme';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { H4 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';
import { useRef } from 'react';

interface Props {
    adId: number;
}

export const SimilarAdsSection = ({ adId }: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { recommendedAdverts, fetching } = useRecommendedAdverts(adId);
    const { trackEvent } = useTracking();

    const similarAdsRef = useRef<HTMLDivElement | null>(null);
    useTrackImpression(similarAdsRef, () => {
        trackEvent('ad_impressions', {
            touch_point_button: 'similar_ads_recommendations',
        });
    });

    if (fetching) {
        return <Container />;
    }

    if (!fetching && recommendedAdverts.length === 0) {
        return null;
    }

    return (
        <Container ref={similarAdsRef} data-testid="similar-ads-container">
            <Heading>
                <H4 variant="bold">{t('frontend.ad.similar-ads-section.headline')}</H4>
                <StyledPopover
                    placement="top"
                    verticalOffset={10}
                    content={(): JSX.Element => (
                        <TooltipText>{t('frontend.ad.similar-ads-section.tooltip')}</TooltipText>
                    )}
                >
                    <span>
                        <StyledQuestionMark size="icon-small" />
                    </span>
                </StyledPopover>
            </Heading>
            <ItemsContainer>
                {recommendedAdverts.map((ad) => (
                    <RecommendedAdItem key={ad.adId} ad={ad} adsType="similar-ads" />
                ))}
            </ItemsContainer>
        </Container>
    );
};
