import { SITE_CONFIG } from '@config/siteConfig';
import type { Locale } from '@lib/i18n/types/locale';

import { getCurrencyIsoCode } from './getCurrencyIsoCode';

interface FormattingOptions {
    price: number;
    currency?: string;
    lang?: Locale;
    maximumSignificantDigits?: number;
    minimumFractionDigits?: number;
    maximumFractionDigits?: number;
    shouldFormatAsInteger?: boolean;
}

export const formatPrice = ({
    price,
    currency = SITE_CONFIG.defaultUnits.currency,
    lang = 'en',
    maximumSignificantDigits,
    minimumFractionDigits,
    maximumFractionDigits,
    shouldFormatAsInteger,
}: FormattingOptions): string => {
    const currencyInISOCode = getCurrencyIsoCode(currency);

    return price.toLocaleString(lang, {
        style: 'currency',
        currency: currencyInISOCode,
        maximumSignificantDigits,
        minimumFractionDigits: shouldFormatAsInteger ? 0 : minimumFractionDigits,
        maximumFractionDigits: shouldFormatAsInteger ? 0 : maximumFractionDigits,
    });
};
