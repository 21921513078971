import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

export const ImageContainer = styled.picture<{ isFullscreen: boolean }>`
    display: inherit;
    background: ${({ isFullscreen }): CSSProperties['background'] =>
        isFullscreen
            ? 'none'
            : 'linear-gradient(to top,' + theme.colors['background-global-inverse'] + 'CC 0%, transparent 20%)'};

    img {
        position: relative;
        width: 100%;
        height: auto;
        aspect-ratio: ${({ isFullscreen }): CSSProperties['aspectRatio'] => (isFullscreen ? 'auto' : '4/3')};
        object-fit: cover !important;

        @media ${theme.mediaQueries.smMin} {
            height: 460px;
        }
    }

    @media ${theme.mediaQueries.lgMin} {
        background: none;
    }
`;
