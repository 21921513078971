import { SITE_CONFIG } from '@config/siteConfig';
import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import { formatPrice } from '@domains/shared/helpers/price/formatPrice';
import type { Locale } from '@lib/i18n/types/locale';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';

export const getPricePerMeter = (characteristics: AdvertCharacteristic[], lang: Locale): string | null => {
    const getCharacteristic = getAdCharacteristic(characteristics);

    const pricePerMeterFromCharacteristic = getCharacteristic('price_per_m');
    const currency = getCharacteristic('price')?.currency;

    if (!pricePerMeterFromCharacteristic?.value || !currency) {
        return null;
    }

    // @pwojciechowska-fran setting currency as undefined to use default site currency as price per square meter unit
    // this is a temporary solution after changes on BE will be introduced it should be updated as calculated value wil be returned from BE.
    const formattedPrice = formatPrice({
        price: Number(pricePerMeterFromCharacteristic.value),
        currency: undefined,
        lang,
        maximumFractionDigits: 0,
    });

    return `${formattedPrice}/${SITE_CONFIG.defaultUnits.area}`;
};
