import { AddToFavorites } from '@domains/shared/components/AdvertItem/AddToFavorites/AddToFavorites';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';

export const Container = styled.div`
    display: flex;
    position: fixed;
    z-index: ${theme.zIndices.modal};
    top: ${theme.space['16']};
    left: ${theme.space['16']};
    justify-content: space-between;
    width: calc(100% - 38px);

    svg {
        color: ${theme.colors.$01_white};
    }
`;

export const ActionsContainer = styled.div`
    display: flex;
    gap: ${theme.sizes['16']};
`;

export const CloseButton = styled(Button)`
    width: 24px;
    height: 24px;
    svg {
        color: ${theme.colors['$01_white']};
    }
`;

export const SavedAdButton = styled(AddToFavorites)`
    width: 24px;
    height: 24px;
    svg {
        color: ${theme.colors['$01_white']};
    }
`;
