import { getAgencyUrl } from '@domains/ad/helpers/getAgencyUrl';
import { getFormattedAgencyName } from '@domains/ad/helpers/getFormattedAgencyName';
import { LocalLink } from '@domains/shared/components/LocalLink/LocalLink';
import { LeaderBadge } from '@domains/shared/components/nexus/LeaderBadgeIcon/LeaderBadge';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTracking } from '@lib/tracking/useTracking';
import type { AdvertAgency, Agency } from '@type/ad/advertAgency';
import type { AdvertCategory } from '@type/ad/attributes/advertCategory';
import type { AgencyOwner } from '@type/ad/attributes/advertOwner';
import { checkIsAgencyOwner } from '@type/ad/attributes/advertOwner';
import type { SellerBadge } from '@type/sellerBadge/sellerBadge';
import type { JSX } from 'react';
import { useRef } from 'react';

import { AgencyDetails, AgencyName, ArrowIcon, Logo, StyledButton } from './AgencyBranding.theme';

interface Props {
    agency: AdvertAgency | AgencyOwner;
    adCategory: AdvertCategory;
    sellerBadges?: SellerBadge[];
}

const checkIsAgency = (value: AdvertAgency | AgencyOwner): value is Agency => !checkIsAgencyOwner(value as AgencyOwner);

export const AgencyBranding = ({ agency, adCategory, sellerBadges }: Props): JSX.Element => {
    const { trackEvent } = useTracking();
    const { lang } = useSiteSettings();
    const isAgency = checkIsAgency(agency);
    const image = isAgency ? agency.imageUrl : agency?.image?.url;
    const name = isAgency ? agency.name : agency?.companyName;
    const { name: estate, type: transaction } = adCategory;
    const url = agency?.url ? getAgencyUrl({ agency, estate, transaction, lang }) : null;

    const linkRef = useRef<HTMLButtonElement | null>(null);
    useTrackImpression(linkRef, () =>
        trackEvent('ads_from_seller_link_impression', {
            touch_point_button: 'sticky_bar',
        }),
    );

    const handleClick = (): void => {
        if (url) {
            trackEvent('ads_from_seller', { touch_point_button: 'sticky_header' });
        }
    };

    const agencyName = getFormattedAgencyName(name);

    const button = (
        <StyledButton onClick={handleClick} ref={url ? linkRef : null} data-testid="agency-branding">
            <AgencyDetails>
                {image ? <Logo src={image} alt={agencyName} /> : null}
                <AgencyName variant="bold">{agencyName}</AgencyName>
            </AgencyDetails>
            <LeaderBadge sellerBadges={sellerBadges} />
            {url ? <ArrowIcon /> : null}
        </StyledButton>
    );

    return url ? <LocalLink href={url}>{button}</LocalLink> : button;
};
