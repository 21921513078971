import { NumberInput } from '@domains/shared/components/NumberInput/NumberInput';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P2 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    position: relative;
    width: 100%;
`;

export const InputField = styled(NumberInput)`
    width: 100%;
    padding: ${theme.space[24]} ${theme.space[28]} ${theme.space[24]} ${theme.space[16]};
    padding-right: ${theme.space[28]};
    border: none;
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors.$02_gray};
    color: ${theme.colors['text-brand-primary']};
    font-size: ${theme.fontSizes.p2};

    @media ${theme.mediaQueries.lgMin} {
        font-size: ${theme.fontSizes.p2};
    }
` as typeof NumberInput;

export const Suffix = styled(P2)`
    position: absolute;
    top: 50%;
    right: ${theme.space[12]};
    transform: translateY(-50%);
    color: ${theme.colors['text-global-secondary']};
`;
