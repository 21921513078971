import { REMOTE_CONTENT_DIALOG_ID } from '@domains/ad/consts/remoteContentDialogId';
import { VIRTUAL_WALK_CONFIRMATION_DIALOG_ID } from '@domains/ad/consts/virtualWalkConfirmationDialog';
import { ZONING_PLAN_DIALOG_ID } from '@domains/ad/consts/zoningPlanDialogId';
import { GalleryContext } from '@domains/ad/contexts/GalleryContext';
import { useUserConsentForEmbeddedContent } from '@domains/ad/hooks/useUserConsentForEmbeddedContent';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import Cube from '@nexus/lib-react/dist/core/Icon/icons/default/Cube';
import MapView from '@nexus/lib-react/dist/core/Icon/icons/default/MapView';
import NRooms from '@nexus/lib-react/dist/core/Icon/icons/default/NRooms';
import Video from '@nexus/lib-react/dist/core/Icon/icons/default/Video';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { useContext, useEffect, useMemo, useState } from 'react';

import {
    Container,
    MultimediaItem,
    MultimediaItemButton,
    MultimediaItemLabel,
    MultimediaList,
} from './AdMultimedia.theme';

const LazyThirdPartyContentWarningDialog = dynamic(
    () => import('@domains/ad/nexusComponents/ThirdPartyContentWarningDialog/ThirdPartyContentWarningDialog'),
    { ssr: false },
);

const LazyRemoteContent = dynamic(() => import('@domains/ad/nexusComponents/RemoteContent/RemoteContent'), {
    ssr: false,
});

interface AdMultimediaItem {
    id: string;
    icon: JSX.Element;
    label: string;
    href?: string;
    touchPointButton: string;
    trackingEventName?: string;
}

interface Props {
    floorPlanIndex: number | null;
    videoUrl?: string;
    view3dUrl?: string;
    walkaroundUrl?: string;
    localPlanUrl?: string;
}

export const AdMultimedia = ({
    floorPlanIndex = null,
    videoUrl,
    localPlanUrl,
    view3dUrl,
    walkaroundUrl,
}: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { openDialog: openVirtualWalkDialog, isOpen: isVirtualWalkDialogOpen } = useDialogState(
        VIRTUAL_WALK_CONFIRMATION_DIALOG_ID,
    );
    const { openDialog: openVideoDialog, isOpen: isVideoDialogOpen } = useDialogState(REMOTE_CONTENT_DIALOG_ID);
    const { openDialog: openZoningPlanDialog, isOpen: isZoningPlanDialogOpen } = useDialogState(ZONING_PLAN_DIALOG_ID);

    const virtualWalkUrl = walkaroundUrl || view3dUrl;

    const [activeVirtualWalkUrl, setActiveVirtualWalkUrl] = useState('');
    const [zoningPlanUrl, setZoningPlanUrl] = useState('');
    const [activeVideoUrl, setActiveVideoUrl] = useState('');
    const [galleryFloorPlanIndex, setGalleryFloorPlanIndex] = useState<number>();
    const [shouldUpdateIndexValueTrigger, setShouldUpdateIndexValueTrigger] = useState(false);
    const { projectImageGalleryMethods } = useContext(GalleryContext);
    const { onImageClick, setCurrentIndex, setIsFullScreen } = projectImageGalleryMethods;
    const isFloorPlanIndexValid = typeof floorPlanIndex === 'number';

    useEffect(() => {
        if (typeof galleryFloorPlanIndex === 'number') {
            setCurrentIndex(galleryFloorPlanIndex);
        }
    }, [setCurrentIndex, galleryFloorPlanIndex, shouldUpdateIndexValueTrigger]);

    const { isConsentGiven } = useUserConsentForEmbeddedContent();

    const list: AdMultimediaItem[] = useMemo(
        () => [
            ...(isFloorPlanIndexValid
                ? [
                      {
                          id: 'ad-multimedia-floor-plan',
                          icon: <NRooms size={16} />,
                          label: t('frontend.adpage.multimedia-section.floor-plan'),
                          touchPointButton: 'floor_plan',
                          trackingEventName: 'floor_plan_click',
                      },
                  ]
                : []),
            {
                id: 'ad-multimedia-video',
                icon: <Video size={16} />,
                label: t('frontend.adpage.multimedia-section.video'),
                href: videoUrl,
                touchPointButton: 'video',
                trackingEventName: 'video_click',
            },
            {
                id: 'ad-multimedia-virtual-walk',
                icon: <Cube size={16} />,
                label: t('frontend.adpage.multimedia-section.virtual-walk'),
                href: virtualWalkUrl,
                touchPointButton: 'virtual_walk',
                trackingEventName: '3d_view_click',
            },
            {
                id: 'ad-multimedia-dev-plan',
                icon: <MapView size={16} />,
                label: t('frontend.adpage.multimedia-section.dev-plan'),
                href: localPlanUrl,
                touchPointButton: 'dev_plan',
                trackingEventName: 'dev_plan_click',
            },
        ],
        [videoUrl, virtualWalkUrl, localPlanUrl, t, isFloorPlanIndexValid],
    );

    if (!videoUrl && !localPlanUrl && !virtualWalkUrl && floorPlanIndex === null) {
        return null;
    }

    const handleItemClick = (id: string, touchPointButton: string, trackingEventName?: string, href?: string): void => {
        const isVideo = id === 'ad-multimedia-video';
        const isVirtualWalk = id === 'ad-multimedia-virtual-walk';
        const isZoningPlanDialog = id === 'ad-multimedia-dev-plan';
        const isFloorPlan = id === 'ad-multimedia-floor-plan';

        if (isFloorPlan && isFloorPlanIndexValid) {
            setShouldUpdateIndexValueTrigger((previousState) => !previousState);
            setGalleryFloorPlanIndex(floorPlanIndex);
            setIsFullScreen(true);
            onImageClick();
        }

        if (isVideo && href) {
            openVideoDialog();
            setActiveVideoUrl(href);
        }

        if (isVirtualWalk && href) {
            openVirtualWalkDialog();
            setActiveVirtualWalkUrl(href);
        }

        if (isZoningPlanDialog && href) {
            openZoningPlanDialog();
            setZoningPlanUrl(href);
        }

        if (trackingEventName) {
            trackEvent(trackingEventName, {
                touch_point_button: touchPointButton,
            });
        }
    };

    return (
        <Container data-testid="ad-multimedia-section">
            <MultimediaList>
                {list.map(({ id, icon, label, href, touchPointButton, trackingEventName }) => {
                    return href || id === 'ad-multimedia-floor-plan' ? (
                        <MultimediaItem key={id}>
                            <MultimediaItemButton
                                data-testid="ad-multimedia-button"
                                onClick={() => handleItemClick(id, touchPointButton, trackingEventName, href)}
                            >
                                {icon}
                                <MultimediaItemLabel>{label}</MultimediaItemLabel>
                            </MultimediaItemButton>
                        </MultimediaItem>
                    ) : null;
                })}
            </MultimediaList>
            {isVirtualWalkDialogOpen && activeVirtualWalkUrl && (
                <LazyThirdPartyContentWarningDialog
                    id={VIRTUAL_WALK_CONFIRMATION_DIALOG_ID}
                    url={activeVirtualWalkUrl}
                    title={t('frontend.ad.virtual-walk-confirmation-modal.title')}
                />
            )}
            {isZoningPlanDialogOpen && zoningPlanUrl && (
                <LazyThirdPartyContentWarningDialog
                    id={ZONING_PLAN_DIALOG_ID}
                    url={zoningPlanUrl}
                    title={t('frontend.ad.zoning-plan-confirmation-modal.title')}
                />
            )}
            {isVideoDialogOpen && activeVideoUrl && isConsentGiven && <LazyRemoteContent url={activeVideoUrl} />}
            {isVideoDialogOpen && activeVideoUrl && !isConsentGiven && (
                <LazyThirdPartyContentWarningDialog
                    id={REMOTE_CONTENT_DIALOG_ID}
                    url={activeVideoUrl}
                    title={t('frontend.ad.video-confirmation-modal.title')}
                />
            )}
        </Container>
    );
};
