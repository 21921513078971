import { FINANCE_CONFIG } from '@config/finance/financeConfig';
import { SITE_CONFIG } from '@config/siteConfig';

import { FIELDS_NAME } from './fieldsName';

const { defaultUnits } = SITE_CONFIG;
const { loanTermMax } = FINANCE_CONFIG.calculator || {};

export const DEFAULT_FIELD_OPTION = {
    [FIELDS_NAME.propertyPrice]: {
        min: 50000,
        max: 8000000,
        step: 5000,
        suffix: defaultUnits.currency,
    },
    [FIELDS_NAME.ownContribution]: {
        min: 0,
        max: 720000,
        step: 5000,
        suffix: defaultUnits.currency,
    },
    [FIELDS_NAME.ownContributionPercent]: {
        min: 10,
        max: 90,
        step: 1,
        suffix: '%',
    },
    [FIELDS_NAME.loanTerm]: {
        min: 5,
        max: loanTermMax || 35,
        step: 5,
        suffix: 'lat',
    },
    [FIELDS_NAME.interestRate]: {
        min: 1,
        max: 20.5,
        step: 0.01,
        suffix: '%',
    },
};
