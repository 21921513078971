import { getLocationAddress } from '@domains/shared/helpers/getLocationAddress';
import type { AdvertLocationDetails } from '@type/location/advertLocation';

export const getAddress = (location?: AdvertLocationDetails): string | null => {
    if (!location) return null;

    const { address, reverseGeocoding, mapDetails } = location;

    return (
        getLocationAddress({
            street: address.street,
            radius: mapDetails?.radius,
            locations: reverseGeocoding?.locations ?? [],
        }) ?? null
    );
};
