import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import MapView from '@nexus/lib-react/dist/core/Icon/icons/default/MapView';
import { H4 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    padding: ${theme.space[24]};
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors.$01_white};
`;

export const Heading = styled(H4)`
    margin-bottom: ${theme.space[16]};
`;

export const LocationWrapper = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: ${theme.space[16]};
    font-size: ${theme.fontSizes[14]};
`;

export const StyledMapViewIcon = styled(MapView)`
    margin-right: ${theme.space[6]};
`;
