import { SITE_CONFIG } from '@config/siteConfig';
import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';

export const getProjectArea = (characteristics: AdvertCharacteristic[]): string | null => {
    const getCharacteristic = getAdCharacteristic(characteristics);
    const projectAreaStartsFromCharacteristic = getCharacteristic('area_from')?.value;
    const projectAreaEndsFromCharacteristic = getCharacteristic('area_to')?.value;

    return projectAreaStartsFromCharacteristic && projectAreaEndsFromCharacteristic
        ? `${projectAreaStartsFromCharacteristic}-${projectAreaEndsFromCharacteristic} ${SITE_CONFIG.defaultUnits.area}`
        : null;
};
