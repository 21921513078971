import { AdSimilarOffersButton } from '@domains/ad/components/AdSimilarOffersButton/AdSimilarOffersButton';
import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import type { Ad } from '@domains/ad/types/Ad';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { CATEGORY_ID } from '@domains/shared/types/categoryId';
import EyeIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Eye';
import { P2, P3 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';

import { Container, Description, InfoContainer } from './SimilarOffersPrompt.theme';

interface Props {
    ad: Ad;
}

export const SimilarOffersPrompt = ({ ad }: Props): JSX.Element | null => {
    const { category, characteristics, id } = ad;
    const [t] = useTranslations();

    const price = getAdCharacteristic(characteristics)('price')?.value;
    const isInvestment = category.id === CATEGORY_ID.investment;

    if (!price || isInvestment) {
        return null;
    }

    return (
        <Container>
            <InfoContainer>
                <EyeIcon />
                <Description>
                    <P2 variant="bold">{t('frontend.ad.similar-offers-prompt.heading')}</P2>
                    <P3>{t('frontend.ad.similar-offers-prompt.description')}</P3>
                </Description>
            </InfoContainer>
            <AdSimilarOffersButton adId={id} viewType="AD" shouldUseNexusVariant />
        </Container>
    );
};
