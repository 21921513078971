import { Item, ItemGridContainer } from '@domains/ad/nexusComponents/AdDetails/AdDetails.theme';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import type { PropsWithChildren } from 'react';

interface AdDetailItemProps {
    label: string;
    showDivider: boolean;
}

export const AdDetailItem = ({ label, children, showDivider }: PropsWithChildren<AdDetailItemProps>): JSX.Element => {
    const [t] = useTranslations();

    return (
        <>
            <ItemGridContainer data-testid="ad-details-items">
                <Item>{t(`frontend.ad.table-information-label-${label}`)}:</Item>
                {children}
            </ItemGridContainer>
            {showDivider ? <Divider variant="secondary-light" /> : null}
        </>
    );
};
