import { AD_CONFIG } from '@config/ad/adConfig';
import { calculateMortgage } from '@domains/shared/helpers/calculateMortgage';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import { useMemo } from 'react';

interface UseAdMortgagePriceParams {
    price: AdvertCharacteristic;
    mortgageDownpaymentPercentage: number;
    mortgageInterest: number;
    mortgageYears: number;
    shouldConvertPriceToLocalCurrency?: boolean;
}

interface UseAdMortgagePriceResult {
    downpaymentPrice: number;
    mortgagePrice: number;
}

const { localCurrencyConversionRates } = AD_CONFIG.adMortgageSimulatorConfig || {};

export const useAdMortgagePrice = ({
    price,
    mortgageDownpaymentPercentage,
    mortgageInterest,
    mortgageYears,
    shouldConvertPriceToLocalCurrency,
}: UseAdMortgagePriceParams): UseAdMortgagePriceResult => {
    const mortgageCalculation = useMemo(() => {
        const adPrice =
            shouldConvertPriceToLocalCurrency && localCurrencyConversionRates && price.currency === 'EUR'
                ? Number(price.value) * localCurrencyConversionRates.eur
                : Number(price.value);

        const calculatedMortgageInterest = Number((mortgageInterest / 100).toFixed(4));

        return {
            downpaymentPrice: adPrice * mortgageDownpaymentPercentage,
            mortgagePrice: calculateMortgage(
                calculatedMortgageInterest,
                mortgageYears,
                adPrice * (1 - mortgageDownpaymentPercentage),
                0,
                0,
            ),
        };
    }, [
        shouldConvertPriceToLocalCurrency,
        price.currency,
        price.value,
        mortgageInterest,
        mortgageDownpaymentPercentage,
        mortgageYears,
    ]);

    return mortgageCalculation;
};
