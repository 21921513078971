import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';

import { ExternalId, StyledPopover } from './AdDescriptionExternalId.theme';

interface Props {
    id: string | null;
    t: Translator;
}

export const AdDescriptionExternalId = ({ id, t }: Props): JSX.Element | null => {
    if (!id) {
        return null;
    }

    const externalIdText = `${t('frontend.ad-content.description.external-id')}: ${id}`;

    const popoverContent = (): JSX.Element => <span>{id}</span>;

    if (externalIdText.length > 35) {
        return (
            <StyledPopover
                appendTo="parent"
                trigger="mouseenter"
                content={popoverContent}
                placement="top-start"
                verticalOffset={14}
            >
                <ExternalId data-testid="external-id-with-popover">{externalIdText}</ExternalId>
            </StyledPopover>
        );
    }

    return <ExternalId data-testid="external-id-as-text">{externalIdText}</ExternalId>;
};
