import { SITE_CONFIG } from '@config/siteConfig';
import type { FC, ReactNode } from 'react';
import { useEffect } from 'react';
import { DFPSlotsProvider } from 'react-dfp';

interface Props {
    children: ReactNode;
    lazyload?: boolean;
    targetingArguments?: unknown;
}

const LAZY_LOAD_OPTIONS = {
    fetchMarginPercent: 0,
    renderMarginPercent: 0,
    mobileScaling: 0,
};

export const AdsProvider: FC<Props> = ({ targetingArguments = {}, lazyload = true, children }) => {
    const dfpNetworkId = SITE_CONFIG.dfp.networkId;

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any -- Setup GTP targeting, it's additional information used by targeted ads
        (window as any).GPT = { targeting: targetingArguments };
    }, [targetingArguments]);

    return (
        <DFPSlotsProvider
            autoLoad={false}
            lazyLoad={lazyload && LAZY_LOAD_OPTIONS}
            dfpNetworkId={dfpNetworkId}
            collapseEmptyDivs={true}
            targetingArguments={targetingArguments}
        >
            {children}
        </DFPSlotsProvider>
    );
};
