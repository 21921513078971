import { getEnergyCertificateReadableValue } from '@domains/ad/components/Table/TopInformation/helpers/getEnergyCertificateReadableValue';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { AdInformation } from '@type/ad/adDetails';
import type { AdInformationValue } from '@type/ad/adInformationValue';
import type { AdvertCharacteristic, EnergyCertificate } from '@type/ad/advertCharacteristic';

const ENERGY_CERTIFICATE_KEY = 'energy_certificate';
export const getEnergyCertificate = (adCharacteristics: AdvertCharacteristic[], t: Translator): AdInformation => {
    const energyCertificate = adCharacteristics.find(({ key }) => key === 'energy_certificate')?.value;

    if (!energyCertificate) {
        return {
            unit: '',
            label: ENERGY_CERTIFICATE_KEY,
            values: [],
        };
    }

    return {
        unit: '',
        label: ENERGY_CERTIFICATE_KEY,
        values: [getEnergyCertificateReadableValue(energyCertificate as EnergyCertificate, t) as AdInformationValue],
    };
};
