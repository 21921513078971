// FIXME: Convert into widget/favorites
import { SavedAdsContext } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useLazyUser } from '@lib/pages/contexts/LazyUserContext/useLazyUser';
import { Button } from '@nexus/lib-react/dist/core/Button';
import HeartDefault from '@nexus/lib-react/dist/core/Icon/icons/default/HeartDefault';
import type { SetSimilarAdsPromptId } from '@type/favorites/similarAdsPromptId';
import dynamic from 'next/dynamic';
import type { JSX, MouseEventHandler } from 'react';
import { useCallback, useContext, useMemo } from 'react';

import { usePlatformEventSubscription } from '../../shared/hooks/usePlatformEventSubscription';
import { ButtonContent, StyledHeartFilled } from './AddToFavorites.theme';
import { useSubscribeButtonHandler } from './hooks/useSubscribeButtonHandler';

const LazyObserveAdModal = dynamic(() => import('@domains/ad/components/ObserveAdModal/ObserveAdModal'), {
    ssr: false,
});

const HANDLE_SUBSCRIPTION_PLATFORM_EVENT = 'addToSavedAdsIconClick';

interface Props {
    id: number;
    buttonSize?: 'large' | 'small';
    buttonVariant?: 'primary' | 'secondary' | 'flat';
    label?: string;
    isRedirectToLoginModalOpen?: boolean;
    setIsRedirectToLoginModalOpen?: (value: boolean) => void;
    className?: string;
    trackingData?: Record<string, unknown>;
    placement?: 'listing' | 'ad-page';
    setSimilarAdsPromptId?: SetSimilarAdsPromptId;
    onStateChange?: (isFavourite: boolean, id: number) => void;
    onClick?: () => void;
    shouldShowUndoButtonInToast?: boolean;
    savedAdNote?: string;
}

const DEFAULT_TRACKING_DATA = {};

export const AddToFavorites = ({
    id,
    isRedirectToLoginModalOpen,
    setIsRedirectToLoginModalOpen,
    className,
    trackingData = DEFAULT_TRACKING_DATA,
    placement,
    setSimilarAdsPromptId,
    onStateChange,
    onClick,
    shouldShowUndoButtonInToast,
    label,
    buttonSize = 'large',
    buttonVariant = 'primary',
}: Props): JSX.Element => {
    const [t] = useTranslations();

    const { isAwaitingUserData, isUserLoggedIn } = useLazyUser();
    const { adsIdInUpdateState, checkIfAdIsSaved, isFetchingSavedAds, toggleSubscription } =
        useContext(SavedAdsContext);
    const isDataLoading = isFetchingSavedAds || isAwaitingUserData;

    const isInProgress = adsIdInUpdateState.has(id);
    const isFavorite = checkIfAdIsSaved(id);

    const shouldShowFilledHeartIcon = isFavorite || isInProgress;
    const isButtonDisabled = isDataLoading || isInProgress;

    const Icon = shouldShowFilledHeartIcon ? StyledHeartFilled : HeartDefault;

    const trackingParams = useMemo(() => ({ ...trackingData, ad_id: id }), [trackingData, id]);

    const { handleSubscribeButtonClick } = useSubscribeButtonHandler({
        isUserLoggedIn,
        setIsRedirectToLoginModalOpen,
        isFavorite,
        trackingParams,
        id,
        setSimilarAdsPromptId,
        toggleSubscription,
        onStateChange,
        onClick,
        shouldShowUndoButtonInToast,
    });

    usePlatformEventSubscription(HANDLE_SUBSCRIPTION_PLATFORM_EVENT, handleSubscribeButtonClick);

    const handleWrapperClickEvent = useCallback<MouseEventHandler<HTMLDivElement>>((event) => {
        event.stopPropagation(); //prevent sending adPage click tracking event on opening modal
        event.preventDefault(); //prevent redirect to adPage
    }, []);

    const buttonCy =
        placement === 'listing' ? 'listing-subscribe-button.subscribe' : 'ad-page-subscribe-button.subscribe';

    return (
        <div onClick={handleWrapperClickEvent}>
            <Button
                onClick={handleSubscribeButtonClick}
                disabled={isButtonDisabled}
                className={className}
                aria-label={t('frontend.ad.subscribe.add-to-favorites')}
                data-cy={buttonCy}
                data-cy-subscribed={isFavorite}
                data-testid="add-to-favorites-button"
                variant={buttonVariant}
                size={buttonSize}
            >
                <ButtonContent data-testid="add-to-favorites-nexus-button">
                    <Icon color="inherit" width={24} height={24} data-testid="nexus-icon" />
                    {label ? <span>{label}</span> : null}
                </ButtonContent>
            </Button>

            {isRedirectToLoginModalOpen ? (
                <LazyObserveAdModal
                    onClose={(): void => setIsRedirectToLoginModalOpen?.(false)}
                    extraTrackingData={trackingParams}
                    id={id}
                />
            ) : null}
        </div>
    );
};
