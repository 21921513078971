import styled from '@emotion/styled';

interface AvatarPictureProps {
    url: string;
}
export const AvatarPicture = styled.div`
    width: 100%;
    height: 100%;
    background-image: url('${(props: AvatarPictureProps): string => props.url}');
    background-position: center;
    background-size: cover;
`;

interface AvatarProps {
    avatarSize: number;
}

export const Container = styled.div<AvatarProps>`
    display: flex;
    align-items: center;
    justify-content: center;
    width: ${({ avatarSize }): string => `${avatarSize}px`};
    height: ${({ avatarSize }): string => `${avatarSize}px`};
    overflow: hidden;
    border: 1px solid ${({ theme }): string => theme.deprecated.domains.shared.avatar.borderColor};
    border-radius: 50%;
    color: ${({ theme }): string => theme.deprecated.domains.shared.avatar.color};
`;
