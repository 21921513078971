import type { ParsedUrlQuery } from 'node:querystring';

export const buildLinkWithQueryParameters = ({ href, query }: { href: string; query: ParsedUrlQuery }): string => {
    const queryParams = new URLSearchParams();
    for (const paramKey in query) {
        const paramValue = query[paramKey];
        if (paramKey !== 'lang' && typeof paramValue !== 'undefined') {
            queryParams.append(paramKey, paramValue as string);
        }
    }

    if (queryParams.toString() === '') {
        return href;
    }

    if (href.includes('#')) {
        return href.split('#').join(`?${queryParams.toString()}#`);
    }

    return `${href}?${queryParams.toString()}`;
};
