import { ImageGalleryItem } from '@domains/ad/components/ImageGalleryItem/ImageGalleryItem';
import { AdPageModalContext } from '@domains/ad/contexts/AdPageModalContext';
import { CONTACT_FORM_TYPE } from '@domains/shared/consts/contactFormType';
import { ContactFormWrapper } from '@widgets/contactForm/ContactFormWrapper';
import { ContactPersonBanner } from '@widgets/contactForm/ContactPersonBanner';
import type { ContactFormProps } from '@widgets/contactForm/types/contactForm';
import type { ContactPersonBannerProps } from '@widgets/contactForm/types/contactPersonBanner';
import type { JSX } from 'react';
import { useContext } from 'react';

interface ImageGalleryRenderItemProps extends ContactPersonBannerProps {
    source: string;
    contactFormAdvertProps: ContactFormProps;
    shouldDisplayPredefinedContactMessages?: boolean;
}

export const ImageGalleryRenderItem = ({
    source,
    advertId,
    advertiserType,
    contactDetails,
    ownerType,
    agencyLogoUrl,
    contactFormAdvertProps,
    shouldDisplayPredefinedContactMessages,
}: ImageGalleryRenderItemProps): JSX.Element => {
    const { toggleModalState } = useContext(AdPageModalContext);

    const handleContactButtonClick = (): void => {
        toggleModalState('default');
    };

    const handlePhoneNumberClick = (): void => {
        if (ownerType === 'agency') {
            toggleModalState('agency');
        }
    };

    return (
        <ImageGalleryItem source={source}>
            <ContactFormWrapper
                beforeContactForm={
                    <ContactPersonBanner
                        advertId={advertId}
                        advertiserType={advertiserType}
                        contactDetails={contactDetails}
                        ownerType={ownerType}
                        agencyLogoUrl={agencyLogoUrl}
                        contactFormVariant="gallery"
                        onContactButtonClick={handleContactButtonClick}
                        onPhoneNumberClick={handlePhoneNumberClick}
                        additionalTrackingData={{ contact_form_type: CONTACT_FORM_TYPE.galleryFullScreen }}
                    />
                }
                variant="gallery"
                shouldDisplayPredefinedContactMessages={shouldDisplayPredefinedContactMessages}
                additionalTrackingData={{ contact_form_type: CONTACT_FORM_TYPE.galleryFullScreen }}
                shouldDisplayGdprInline={true}
                {...contactFormAdvertProps}
            />
        </ImageGalleryItem>
    );
};
