import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    display: flex;
    flex-wrap: nowrap;
`;

export const PercentInputWrapper = styled.div`
    width: 110px;
    margin-right: ${theme.space[8]};
`;
