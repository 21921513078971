import type { FinanceConfig } from './type';

export const FINANCE_CONFIG: FinanceConfig = {
    contactNumber: '784 660 558',
    navbar: [
        {
            label: 'frontend.finance.navbar.calculator',
            href: '/[lang]/finanse#financeCalculator',
            as: '/[lang]/finanse#financeCalculator',
            shouldAppendQueryParams: true,
        },
        {
            label: 'frontend.finance.navbar.mortgage-browser',
            href: '/[lang]/finanse/kredyty-hipoteczne',
            as: '/[lang]/finanse/kredyty-hipoteczne',
            shouldAppendQueryParams: true,
        },
        {
            label: 'frontend.finance.navbar.support',
            href: '/[lang]/finanse#financeSupport',
            as: '/[lang]/finanse#financeSupport',
            shouldAppendQueryParams: true,
        },
        {
            label: 'frontend.finance.navbar.faq',
            href: '/[lang]/finanse#financeFaq',
            as: '/[lang]/finanse#financeFaq',
            shouldAppendQueryParams: true,
        },
        {
            label: 'frontend.navbar.menu.articles',
            href: '/wiadomosci/',
            shouldAppendQueryParams: false,
        },
        {
            label: 'frontend.finance.navbar.call',
            href: '/[lang]/finanse/zamow-rozmowe',
            as: '/[lang]/finanse/zamow-rozmowe',
            shouldAppendQueryParams: true,
            tracking: {
                eventName: 'finance_lead_form_click',
                touchPointButton: 'head_bar',
            },
        },
    ],
    calculator: {
        ownContributionPercent: 0.2,
        interestRate: {
            lowest: 7.8,
            average: 8.5,
        },
        loanTerm: 30,
        loanTermMax: 35,
    },
    callRequestLink: {
        href: '/[lang]/finanse/zamow-rozmowe',
        as: '/[lang]/finanse/zamow-rozmowe',
        label: 'frontend.finance.call-request.link-button',
    },
    mortgageBrowserLink: {
        href: '/[lang]/finanse/kredyty-hipoteczne',
        as: '/[lang]/finanse/kredyty-hipoteczne',
        label: 'frontend.ad.ad-mortgage-simulator.mortgage-browser',
    },
    callRequest: {
        regulationsUrl: 'https://www.custream.com/otodom_finanse_regulamin',
        infoUrl: 'https://pomoc.otodom.pl/otodomhelpcenter/s/article/kA009000001v1heCAA',
        promotionUrl: 'https://www.custream.com/finanse-bik',
        discussProposalUtmMedium: 'omow_propozycje',
        discussWithAnExpertUtmMedium: 'omow_z_ekspertem',
        utmSource: 'otodom',
    },
    pipedriveLeadboosterConfig: {
        base: 'leadbooster-chat.pipedrive.com',
        companyId: 5994885,
        playbookUuid: 'e8db5dc4-31de-4ff4-9d1b-50b25ee28afa',
        version: 2,
    },
    experts: [
        {
            name: 'Joanna Kubińska',
            location: 'Warszawa',
            image: {
                medium: '/images/finance/joannaKubinskaEkspertFinansowy.webp',
                large: '/images/finance/joannaKubinskaEkspertFinansowyLarge.webp',
            },
        },
        {
            name: 'Kacper Szustak',
            location: 'Warszawa',
            image: {
                medium: '/images/finance/kacperSzustakEkspertFinansowy.webp',
                large: '/images/finance/kacperSzustakEkspertFinansowyLarge.webp',
            },
        },
        {
            name: 'Ewelina Michalak',
            location: 'Łódź',
            image: {
                medium: '/images/finance/ewelinaMichalakEkspertFinansowy.webp',
                large: '/images/finance/ewelinaMichalakEkspertFinansowyLarge.webp',
            },
        },
        {
            name: 'Michał Skrzypczyński',
            location: 'Poznań',
            image: {
                medium: '/images/finance/michalSkrzypczynskiEkspertFinansowy.webp',
                large: '/images/finance/michalSkrzypczynskiEkspertFinansowyLarge.webp',
            },
        },
        {
            name: 'Marta Ośnicka',
            location: 'Wrocław',
            image: {
                medium: '/images/finance/martaOsnickaEkspertFinansowy.webp',
                large: '/images/finance/martaOsnickaEkspertFinansowyLarge.webp',
            },
        },
        {
            name: 'Cezary Szewczak',
            location: 'Warszawa',
            image: {
                medium: '/images/finance/cezarySzewczakEkspertFinansowy.webp',
                large: '/images/finance/cezarySzewczakEkspertFinansowyLarge.webp',
            },
        },
        {
            name: 'Monika Klak-Stachowiak',
            location: 'Poznań',
            image: {
                medium: '/images/finance/monikaKlakStachowiakEkspertFinansowy.webp',
                large: '/images/finance/monikaKlakStachowiakEkspertFinansowyLarge.webp',
            },
        },
        {
            name: 'Jakub Kuflowski',
            location: 'Wrocław',
            image: {
                medium: '/images/finance/jakubKuflowskiEkspertFinansowy.webp',
                large: '/images/finance/jakubKuflowskiEkspertFinansowyLarge.webp',
            },
        },
    ],
};
