import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    box-sizing: border-box;
    width: 290px;
    min-width: 290px;
    height: auto;
    min-height: 265px;
    border-radius: ${theme.radii['4']};
    background-color: ${theme.colors.$01_white};
`;

export const StyledLink = styled.a`
    display: block;
    color: inherit;
    text-decoration: none;
`;

export const ImageWrapper = styled.div`
    width: 100%;
    height: 165px;
`;

export const StyledImage = styled.img`
    display: block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    object-fit: cover;
    border-top-left-radius: ${theme.radii['4']};
    border-top-right-radius: ${theme.radii['4']};
`;
