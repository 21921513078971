import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import type { CategoryType } from '@type/ad/attributes/advertCategory';
import type { JSX } from 'react';

import { AskAboutPrice } from '../AskAboutPrice/AskAboutPrice';
import {
    AdditionalPriceElement,
    AdditionalPriceWrapper,
    MainPriceWrapper,
    Price,
    PriceSuffix,
    PriceSuffixWrapper,
} from './AdPrice.theme';

export interface AdPriceProps {
    isInvestment: boolean;
    price: AdvertCharacteristic | undefined;
    pricePerMeter: AdvertCharacteristic | undefined;
    transaction: CategoryType;
    rent: AdvertCharacteristic | undefined;
}

export const AdPrice = ({ isInvestment, price, pricePerMeter, transaction, rent }: AdPriceProps): JSX.Element => {
    const [t] = useTranslations();

    const isRent = transaction === 'RENT';

    if (!price || price.value === '') {
        return (
            <Price aria-label={t('frontend.ad.ad-header.price')}>
                <AskAboutPrice />
            </Price>
        );
    }

    const shouldShowPricePerMeter = !isInvestment && !isRent && price && pricePerMeter;
    const shouldShowPriceSuffix = !isInvestment && isRent;
    const shouldShowRent = !isInvestment && rent?.localizedValue && isRent;

    return (
        <>
            <MainPriceWrapper>
                <Price aria-label={t('frontend.ad.ad-header.price')} data-cy="adPageHeaderPrice">
                    {isInvestment
                        ? `${t('frontend.ad.ad-header.price-from')} ${price.localizedValue}`
                        : price.localizedValue}
                </Price>
                {shouldShowPriceSuffix ? (
                    <PriceSuffixWrapper>
                        <PriceSuffix>{t('frontend.ad.ad-header.per-month')}</PriceSuffix>
                    </PriceSuffixWrapper>
                ) : null}
            </MainPriceWrapper>
            <AdditionalPriceWrapper>
                {shouldShowPricePerMeter ? (
                    <AdditionalPriceElement aria-label={t('frontend.ad.ad-header.price-per-meter')}>
                        {pricePerMeter.localizedValue}
                    </AdditionalPriceElement>
                ) : null}
                {shouldShowRent ? (
                    <AdditionalPriceElement>{`${t('frontend.ad.ad-header.rent')} ${rent.localizedValue}`}</AdditionalPriceElement>
                ) : null}
            </AdditionalPriceWrapper>
        </>
    );
};
