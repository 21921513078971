import { REMOTE_CONTENT_DIALOG_ID } from '@domains/ad/consts/remoteContentDialogId';
import { VIRTUAL_WALK_CONFIRMATION_DIALOG_ID } from '@domains/ad/consts/virtualWalkConfirmationDialog';
import { useUserConsentForEmbeddedContent } from '@domains/ad/hooks/useUserConsentForEmbeddedContent';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import Cube from '@nexus/lib-react/dist/core/Icon/icons/default/Cube';
import Video from '@nexus/lib-react/dist/core/Icon/icons/default/Video';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';

import { FullscreenActionButtonsContainer, StyledButton, StyledTooltip } from './FullscreenGalleryActions.theme';

const LazyVirtualWalkConfirmationDialog = dynamic(
    () => import('@domains/ad/nexusComponents/ThirdPartyContentWarningDialog/ThirdPartyContentWarningDialog'),
    { ssr: false },
);

const LazyRemoteContent = dynamic(() => import('@domains/ad/nexusComponents/RemoteContent/RemoteContent'), {
    ssr: false,
});

interface Props {
    walkAroundUrl: string | undefined;
    view3dUrl: string | undefined;
    videoUrl: string | undefined;
}

export const FullscreenGalleryActions = ({ walkAroundUrl, view3dUrl, videoUrl }: Props): JSX.Element => {
    const { openDialog: openVirtualWalkDialog, isOpen: isVirtualWalkDialogOpen } = useDialogState(
        VIRTUAL_WALK_CONFIRMATION_DIALOG_ID,
    );
    const { openDialog: openVideoDialog, isOpen: isVideoDialogOpen } = useDialogState(REMOTE_CONTENT_DIALOG_ID);
    const virtualWalkUrl = walkAroundUrl || view3dUrl;
    const [t] = useTranslations();

    const { isConsentGiven } = useUserConsentForEmbeddedContent();

    return (
        <FullscreenActionButtonsContainer>
            {videoUrl ? (
                <>
                    <StyledTooltip
                        touch={false}
                        placement="top-start"
                        content={t('frontend.adpage.multimedia-section.video')}
                        trigger="mouseenter"
                    >
                        <StyledButton
                            variant="circle"
                            size="large"
                            prefixIcon={Video}
                            data-testid="ad.page.gallery.video.button"
                            onClick={openVideoDialog}
                        />
                    </StyledTooltip>
                    {isVideoDialogOpen && isConsentGiven && <LazyRemoteContent url={videoUrl} />}
                    {isVideoDialogOpen && !isConsentGiven && (
                        <LazyVirtualWalkConfirmationDialog
                            id={REMOTE_CONTENT_DIALOG_ID}
                            url={videoUrl}
                            title={t('frontend.ad.video-confirmation-modal.title')}
                        />
                    )}
                </>
            ) : null}
            {virtualWalkUrl ? (
                <>
                    <StyledTooltip
                        touch={false}
                        placement="top-start"
                        content={t('frontend.adpage.multimedia-section.virtual-walk')}
                        trigger="mouseenter"
                    >
                        <StyledButton
                            variant="circle"
                            size="large"
                            prefixIcon={Cube}
                            onClick={openVirtualWalkDialog}
                            data-testid="ad.page.gallery.virtualwalk.button"
                        />
                    </StyledTooltip>
                    {isVirtualWalkDialogOpen && (
                        <LazyVirtualWalkConfirmationDialog
                            id={VIRTUAL_WALK_CONFIRMATION_DIALOG_ID}
                            url={virtualWalkUrl}
                            title={t('frontend.ad.virtual-walk-confirmation-modal.title')}
                        />
                    )}
                </>
            ) : null}
        </FullscreenActionButtonsContainer>
    );
};
