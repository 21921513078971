import { AD_CONFIG } from '@config/ad/adConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import { NEXUS_ASK_ABOUT_OFFER_MODAL_ID } from '@widgets/MortgageCalculator/core/consts/nexusAskAboutOfferModalId';
import type { Dispatch, JSX, SetStateAction } from 'react';

import {
    AskAboutOffer,
    CalculatedRepayment,
    Clarification,
    Container,
    Repayment,
    SmallHeading,
    StyledButton,
} from './RepaymentSection.theme';

interface Props {
    repayment: string;
    setIsAskAboutOfferModalOpen: Dispatch<SetStateAction<boolean>>;
}

export const RepaymentSection = ({ repayment, setIsAskAboutOfferModalOpen }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { openDialog } = useDialogState(NEXUS_ASK_ABOUT_OFFER_MODAL_ID);

    const {
        mortgageCalculator: {
            isAskAboutOfferButtonEnabled,
            shouldShowRepaymentClarification,
            askAboutOfferUrl,
            shouldUseNexusAskAboutOfferModal,
        },
    } = AD_CONFIG;

    const handleAskAboutOfferButtonClick = (): void => {
        trackEvent('finance_lead_form_click', {
            touch_point_button: 'ad_page_calc',
        });

        if (askAboutOfferUrl) {
            window.location.href = askAboutOfferUrl;
        } else {
            setIsAskAboutOfferModalOpen(true);
            if (shouldUseNexusAskAboutOfferModal) {
                openDialog();
            }
        }
    };

    return (
        <Container>
            <Repayment>
                <SmallHeading>{t('frontend.widget.mortgage-calculator.ad-variant-repayment')}:</SmallHeading>
                <CalculatedRepayment>{repayment}</CalculatedRepayment>
            </Repayment>
            {isAskAboutOfferButtonEnabled ? (
                <AskAboutOffer>
                    <StyledButton
                        variant="secondary"
                        onClick={handleAskAboutOfferButtonClick}
                        data-cy="ad-calculator.ask-about-offer-button"
                    >
                        {t('frontend.widget.mortgage-calculator.ask-about-offer-button')}
                    </StyledButton>
                </AskAboutOffer>
            ) : null}
            {shouldShowRepaymentClarification ? (
                <Clarification>{t('frontend.widget.mortgage-calculator.repayment-clarification')}</Clarification>
            ) : null}
        </Container>
    );
};
