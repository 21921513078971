import type { AdMortgageSimulatorConfig } from '@config/ad/adConfig';
import { AD_CONFIG } from '@config/ad/adConfig';
import { useAdMortgagePrice } from '@domains/ad/nexusComponents/AdHeader/components/FinancingButton/hooks/useAdMortgagePrice';
import { Skeleton } from '@domains/shared/components/Skeleton/Skeleton';
import { BANK_RATE_QUERY, EXPECTED_BANK_RATE_QUERY_TYPENAME } from '@domains/shared/graphql/queries/BankRateQuery';
import { formatPrice } from '@domains/shared/helpers/price/formatPrice';
import { getCurrencyIsoCode } from '@domains/shared/helpers/price/getCurrencyIsoCode';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useAssertGraphqlResponse } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import { useTracking } from '@lib/tracking/useTracking';
import ArrowRight from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowRight';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import { type JSX, useRef } from 'react';
import { useQuery } from 'urql';

import { StyledButton, Wrapper } from './FinancingButton.theme';

export interface FinancingButtonProps {
    price: AdvertCharacteristic;
    simulatorConfig: AdMortgageSimulatorConfig;
}

const { adMortgageSimulatorConfig } = AD_CONFIG;
const { shouldConvertPriceToLocalCurrency, currency: CURRENCY_SYMBOL } = adMortgageSimulatorConfig || {};

export const FinancingButton = ({ price, simulatorConfig }: FinancingButtonProps): JSX.Element => {
    const { lang } = useSiteSettings();
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const { mortgageDownpaymentPercentage, mortgageInterest, mortgageYears, shouldFetchBankRate } = simulatorConfig;

    const [{ data, error, operation, fetching }] = useQuery({
        query: BANK_RATE_QUERY,
        pause: !shouldFetchBankRate,
    });

    const bankRateData = useAssertGraphqlResponse({
        data: data?.bankRate,
        expectedTypenames: EXPECTED_BANK_RATE_QUERY_TYPENAME,
        graphqlError: error,
        logErrorPrefix: '[BankRateQuery]',
        operation,
        fetching,
        pause: !shouldFetchBankRate,
    });

    const { mortgagePrice } = useAdMortgagePrice({
        price,
        mortgageDownpaymentPercentage,
        mortgageInterest: bankRateData?.lowest ?? mortgageInterest,
        mortgageYears,
        shouldConvertPriceToLocalCurrency,
    });
    const currency =
        shouldConvertPriceToLocalCurrency && CURRENCY_SYMBOL ? getCurrencyIsoCode(CURRENCY_SYMBOL) : price.currency;

    const installmentPriceWithCurrency = formatPrice({
        price: mortgagePrice,
        currency,
        lang,
        shouldFormatAsInteger: true,
    });

    const financeButtonRef = useRef<HTMLSpanElement | null>(null);
    useTrackImpression(financeButtonRef, () => {
        trackEvent('finance_link_redirect_impression', {
            touch_point_button: 'under_gallery',
        });
    });

    const handleClick = (): void => {
        if (typeof window !== 'undefined') {
            window.location.hash = 'financial-calculator';
        }

        trackEvent('finance_link_redirect_click');
    };

    return (
        <Wrapper ref={financeButtonRef}>
            {fetching ? (
                <Skeleton height={28} width={170} containerTestId="skeleton-loader" />
            ) : (
                <StyledButton
                    type="button"
                    variant="secondary"
                    size="small"
                    suffixIcon={ArrowRight}
                    onClick={handleClick}
                    data-testid="ad-page.financing-button"
                >
                    <span>{`${t('frontend.ad-header.financing-button.label')} ${installmentPriceWithCurrency}`}</span>
                </StyledButton>
            )}
        </Wrapper>
    );
};
