import { gql } from '@urql/core';

export const UpdateShowPhoneStatisticsMutation = gql`
    mutation UpdateShowPhoneStatistics($advertId: Int64!) {
        updateShowPhoneStatistics(advertId: $advertId) {
            ... on UpdateShowPhoneStatisticsSuccess {
                success
            }
        }
    }
`;
