import { SavedSimilarAdsSearchContext } from '@domains/ad/contexts/SavedSimilarAdsSearchContext';
import { SAVED_SEARCH_CREATE_SIMILAR_OFFER_MUTATION } from '@domains/ad/graphql/mutations/SavedSearchCreateSimilarOfferMutation';
import { toast } from '@domains/shared/components/Toast/toast';
import { logError } from '@domains/shared/helpers/logger';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { useCallback, useContext } from 'react';
import { useMutation } from 'urql';

type HandleSimilarOfferSubscription = (
    adId: number,
    trackingData: Record<string, unknown>,
    onSuccessfulSubscription?: () => void,
) => Promise<void>;

export const useSimilarOfferSubscription = (): [HandleSimilarOfferSubscription, boolean] => {
    const [t] = useTranslations();
    const { updateSavedSimilarAdsSearchStatus } = useContext(SavedSimilarAdsSearchContext);
    const [{ fetching: isFetching }, savedSearchCreateSimilarOffer] = useMutation(
        SAVED_SEARCH_CREATE_SIMILAR_OFFER_MUTATION,
    );
    const { trackEvent } = useTracking();

    const handleSimilarOfferSubscription = useCallback(
        (
            adId: number,
            trackingData: Record<string, unknown>,
            onSuccessfulSubscription: (() => void) | undefined,
        ): Promise<void> => {
            return savedSearchCreateSimilarOffer({
                id: adId,
            })
                .then((response) => {
                    if (response.error) {
                        logError('Error occurred while trying to subscribe to similar offers', {
                            errorMessage: response.error,
                        });
                        toast.error(t('frontend.toast.error'));

                        return;
                    }

                    const similarOffer = response.data?.savedSearchCreateSimilarOffer;

                    const isOperationError =
                        similarOffer?.__typename === 'OperationSearchError' && similarOffer.error !== undefined;
                    const isOperationSuccess =
                        similarOffer?.__typename === 'OperationSearchSuccess' && similarOffer.success;
                    const isOfferExist =
                        similarOffer?.__typename === 'SimilarOfferSearchExists' && !similarOffer.success;

                    if (isOperationError) {
                        logError('Error occurred while trying to subscribe to similar offers', {
                            errorMessage: similarOffer.error,
                        });
                        toast.error(t('frontend.toast.error'));

                        return;
                    }

                    if (isOperationSuccess) {
                        toast.success(t('frontend.ad.similar-offers-subscription.toast-success'), {
                            dataCy: 'toast.subscription-success',
                        });

                        trackEvent('favourite_search_save_success', { ...trackingData });
                        updateSavedSimilarAdsSearchStatus(true);
                        onSuccessfulSubscription?.();

                        return;
                    }

                    if (isOfferExist) {
                        toast.info(t('frontend.ad.similar-offers-subscription.toast-info'), {
                            dataCy: 'toast.subscription-exists',
                        });

                        return;
                    }
                })
                .catch((error) => {
                    logError('Error occurred while trying to subscribe to similar offers', { error });
                    toast.error(t('frontend.toast.error'));
                });
        },
        [savedSearchCreateSimilarOffer, t, trackEvent, updateSavedSimilarAdsSearchStatus],
    );

    return [handleSimilarOfferSubscription, isFetching];
};
