import { ImageContainer } from '@domains/ad/nexusComponents/Gallery/components/MainImage/MainImage.theme';
import type { JSX } from 'react';
import type { ReactImageGalleryItem } from 'react-image-gallery';

interface Props {
    isFullscreen: boolean;
    item: ReactImageGalleryItem;
}

export const MainImage = ({ isFullscreen, item }: Props): JSX.Element => {
    return (
        <ImageContainer isFullscreen={isFullscreen}>
            {item.imageSet && item.imageSet.length > 0
                ? item.imageSet.map((img) => (
                      <source media={img.media} srcSet={img.srcSet} key={img.srcSet} data-testid="image-source" />
                  ))
                : null}
            <img src={item.original} alt={item.originalAlt} loading="lazy" />
        </ImageContainer>
    );
};
