import { UpdateShowPhoneStatisticsMutation } from '@domains/shared/graphql/mutations/UpdateShowPhoneStatisticsMutation';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import type { OperationResult } from '@urql/core';
import { useMutation } from 'urql';

interface UpdateShowPhoneStatisticsVariables {
    advertId: number;
}

interface Output {
    updateShowPhoneStatistics: (variables: UpdateShowPhoneStatisticsVariables) => Promise<OperationResult>;
}

export const usePhoneRevealStatistics = (): Output => {
    const [{ error: updateStatsError, operation }, updateShowPhoneStatistics] = useMutation(
        UpdateShowPhoneStatisticsMutation,
    );

    useHandleGraphqlError({ descriptor: 'usePhoneRevealStatistics', error: updateStatsError, operation });

    return { updateShowPhoneStatistics };
};
