interface Params {
    isDesktop: boolean;
    isForRent: boolean;
    shouldHideBaxterUnits?: boolean;
}

interface Result {
    shouldRenderBaxterAdsMobileAdUnit: boolean;
    shouldRenderBaxterAdsDesktopAdUnit: boolean;
}

export const getAdUnitRenderConditions = ({ isDesktop, isForRent, shouldHideBaxterUnits = false }: Params): Result => {
    if (shouldHideBaxterUnits) {
        return {
            shouldRenderBaxterAdsMobileAdUnit: false,
            shouldRenderBaxterAdsDesktopAdUnit: false,
        };
    }

    return {
        shouldRenderBaxterAdsMobileAdUnit: !isDesktop && !isForRent,
        shouldRenderBaxterAdsDesktopAdUnit: isDesktop && !isForRent,
    };
};
