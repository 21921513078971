import { EXPERIMENT } from '@domains/ad/consts/experiment';
import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import type { FC } from 'react';

interface Props {
    isMobile: boolean;
}

export const AdTopAdvert: FC<Props> = () => {
    useBaxterAdvertising();

    const { isVariantEnabled } = useExperiments();
    const isSMR3411VariantB = isVariantEnabled(EXPERIMENT.smr3411, 'b');

    if (isSMR3411VariantB) {
        return null;
    }

    return <AdvertSlot id="baxter-a-parameters" />;
};
