import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

const EXTRA_BOTTOM_SPACE_FOR_STICKY_FOOTER_MOBILE_VIEW = '65px';

export const Container = styled.div`
    @media ${theme.mediaQueries.lg} {
        margin-bottom: ${EXTRA_BOTTOM_SPACE_FOR_STICKY_FOOTER_MOBILE_VIEW};
    }
`;
