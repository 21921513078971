import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Dialog, DialogBody } from '@nexus/lib-react/dist/core/Dialog';

export const StyledDialog = styled(Dialog)`
    position: relative;
    width: 100% !important;
    height: 100%;
    max-height: 100%;
    border-radius: ${theme.radii['0']};

    @media ${theme.mediaQueries.smMin} {
        width: auto !important;
        height: fit-content;
        border-radius: ${theme.radii.medium};
    }
`;

export const StyledDialogBody = styled(DialogBody)`
    align-items: flex-start;
    max-width: 500px;
    max-height: fit-content;
`;
