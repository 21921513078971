import type { OpenGalleryRef } from '@domains/ad/components/AdMediaGallery/openGalleryRef';
import type { Ad } from '@domains/ad/types/Ad';
import type { JSX, PropsWithChildren } from 'react';
import { createContext } from 'react';

import { type ProjectImageGalleryMethods, useProjectImageGallery } from '../shared/hooks/useProjectImageGallery';

interface Props {
    ad: Ad;
    openGalleryRef?: OpenGalleryRef;
}

interface GalleryContextValue {
    projectImageGalleryMethods: ProjectImageGalleryMethods;
}

export const GalleryContext = createContext<GalleryContextValue>({} as GalleryContextValue);

export const GalleryContextProvider = ({ ad, openGalleryRef, children }: PropsWithChildren<Props>): JSX.Element => {
    const projectImageGalleryMethods = useProjectImageGallery({
        advert: ad,
        galleryTrackingData: {},
        isAdvancedGallery: false,
        openGalleryRef,
        shouldDisplayPredefinedContactMessages: false,
    });

    return <GalleryContext.Provider value={{ projectImageGalleryMethods }}>{children}</GalleryContext.Provider>;
};
