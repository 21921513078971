import { AD_CONFIG } from '@config/ad/adConfig';
import { SITE_CONFIG } from '@config/siteConfig';
import { getCurrencyIsoCode } from '@domains/shared/helpers/price/getCurrencyIsoCode';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';

import {
    Container,
    Heading,
    HeadingWrapper,
    Paragraph,
    StyledIcon,
    StyledSegment,
    StyledSegmentedControl,
    Title,
} from './Header.theme';

interface HeaderProps {
    selectedCurrencyCode: string;
    onCurrencyCodeChange: (selectedCurrency: string) => void;
}

const { currency } = SITE_CONFIG.defaultUnits;
const { adMortgageSimulatorConfig } = AD_CONFIG;
const { shouldConvertPriceToLocalCurrency, currency: LOCAL_CURRENCY_SYMBOL } = adMortgageSimulatorConfig || {};

const DEFAULT_CURRENCY_CODE = getCurrencyIsoCode(currency);
const LOCAL_CURRENCY_CODE = LOCAL_CURRENCY_SYMBOL ? getCurrencyIsoCode(LOCAL_CURRENCY_SYMBOL) : undefined;

export const Header = ({ selectedCurrencyCode, onCurrencyCodeChange }: HeaderProps): JSX.Element => {
    const [t] = useTranslations();

    return (
        <Container>
            <Title>
                <HeadingWrapper>
                    <StyledIcon />
                    <Heading>{t('frontend.widget.mortgage-calculator.ad-variant-title')}</Heading>
                </HeadingWrapper>

                {shouldConvertPriceToLocalCurrency && LOCAL_CURRENCY_CODE ? (
                    <StyledSegmentedControl
                        activeSegment={selectedCurrencyCode}
                        onSegmentChange={onCurrencyCodeChange}
                        variant="dark"
                    >
                        <StyledSegment name={LOCAL_CURRENCY_CODE} />
                        <StyledSegment name={DEFAULT_CURRENCY_CODE} />
                    </StyledSegmentedControl>
                ) : null}
            </Title>
            <Paragraph>{t('frontend.widget.mortgage-calculator.ad-variant-paragraph')}</Paragraph>
        </Container>
    );
};
