import { ButtonContentWrapper } from '@domains/ad/nexusComponents/AdPageTopBar/AdPageTopBar.theme';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { handleShareApi } from '@domains/shared/helpers/handleShareApi';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import Share from '@nexus/lib-react/dist/core/Icon/icons/default/Share';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { useContext } from 'react';

const LazyShareAdModal = dynamic(
    () => import('@widgets/ModalVariants/ShareAdModal').then((module) => module.ShareAdModal),
    {
        ssr: false,
    },
);

interface Props {
    adId: string;
    title: string;
    url: string;
    showLabel?: boolean;
}

const ShareAdButton = ({ adId, title, url, showLabel }: Props): JSX.Element => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const { isDesktop } = useContext(RWDContext);

    const shareModalId = `AD_PAGE_SHARE_${adId}`;

    const { openDialog, isOpen } = useDialogState(shareModalId);

    const handleClick = (): void => {
        trackEvent('share_ad_click');
        handleShareApi({
            shareData: {
                title,
                text: title,
                url,
            },
            isDesktop,
            shareApiSuccessCallback: () => trackEvent('share_ad_selected', { share_ad_method_type: 'native' }),
            nonShareApiCallback: () => {
                openDialog();
            },
        });
    };

    return (
        <>
            <Button variant="flat" onClick={handleClick} data-testid="ad-page-share-button">
                <ButtonContentWrapper>
                    <Share />
                    {isDesktop && showLabel ? t('frontend.saved-ads.actions.share-button') : null}
                </ButtonContentWrapper>
            </Button>
            {isOpen && <LazyShareAdModal id={shareModalId} url={url} />}
        </>
    );
};

export default ShareAdButton;
