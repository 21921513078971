import { GalleryContext } from '@domains/ad/contexts/GalleryContext';
import { FullscreenGalleryActions } from '@domains/ad/nexusComponents/Gallery/components/FullscreenGalleryActions/FullscreenGalleryActions';
import FullscreenGalleryHeader from '@domains/ad/nexusComponents/Gallery/components/FullscreenGalleryHeader/FullscreenGalleryHeader';
import { MainImage } from '@domains/ad/nexusComponents/Gallery/components/MainImage/MainImage';
import { GalleryWrapper, IndexCounter, LeftNav, RightNav } from '@domains/ad/nexusComponents/Gallery/Gallery.theme';
import { useGalleryEventMethods } from '@domains/ad/shared/hooks/useGalleryEventMethods';
import type { Ad } from '@domains/ad/types/Ad';
import { GalleryBullets } from '@domains/shared/components/GalleryBullets/GalleryBullets';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import Camera from '@nexus/lib-react/dist/core/Icon/icons/default/Camera';
import ChevronLeft from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronLeft';
import ChevronRight from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronRight';
import type { JSX, MouseEventHandler } from 'react';
import { useCallback, useContext, useEffect, useState } from 'react';
import type { ReactImageGalleryItem } from 'react-image-gallery';
import ReactImageGallery from 'react-image-gallery';

import { useDeviceOrientation } from './hooks/useDeviceOrientation';

interface Props {
    ad: Ad;
}

export const Gallery = ({ ad }: Props): JSX.Element => {
    const { isDesktop } = useContext(RWDContext);
    const { projectImageGalleryMethods } = useContext(GalleryContext);
    const isLandscape = useDeviceOrientation();

    const {
        currentIndex,
        galleryWrapperRef,
        galleryImageSets,
        galleryRef,
        isFullScreen,
        isGallery,
        isKeyboardNavigationEnabled,
        onImageClick,
        reassignGalleryImages,
        setCurrentIndex,
        setGalleryImageSets,
        setIsContactFormSlide,
        setIsFullScreen,
        setIsGallery,
        setIsTransitionInProgress,
        setIsKeyboardNavigationEnabled,
        setTransition,
    } = projectImageGalleryMethods;

    const { links, id, title, url } = ad;
    const { videoUrl, view3dUrl, walkaroundUrl } = links || {};
    const areLinksAvailable = !!(videoUrl || view3dUrl || walkaroundUrl);
    const [shouldDisplayWalkaroundOverlay, setShouldDisplayWalkaroundOverlay] = useState<boolean>(areLinksAvailable);
    const slidesTotalCount = galleryImageSets.length;
    const isLastSlideIndex = currentIndex === slidesTotalCount - 1;

    const onWalkaroundOverlayDismiss = (): void => setShouldDisplayWalkaroundOverlay(false);

    const { onThumbnailClick, onSlide, onThumbnailError, onScreenChange } = useGalleryEventMethods({
        galleryRef,
        isGallery,
        currentIndex,
        isFullScreen,
        isAdvancedGallery: false,
        isLastSlideIndex,
        shouldDisplayWalkaroundOverlay,
        slidesTotalCount,
        onWalkaroundOverlayDismiss,
        setIsContactFormSlide,
        setIsTransitionInProgress,
        setCurrentIndex,
        setGalleryImageSets,
        setIsFullScreen,
        setIsGallery,
        setIsKeyboardNavigationEnabled,
        setTransition,
        isNexusGallery: true,
    });

    const handleCloseFullscreen = (): void => {
        galleryRef.current?.exitFullScreen();
    };

    const shouldShowThumbnails = !!isDesktop || (isFullScreen && !isLandscape);

    useEffect(() => {
        setCurrentIndex(0);
    }, [ad.id, setCurrentIndex]);

    const renderLeftNav = useCallback(
        (onClick: MouseEventHandler<HTMLElement>): JSX.Element => (
            <LeftNav onClick={onClick} isFullscreen={isFullScreen}>
                <ChevronLeft />
            </LeftNav>
        ),
        [isFullScreen],
    );

    const renderRightNav = useCallback(
        (onClick: MouseEventHandler<HTMLElement>): JSX.Element => (
            <RightNav onClick={onClick} isFullscreen={isFullScreen}>
                <ChevronRight />
            </RightNav>
        ),
        [isFullScreen],
    );

    const handleScreenChange = useCallback(
        (isFullScreen: boolean): void => {
            onScreenChange(isFullScreen);
            reassignGalleryImages(isFullScreen);
        },
        [onScreenChange, reassignGalleryImages],
    );

    const renderGalleryItem = useCallback(
        (item: ReactImageGalleryItem) => <MainImage isFullscreen={isFullScreen} item={item} />,
        [isFullScreen],
    );

    return (
        <GalleryWrapper ref={galleryWrapperRef} isFullscreen={isFullScreen} shouldShowThumbnails={shouldShowThumbnails}>
            {isFullScreen ? (
                <>
                    <FullscreenGalleryHeader
                        adId={id}
                        url={url}
                        title={title}
                        onLeaveFullscreen={handleCloseFullscreen}
                        data-testid="fullscreen-gallery-header"
                    />
                    <FullscreenGalleryActions videoUrl={videoUrl} view3dUrl={view3dUrl} walkAroundUrl={walkaroundUrl} />
                </>
            ) : null}
            <IndexCounter isFullscreen={isFullScreen} shouldShowThumbnails={shouldShowThumbnails}>
                <Camera size="icon-small" />
                {currentIndex + 1} / {slidesTotalCount}
            </IndexCounter>
            <ReactImageGallery
                disableThumbnailScroll={false}
                ref={galleryRef}
                disableKeyDown={!isKeyboardNavigationEnabled}
                onClick={onImageClick}
                items={galleryImageSets}
                showPlayButton={false}
                lazyLoad={true}
                useBrowserFullscreen={false}
                useTranslate3D={true}
                showFullscreenButton={false}
                onSlide={onSlide}
                showThumbnails={shouldShowThumbnails}
                thumbnailPosition="bottom"
                startIndex={currentIndex}
                onThumbnailClick={onThumbnailClick}
                onThumbnailError={onThumbnailError}
                onScreenChange={handleScreenChange}
                renderItem={renderGalleryItem}
                renderLeftNav={renderLeftNav}
                renderRightNav={renderRightNav}
            />
            {isDesktop || isFullScreen ? null : (
                <GalleryBullets currentIndex={currentIndex} slidesCount={galleryImageSets.length} />
            )}
        </GalleryWrapper>
    );
};

export default Gallery;
