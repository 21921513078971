import { listenForPlatformEvent, unmountPlatformEvent } from '@lib/events';
import { useEffect } from 'react';

export const usePlatformEventSubscription = (eventName: string, callback: () => void): void => {
    useEffect(() => {
        listenForPlatformEvent(eventName, callback);

        return (): void => {
            unmountPlatformEvent(eventName, callback);
        };
    }, [eventName, callback]);
};
