import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Tag } from '@nexus/lib-react/dist/core/Tag';

export const Container = styled.div`
    padding: ${theme.space['16']};
    background-color: ${theme.colors.$01_white};
    color: ${theme.colors['text-brand-primary']};

    @media ${theme.mediaQueries.mdMin} {
        padding: ${theme.space['24']};
    }
`;

export const Title = styled.h1`
    margin: 0;
    margin-bottom: ${theme.space[16]};
    font-size: ${theme.fontSizes[16]};
    font-weight: ${theme.fontWeights.regular};

    @media ${theme.mediaQueries.mdMin} {
        margin-bottom: ${theme.space[12]};
    }
`;

export const PriceSection = styled.div`
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    margin-bottom: ${theme.space[16]};
    gap: ${theme.space[8]};

    @media ${theme.mediaQueries.mdMin} {
        margin-bottom: ${theme.space[12]};
    }
`;

export const AdUnit = styled.div`
    place-self: 'right';
    width: 170px;
    margin: 'auto 0';
    overflow: hidden;
`;

export const TagSection = styled.div`
    display: flex;
    gap: ${theme.space[10]};
    margin-top: ${theme.space[16]};

    @media ${theme.mediaQueries.mdMin} {
        margin-top: ${theme.space[12]};
    }

    > * {
        margin-top: 0;
    }
`;

const StyledTag = styled(Tag)`
    display: inline-block;
    margin-top: ${theme.space[12]};
    font-weight: ${theme.fontWeights.bold};
    letter-spacing: 0;
    text-transform: none;
`;

export const ExclusiveOfferTag = styled(StyledTag)`
    margin-top: 0;
    background-color: ${theme.colors['background-global-inverse']};
    color: ${theme.colors.$01_white};
`;

export const OpenDayTag = styled(StyledTag)`
    background-color: ${theme.colors['background-brand-confirmationScreen-success']};
    color: ${theme.colors['text-brand-highlight']};
`;

export const AdUnitContainer = styled.div`
    margin-top: ${theme.space[16]};

    @media ${theme.mediaQueries.mdMin} {
        margin-top: ${theme.space[12]};
    }
`;
