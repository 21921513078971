import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { DialogHeader } from '@nexus/lib-react/dist/core/Dialog';
import CloseIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Close';

export const StyledDialogHeader = styled(DialogHeader)`
    display: flex;
    justify-content: space-between;
`;

export const Heading = styled.div`
    display: flex;
    gap: ${theme.space['16']};
`;

export const StyledCloseIcon = styled(CloseIcon)`
    &:hover {
        cursor: pointer;
    }
`;
