import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { ExtendedAdInformation } from '@type/ad/adDetails';

const EXTRA_TYPES_PREFIX = 'extras_types::';

export const extractHighlightsFromExtras = (
    keysToFind: string[],
    extrasTypes: ExtendedAdInformation | undefined,
    t: Translator,
): ExtendedAdInformation[] => {
    if (!extrasTypes) {
        return [];
    }

    if (keysToFind.length === 0) {
        return [extrasTypes];
    }

    return keysToFind
        .map((key) => {
            const extra = extrasTypes.values.find((extra) => {
                if (typeof extra === 'string') {
                    return extra === `${EXTRA_TYPES_PREFIX}${key}`;
                }

                return false;
            });

            if (extra) {
                return {
                    label: key,
                    values: [t(`frontend.ad.table-information-value-extras_types-${key}`)],
                    unit: '',
                };
            }

            return {
                label: '',
                values: [],
                unit: '',
            };
        })
        .filter((highlight) => !!highlight.label);
};
