import { getPluralTranslationVariant } from '@domains/shared/helpers/getPluralTranslationVariant';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import type { RoomsValue } from '@type/ad/adDetails';

const ROOMS_NUMBER_MORE = 'rooms_num::more';
export const createRoomsNumberLabel = (
    t: Translator,
    lang: Locale,
    roomsNumber: RoomsValue | string,
    shouldUseTypology: boolean | undefined,
): string => {
    const value = typeof roomsNumber === 'string' ? roomsNumber : roomsNumber.value;
    const numberValue = Number(value);
    if (shouldUseTypology) {
        if (numberValue > 9 || value === ROOMS_NUMBER_MORE) {
            return t('frontend.search.advert-specs-list.rooms-typology-more');
        }

        return t('frontend.search.advert-specs-list.rooms-typology-many', {
            value: String(numberValue - 1),
        });
    }

    if (value === ROOMS_NUMBER_MORE) {
        return t('frontend.search.advert-specs-list.rooms-more');
    }

    const translationVariant = getPluralTranslationVariant({
        lang,
        value: numberValue,
        translation: {
            zero: 'frontend.search.advert-specs-list.rooms-zero',
            one: 'frontend.search.advert-specs-list.rooms-one',
            two: 'frontend.search.advert-specs-list.rooms-two',
            few: 'frontend.search.advert-specs-list.rooms-few',
            many: 'frontend.search.advert-specs-list.rooms-many',
            other: 'frontend.search.advert-specs-list.rooms-other',
        },
    });

    return t(translationVariant, { rooms: value });
};
