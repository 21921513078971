import ShareAdButton from '@domains/ad/nexusComponents/AdPageTopBar/components/ShareAdButton/ShareAdButton';
import ChevronLeft from '@nexus/lib-react/dist/core/Icon/icons/default/ChevronLeft';
import type { JSX } from 'react';
import { useState } from 'react';

import { ActionsContainer, CloseButton, Container, SavedAdButton } from './FullscreenGalleryHeader.theme';

interface Props {
    adId: number;
    title: string;
    url: string;
    onLeaveFullscreen(): void;
}

const FullscreenGalleryHeader = ({ adId, title, url, onLeaveFullscreen }: Props): JSX.Element => {
    const [isRedirectToLoginModalOpen, setIsRedirectToLoginModalOpen] = useState(false);

    return (
        <Container>
            <CloseButton
                variant="flat"
                size="small"
                onClick={onLeaveFullscreen}
                data-testid="ad-page-gallery-close-button"
            >
                <ChevronLeft />
            </CloseButton>
            <ActionsContainer>
                <ShareAdButton adId={String(adId)} title={title} url={url} />
                <SavedAdButton
                    id={adId}
                    data-testid="ad-page-gallery-save-ad-button"
                    nexusButtonVariant="flat"
                    showAsNexusButton
                    isRedirectToLoginModalOpen={isRedirectToLoginModalOpen}
                    setIsRedirectToLoginModalOpen={setIsRedirectToLoginModalOpen}
                />
            </ActionsContainer>
        </Container>
    );
};

export default FullscreenGalleryHeader;
