import { LoginModalContext } from '@domains/ad/contexts/LoginModalContext';
import { SavedSimilarAdsSearchContext } from '@domains/ad/contexts/SavedSimilarAdsSearchContext';
import { getSimilarAdsTrackingData } from '@domains/ad/helpers/getSimilarAdsTrackingData';
import type { SimilarAdsViewType } from '@domains/ad/types/similarAdsViewType';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useSimilarOfferSubscription } from '@domains/shared/hooks/useSimilarOfferSubscription';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faBell } from '@fortawesome/free-solid-svg-icons/faBell';
import { CurrentUserContext } from '@lib/currentUser/CurrentUserContext';
import { useTracking } from '@lib/tracking/useTracking';
import TickIcon from '@nexus/lib-react/dist/core/Icon/icons/default/Tick';
import type { FC } from 'react';
import { useCallback, useContext, useEffect } from 'react';

import { StyledButton, StyledNexusButton } from './AdSimilarOffersButton.theme';
import { getButtonLabel } from './helpers/getButtonLabel';
import { getDataCyAttribute } from './helpers/getDataCyAttribute';

export const SIMILAR_OFFERS_PARAMETER = 'similarOffers';

interface Props {
    adId: number;
    shouldShowBellIcon?: boolean;
    viewType: SimilarAdsViewType;
    shouldUseNexusVariant?: boolean;
    onClick?(): void;
}

export const AdSimilarOffersButton: FC<Props> = ({
    adId,
    shouldShowBellIcon = true,
    viewType,
    onClick,
    shouldUseNexusVariant,
}) => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const currentUser = useContext(CurrentUserContext);
    const [handleSimilarOfferSubscription, isFetching] = useSimilarOfferSubscription();

    const { toggleLoginModalState } = useContext(LoginModalContext);
    const trackingData = getSimilarAdsTrackingData(viewType);

    const { similarAdsSaveSearchExists } = useContext(SavedSimilarAdsSearchContext);

    const handleHashChangeEvent = useCallback((): void => {
        if (typeof window !== 'undefined' && window.location.hash.includes(SIMILAR_OFFERS_PARAMETER)) {
            handleSimilarOfferSubscription(adId, trackingData);
            window.removeEventListener('hashchange', handleHashChangeEvent, false);
            window.location.hash = '';
        }
    }, [handleSimilarOfferSubscription, adId, trackingData]);

    useEffect(() => {
        /* this call is needed to subscribe after page gets reloaded during email login */
        handleHashChangeEvent();

        window.addEventListener('hashchange', handleHashChangeEvent, false);

        /* listener should be removed when user leaves the page */
        return (): void => window.removeEventListener('hashchange', handleHashChangeEvent, false);
    }, [handleHashChangeEvent]);

    const handleClick = (): void => {
        trackEvent('favourite_search_save', trackingData);

        onClick?.();
        if (currentUser) {
            handleSimilarOfferSubscription(adId, trackingData);

            return;
        }

        toggleLoginModalState('login', viewType);
    };

    const { isDesktop } = useContext(RWDContext);
    const buttonLabel = t(getButtonLabel(viewType, similarAdsSaveSearchExists, !!isDesktop));
    const nexusButtonLabel = similarAdsSaveSearchExists
        ? t('frontend.ad.similar-offers-button.disabled-label')
        : t('frontend.ad.similar-offers-button.enabled-label');

    if (shouldUseNexusVariant) {
        return (
            <StyledNexusButton
                variant="secondary"
                onClick={handleClick}
                disabled={isFetching || similarAdsSaveSearchExists}
                prefixIcon={similarAdsSaveSearchExists ? TickIcon : undefined}
                size="small"
                dataAttributes={{ cy: 'ad-similar-offers-button' }}
            >
                {nexusButtonLabel}
            </StyledNexusButton>
        );
    }

    return (
        <>
            <StyledButton
                icon="left"
                shouldFitContainer
                data-cy={getDataCyAttribute(viewType)}
                variant={viewType === 'AD' ? ButtonVariant.Ghost : ButtonVariant.Primary}
                onClick={handleClick}
                disabled={isFetching || similarAdsSaveSearchExists}
            >
                {shouldShowBellIcon ? <Icon icon={faBell} /> : null}
                {buttonLabel}
            </StyledButton>
        </>
    );
};
