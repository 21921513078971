import type { SelectedRate } from '@widgets/CompareAds/CompareAdsDialog/components/RateButtons/RateButtons';

export const MIN_MESSAGE_LENGTH = 5;
export const MAX_MESSAGE_LENGTH = 2000;

export const isMessageValid = (message: string, selectedRate: SelectedRate): boolean => {
    if (selectedRate === 'yes') {
        return message.trim().length >= MIN_MESSAGE_LENGTH;
    }

    return true; // message is not required if user selects "no" answer
};
