import type { ImageSet } from '@domains/ad/types/ImageSet';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import type { AdvertImage as LegacyAdvertImage } from '@type/gallery/advertImage';

import { getImageUrl } from './getImageUrl';

interface CreateSingleImageSetProps {
    image: LegacyAdvertImage;
    title: string;
    t: Translator;
    index: number;
    totalCount: number;
}

export const createSingleImageSet = ({ image, title, t, index, totalCount }: CreateSingleImageSetProps): ImageSet => {
    const { thumbnail, medium, large } = image;

    const thumbnailUrl = getImageUrl(thumbnail);
    const mediumUrl = getImageUrl(medium);
    const largeUrl = getImageUrl(large);

    // Temporary deleted small size picture for a better quality photos on adPage -> REPT-508
    const imageSet = [
        { srcSet: mediumUrl, media: `(max-width: ${BREAKPOINT.sm})` },
        { srcSet: largeUrl, media: `(min-width: ${BREAKPOINT.sm})` },
    ];

    return {
        thumbnail: thumbnailUrl,
        thumbnailAlt: `${title} - ${t('frontend.ad.image-gallery.thumbnail')}: ${index + 1}/${totalCount}`,
        thumbnailLoading: 'lazy',
        original: largeUrl,
        originalAlt: `${title} - ${t('frontend.ad.image-gallery.full-image')}: ${index + 1}/${totalCount}`,
        imageSet,
        originalImageSet: imageSet, // it is needed for high quality photos in fullscreen
    };
};
