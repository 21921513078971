import { AdReportAd } from '@domains/ad/components/AdReportAd/AdReportAd';
import { getExternalId } from '@domains/ad/helpers/getExternalId';
import { Container, DetailsContainer, DetailsProperty } from '@domains/ad/nexusComponents/AdMetadata/AdMetadata.theme';
import { AdDescriptionExternalId } from '@domains/ad/nexusComponents/AdMetadata/components/AdDescriptionExternalId/AdDescriptionExternalId';
import type { Ad } from '@domains/ad/types/Ad';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import dayjs from 'dayjs';
import type { JSX } from 'react';

interface Props {
    ad: Ad;
}

export const AdMetadata = ({ ad }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { modifiedAt, id, externalId, referenceId, creationSource, createdAt } = ad;

    const additionalId = getExternalId({ creationSource, referenceId, externalId });
    const dateFormatPattern = 'D.MM.YYYY';
    const modifiedDate = dayjs(modifiedAt).format(dateFormatPattern);
    const createdDate = dayjs(createdAt).format(dateFormatPattern);

    return (
        <>
            <Container data-testid="ad.metadata">
                <DetailsContainer>
                    {modifiedAt ? (
                        <DetailsProperty>
                            {modifiedDate
                                ? `${t('frontend.ad-content.description.date-modified')}: ${modifiedDate}`
                                : null}
                        </DetailsProperty>
                    ) : null}
                    <DetailsProperty>
                        {createdDate ? `${t('frontend.ad-content.description.date-created')}: ${createdDate}` : null}
                    </DetailsProperty>
                    <DetailsProperty>
                        {t('frontend.ad-content.description.offer-id')}: {id}
                    </DetailsProperty>
                </DetailsContainer>
                <AdReportAd ad={ad} shouldShowNexusVariant />
            </Container>
            <AdDescriptionExternalId id={additionalId} t={t} />
        </>
    );
};
