import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { H3, P2 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    padding-bottom: ${theme.space[14]};
`;

export const Repayment = styled.div`
    display: flex;
    justify-content: center;
    margin-bottom: ${theme.space[28]};
`;

export const Clarification = styled.p`
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes.p4};
`;

export const AskAboutOffer = styled.div`
    width: 100%;
    margin-bottom: ${theme.space[16]};
    text-align: center;
`;

export const SmallHeading = styled(P2)`
    margin-top: ${theme.space[12]};
    font-weight: ${theme.fontWeights.bold};
`;

export const CalculatedRepayment = styled(H3)`
    margin: ${theme.space[6]} ${theme.space[0]} ${theme.space[0]} ${theme.space[12]};
    color: ${theme.colors['text-brand-highlight']};
`;

export const StyledButton = styled(Button)`
    width: 100%;

    @media ${theme.mediaQueries.smMin} {
        width: auto;
    }
`;
