import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import MapView from '@nexus/lib-react/dist/core/Icon/icons/default/MapView';

export const Container = styled.div`
    display: flex;
    align-items: center;
`;

export const StyledLink = styled.a`
    display: flex;
    align-items: center;
    color: ${theme.colors['text-brand-primary']};
    font-size: ${theme.sizes[14]};
    font-weight: ${theme.fontWeights.bold};
    text-decoration: none;
`;

export const StyledMapViewIcon = styled(MapView)`
    position: relative;
    margin-right: ${theme.space[6]};
`;
