import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { EnergyCertificate } from '@type/ad/advertCharacteristic';

export const getEnergyCertificateReadableValue = (energyCertificate: EnergyCertificate, t: Translator): string => {
    switch (energyCertificate) {
        case 'aplus': {
            return 'A+';
        }

        case 'bminus': {
            return 'B-';
        }

        case 'exempt': {
            return t('frontend.ad.table.energy-certificate-in-progress-or-exempt');
        }

        default: {
            return energyCertificate.toUpperCase();
        }
    }
};
