import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';

export const getProjectPrice = (characteristics: AdvertCharacteristic[], t: Translator): string | null => {
    const getCharacteristic = getAdCharacteristic(characteristics);

    const investmentPricePerMeterFromCharacteristic = getCharacteristic('price_per_m_from')?.localizedValue;

    return investmentPricePerMeterFromCharacteristic
        ? `${t('frontend.ad.user-ads.project-price-from')} ${investmentPricePerMeterFromCharacteristic}`
        : null;
};
