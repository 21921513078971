import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P2, P3, P4 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    padding-top: ${theme.space[24]};
    padding-bottom: ${theme.space[12]};
    border-top: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
`;

export const Header = styled(P2)`
    font-weight: ${theme.fontWeights.bold};
`;

export const Content = styled.div`
    display: flex;
    flex-flow: column nowrap;

    @media ${theme.mediaQueries.smMin} {
        flex-direction: row;
    }
`;

export const Paragraph = styled(P3)`
    display: block;
    width: 90%;
    margin-top: ${theme.space[20]};
    margin-bottom: ${theme.space[28]};
    color: ${theme.colors['text-global-secondary']};
`;

export const TextWrapper = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Label = styled(P4)`
    color: ${theme.colors['text-global-secondary']};
    text-align: center;
    white-space: nowrap;
`;

export const LogoWrapper = styled.div`
    text-align: center;
`;

export const LogoSection = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    width: 160px;
    margin-top: ${theme.space[20]};

    @media ${theme.mediaQueries.smMin} {
        justify-content: center;
        margin-top: 0;
    }
`;
