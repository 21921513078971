import type { RecommendedAd } from '@domains/ad/types/Ad';
import { gql } from '@urql/core';

interface Data {
    relatedOwnerAdverts: {
        items: RecommendedAd[];
    };
}

interface Variables {
    advertId: number;
    limit: number;
}

export const RELATED_OWNER_ADS_QUERY = gql<Data, Variables>`
    query relatedOwnerAdverts($advertId: ID!, $limit: Int64) {
        relatedOwnerAdverts(advertId: $advertId, limit: $limit) {
            ... on RelatedOwnerAdvertsResponse {
                items {
                    adId
                    adCategory {
                        type
                    }
                    characteristics {
                        currency
                        key
                        value
                        localizedValue
                    }
                    images {
                        thumbnail
                        small
                    }
                    title
                    url
                    location: locationDetails {
                        mapDetails {
                            radius
                        }
                        address {
                            street {
                                name
                            }
                        }
                        reverseGeocoding {
                            locations {
                                fullName
                            }
                        }
                    }
                }
            }
        }
    }
`;
