import { Button } from '@domains/shared/components/Button/Button';
import styled from '@emotion/styled';
import { BREAKPOINT } from '@lib/styles/partials/breakpoints';
import { theme } from '@lib/styles/themes/nexus';
import { Button as NexusButton } from '@nexus/lib-react/dist/core/Button';

export const StyledButton = styled(Button)`
    margin-top: 16px;

    @media (min-width: ${BREAKPOINT.md}) {
        width: 310px;
        margin-top: 0;
    }
`;

export const StyledNexusButton = styled(NexusButton)`
    width: 100%;

    @media ${theme.mediaQueries.smMin} {
        width: auto;
    }
`;
