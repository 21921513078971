import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { DEFAULT_FIELD_OPTION } from '@widgets/MortgageCalculator/core/consts/fieldOption';
import { FIELDS_NAME } from '@widgets/MortgageCalculator/core/consts/fieldsName';
import type { DefaultValues } from '@widgets/MortgageCalculator/core/types';
import type { JSX } from 'react';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { BaseFieldProps } from '../../AdCalculator';
import { FieldLeftColumn, FieldRightColumn } from '../../AdCalculator.theme';
import { NumberInput } from '../NumberInput/NumberInput';
import { StyledFieldDescription, StyledFieldLabel, StyledFieldWrapper } from './PropertyPrice.theme';

interface PropertyPriceProps {
    control: Control<DefaultValues>;
    baseFieldProps: BaseFieldProps;
    propertyPriceWatched: number;
    selectedCurrency: string;
}

export const PropertyPrice = ({
    control,
    baseFieldProps,
    propertyPriceWatched,
    selectedCurrency,
}: PropertyPriceProps): JSX.Element => {
    const [t] = useTranslations();

    return (
        <StyledFieldWrapper>
            <FieldLeftColumn>
                <StyledFieldLabel htmlFor={FIELDS_NAME.propertyPrice}>
                    {t('frontend.finance.mortgage-calculator.property-price')}
                </StyledFieldLabel>
                <StyledFieldDescription>
                    {t('frontend.finance.mortgage-calculator.property-price-description')}
                </StyledFieldDescription>
            </FieldLeftColumn>
            <FieldRightColumn>
                <Controller
                    control={control}
                    name={FIELDS_NAME.propertyPrice}
                    render={({ field: { value } }): JSX.Element => {
                        const fieldProps = {
                            ...baseFieldProps,
                            name: FIELDS_NAME.propertyPrice,
                            value: value || 0,
                            options: {
                                ...DEFAULT_FIELD_OPTION[FIELDS_NAME.propertyPrice],
                                suffix: selectedCurrency,
                            },
                            tracking: {
                                eventName: 'calc_total_value',
                                data: {
                                    touch_point_button: 'mortgage_calculator',
                                },
                            },
                            defaultValue: propertyPriceWatched,
                        };

                        return <NumberInput {...fieldProps} />;
                    }}
                />
            </FieldRightColumn>
        </StyledFieldWrapper>
    );
};
