import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';

export const ContactSectionWrapper = styled.div`
    display: grid;
    position: absolute;
    z-index: ${LAYER.x1};
    left: 50%;
    align-items: center;
    width: min(100%, 360px);
    height: 100%;
    overflow: auto;
    transform: translateX(-50%);
    line-height: initial;
    text-align: left;
    white-space: normal;
`;

export const SlideImageContainer = styled.div`
    position: relative;
    width: 100%;
`;

export const SlideImage = styled.img`
    opacity: 0.65;
`;
