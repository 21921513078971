import { AD_CONFIG } from '@config/ad/adConfig';
import { EXPERIMENT } from '@domains/ad/consts/experiment';
import { ADVERT_FLOOR_PLANS_QUERY } from '@domains/ad/graphql/queries/AdvertFloorPlansQuery';
import type { Ad } from '@domains/ad/types/Ad';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import { useEffect, useState } from 'react';
import { useQuery } from 'urql';

export const useAdvertFloorPlans = (
    ad: Ad,
): {
    floorPlanIndex: number | null;
} => {
    const { shouldShowFloorPlanLink } = AD_CONFIG;
    const [floorPlanIndex, setFloorPlanIndex] = useState<number | null>(null);
    const { isVariantEnabled } = useExperiments();
    const isEURE28555VariantB = isVariantEnabled(EXPERIMENT.eure28555, 'b');
    const areFloorPlansEnabled = isEURE28555VariantB && shouldShowFloorPlanLink;

    const [{ data, fetching, error, operation }] = useQuery({
        query: ADVERT_FLOOR_PLANS_QUERY,
        variables: {
            advertId: ad.id,
        },
        pause: !areFloorPlansEnabled,
    });

    const shouldSkipGeneratingFloorPlanIndex = fetching || !data?.advertFloorPlans?.urls?.length;

    useEffect(() => {
        if (shouldSkipGeneratingFloorPlanIndex) return;

        const galleryFloorPlanIndex = ad.images.findIndex(
            ({ large }) => large.split(';s')[0] === data?.advertFloorPlans?.urls[0],
        );
        if (galleryFloorPlanIndex !== -1) {
            setFloorPlanIndex(galleryFloorPlanIndex);
        }
    }, [shouldSkipGeneratingFloorPlanIndex, data?.advertFloorPlans?.urls, ad.images]);

    useHandleGraphqlError({ descriptor: 'RelatedOwnerAdsQuery', error, operation });

    return { floorPlanIndex };
};
