import { AD_CONFIG } from '@config/ad/adConfig';
import type { DFPConfig } from '@config/type';
import { AdBottomAdvert } from '@domains/ad/components/AdAdvert/AdBottomAdvert';
import { AdMiddleAdvert } from '@domains/ad/components/AdAdvert/AdMiddleAdvert';
import { AdTopAdvert } from '@domains/ad/components/AdAdvert/AdTopAdvert';
import { shouldUseAdAvmModule } from '@domains/ad/components/AdAvmModule/helpers/shouldUseAdAvmModule';
import type { OpenGalleryFunction } from '@domains/ad/components/AdMediaGallery/openGalleryRef';
import { EXPERIMENT } from '@domains/ad/consts/experiment';
import { GalleryContextProvider } from '@domains/ad/contexts/GalleryContext';
import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import { AdBreadcrumbs } from '@domains/ad/nexusComponents/AdBreadcrumbs/AdBreadcrumbs';
import { AdDescription } from '@domains/ad/nexusComponents/AdDescription/AdDescription';
import { AdDetails } from '@domains/ad/nexusComponents/AdDetails/AdDetails';
import { AdHeader } from '@domains/ad/nexusComponents/AdHeader/AdHeader';
import { AdMap } from '@domains/ad/nexusComponents/AdMap/AdMap';
import AdPageTopBar from '@domains/ad/nexusComponents/AdPageTopBar/AdPageTopBar';
import { AgencyBranding } from '@domains/ad/nexusComponents/AgencyBranding/AgencyBranding';
import { CONTACT_FORM_DIALOG_ID } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormDialogId';
import { FixedMobileFooter } from '@domains/ad/nexusComponents/FixedMobileFooter/FixedMobileFooter';
import Gallery from '@domains/ad/nexusComponents/Gallery/Gallery';
import {
    AsideContent,
    Container,
    FullWidthMainContentSection,
    MainContent,
} from '@domains/ad/nexusComponents/PageContent/PageContent.theme';
import { SellerAdsSection } from '@domains/ad/nexusComponents/SellerAdsSection/SellerAdsSection';
import { SimilarAdsSection } from '@domains/ad/nexusComponents/SimilarAdsSection/SimilarAdsSection';
import { SimilarOffersPrompt } from '@domains/ad/nexusComponents/SimilarOffersPrompt/SimilarOffersPrompt';
import type { Ad, AdBreadcrumb } from '@domains/ad/types/Ad';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import type { SellerBadge } from '@type/sellerBadge/sellerBadge';
import { COMPARE_ADS_DIALOG_ID, CompareAdsDialog } from '@widgets/CompareAds';
import dynamic from 'next/dynamic';
import type { JSX, MutableRefObject } from 'react';
import { useContext, useRef } from 'react';

import { MortgageCalculator } from '../MortgageCalculator/MortgageCalculator';

// when we migrate whole ad page we can align SSR props with this interface
interface Props {
    ad: Ad;
    referer: string | null;
    dfp: DFPConfig;
    sidebarAdContainer: MutableRefObject<HTMLDivElement | null>;
    breadcrumbs: AdBreadcrumb[];
    sellerBadges?: SellerBadge[];
}

const LazyAdAvmModule = dynamic(() => import('@domains/ad/shared/components/BaseAdAvmModule/BaseAdAvmModule'));
const LazyAdAgencyBanner = dynamic(() => import('@domains/ad/nexusComponents/AdAgencyBanner/AdAgencyBanner'));
const LazySidebar = dynamic(() => import('@domains/ad/nexusComponents/AdSidebar/AdSidebar'));
const LazyDialogContactForm = dynamic(
    () => import('@domains/ad/nexusComponents/ContactForm/components/DialogContactForm/DialogContactForm'),
);

const MORTGAGE_CALCULATOR_AD_TYPE = new Set(['FLAT', 'HOUSE']);

const AdPageContent = ({ ad, referer, dfp, sidebarAdContainer, breadcrumbs, sellerBadges }: Props): JSX.Element => {
    const [t] = useTranslations();

    const { lang, featureFlags } = useSiteSettings();
    const { isDesktop } = useContext(RWDContext);
    const isMobile = !isDesktop;
    const { isAdAvmModuleEnabled } = featureFlags;

    const { isVariantEnabled } = useExperiments();
    const isREMT569VariantB = isVariantEnabled(EXPERIMENT.remt569, 'b');
    const isEURE25554VariantC = isVariantEnabled(EXPERIMENT.eure25554, 'c');

    const { isOpen: isDialogOpen } = useDialogState(COMPARE_ADS_DIALOG_ID);

    const getData = getAdCharacteristic(ad.characteristics);
    const adPrice = getData('price');
    const adPricePerM = getData('price_per_m');
    const adArea = getData('m');

    const shouldDisplayAdAvmModule =
        shouldUseAdAvmModule(ad, lang, isREMT569VariantB, isAdAvmModuleEnabled) && !!adPrice;

    const openGalleryRef = useRef<OpenGalleryFunction>(null);
    const { id: agencyId, ...agencyProperties } = ad.agency || {};

    const shouldShowAgencyBranding = !!ad.agency?.brandingVisible;

    const { mortgageCalculator } = AD_CONFIG;
    const { isEnabled: isMortgageCalculatorEnabled } = mortgageCalculator;

    const shouldShowMortgageCalculator =
        isMortgageCalculatorEnabled &&
        !!adPrice?.value &&
        ad.adCategory.type !== 'RENT' &&
        MORTGAGE_CALCULATOR_AD_TYPE.has(ad.adCategory.name);

    const { isOpen: isDialogContactFormOpen } = useDialogState(CONTACT_FORM_DIALOG_ID);

    return (
        <>
            {isDialogOpen ? <CompareAdsDialog /> : null}
            {shouldShowAgencyBranding ? (
                <>
                    <FullWidthMainContentSection>
                        <AgencyBranding agency={ad.agency} adCategory={ad.adCategory} sellerBadges={sellerBadges} />
                    </FullWidthMainContentSection>
                    <Divider variant="secondary-light" />
                </>
            ) : null}
            <Container data-testid="nexus-ad-page">
                <MainContent>
                    <GalleryContextProvider ad={ad} openGalleryRef={openGalleryRef}>
                        <div>
                            <AdPageTopBar referer={referer} adTitle={ad.title} adUrl={ad.url} adId={ad.id} />
                            <Gallery ad={ad} />
                            <AdHeader ad={ad} shouldShowMortgageCalculator={shouldShowMortgageCalculator} />
                        </div>
                        {isEURE25554VariantC && <AdDescription ad={ad} />}
                        <AdDetails ad={ad} />
                    </GalleryContextProvider>
                    <AdTopAdvert isMobile={isMobile} />
                    {!isEURE25554VariantC && <AdDescription ad={ad} />}
                    <SimilarOffersPrompt ad={ad} />
                    <AdMap title={t('frontend.adpage.map.title')} location={ad.location} />
                    {shouldShowMortgageCalculator ? (
                        <MortgageCalculator
                            propertyPrice={Number(adPrice?.value)}
                            adId={ad.id}
                            reverseGeocoding={ad.location.reverseGeocoding}
                        />
                    ) : null}
                    {shouldDisplayAdAvmModule && (
                        <LazyAdAvmModule
                            id={ad.id}
                            price={adPrice}
                            pricePerM={adPricePerM}
                            adArea={adArea}
                            isNexusPage
                        />
                    )}
                    {ad.agency ? (
                        <LazyAdAgencyBanner
                            adId={ad.id}
                            agencyId={ad.agency.id}
                            adCategory={ad.adCategory}
                            sellerBadges={sellerBadges}
                            licenseNumber={ad.agency.licenseNumber}
                            {...agencyProperties}
                        />
                    ) : null}
                </MainContent>
                <AsideContent>
                    <LazySidebar ad={ad} dfp={dfp} sidebarAdContainer={sidebarAdContainer} isMobile={isMobile} />
                </AsideContent>
                {isDesktop ? null : <FixedMobileFooter ad={ad} />}
                {isDialogContactFormOpen && <LazyDialogContactForm ad={ad} />}
            </Container>
            <FullWidthMainContentSection addTopSpacing>
                <SimilarAdsSection adId={ad.id} />
                <SellerAdsSection ad={ad} />
                <AdBreadcrumbs breadcrumbs={breadcrumbs} />
                <AdMiddleAdvert isMobile={isMobile} />
                <AdBottomAdvert />
            </FullWidthMainContentSection>
        </>
    );
};

export default AdPageContent;
