import type { AdInformation, ExtendedAdInformation, RoomsValue } from '@type/ad/adDetails';
import type { AdInformationValue } from '@type/ad/adInformationValue';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';
import type { PropertyType } from '@type/ad/attributes/advertProperty';

export function getRoomsNumberValue(characteristics: AdvertCharacteristic[]): string | undefined {
    return characteristics.find(({ key }) => key === 'rooms_num')?.localizedValue;
}

const moveAirConditioningToEquipments = (topInformation: (AdInformation | ExtendedAdInformation)[]): void => {
    const extrasIndex = topInformation.findIndex((info) => info.label === 'extras_types');
    const equipmentIndex = topInformation.findIndex((info) => info.label === 'equipment_types');

    if (extrasIndex > -1) {
        const airConditioningIndex = topInformation[extrasIndex].values.findIndex(
            (value) => typeof value === 'string' && value === 'extras_types::air_conditioning',
        );
        if (airConditioningIndex > -1 && equipmentIndex > -1) {
            const airConditioning = topInformation[extrasIndex].values.splice(
                airConditioningIndex,
                1,
            )[0] as RoomsValue & AdInformationValue;
            topInformation[equipmentIndex].values.push(airConditioning);
        }
    }
};

export function getAdditionalInformation(
    topInformation: (AdInformation | ExtendedAdInformation)[],
    itemsToRemove?: string[],
): AdInformation {
    const relevantLabels = new Set(['outdoor', 'car', 'extras_types']);
    const valuesToRemove = new Set(itemsToRemove ?? ['extras_types::two_storey']);
    moveAirConditioningToEquipments(topInformation);

    const values = topInformation.reduce((accumulator: string[], info) => {
        const filteredValues = info.values.filter((value) => !valuesToRemove.has(value as string));

        if (relevantLabels.has(info.label)) {
            const infoValues = getStringValues(filteredValues);
            accumulator = [...accumulator, ...infoValues];
        }

        return accumulator;
    }, []);

    return {
        label: 'extras_types',
        values: values as AdInformationValue[],
        unit: '',
    };
}

export function getCommercialAdditionalInformation(
    topInformation: (AdInformation | ExtendedAdInformation)[],
    propertyType: PropertyType,
): AdInformation {
    if (propertyType !== 'COMMERCIAL_PROPERTY') {
        return {
            unit: '',
            label: 'extras_types',
            values: [],
        };
    }

    return getAdditionalInformation(topInformation, [
        'extras_types::air_conditioning',
        'extras_types::asphalt_access',
        'extras_types::heating',
        'extras_types::shop_window',
        'extras_types::parking',
    ]);
}

export function getStringValues(values: (string | RoomsValue)[]): string[] {
    return Array.isArray(values) && values.every((item) => typeof item === 'string') ? (values as string[]) : [];
}
