import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P3 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    display: flex;
    justify-content: space-between;
`;

export const DetailsContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space['8']};
`;

export const DetailsProperty = styled(P3)`
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes['14']};
`;
