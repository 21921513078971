import { RELATED_OWNER_ADS_QUERY } from '@domains/ad/graphql/queries/RelatedOwnerAdsQuery';
import type { RecommendedAd } from '@domains/ad/types/Ad';
import { useHandleGraphqlError } from '@lib/graphql/hooks/useHandleGraphqlError';
import { useQuery } from 'urql';

export const useRelatedOwnerAdverts = (
    advertId: number,
    limit: number,
): {
    fetching: boolean;
    relatedOwnerAdverts: RecommendedAd[];
} => {
    const [{ data, fetching, error, operation }] = useQuery({
        query: RELATED_OWNER_ADS_QUERY,
        variables: {
            advertId,
            limit,
        },
    });

    useHandleGraphqlError({ descriptor: 'RelatedOwnerAdsQuery', error, operation });

    return {
        fetching,
        relatedOwnerAdverts: data?.relatedOwnerAdverts?.items || [],
    };
};
