import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import { H4 } from '@nexus/lib-react/dist/core/Typography';
import {
    Heading,
    StyledCloseIcon,
    StyledDialogHeader,
} from '@widgets/CompareAds/CompareAdsDialog/components/DialogHeading/DialogHeading.theme';
import type { JSX } from 'react';

import { COMPARE_ADS_DIALOG_ID } from '../../../constants/dialogId';
import CompareIcon from '../../../icons/compare.svg';

export const DialogHeadingContent = (): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { closeDialog } = useDialogState(COMPARE_ADS_DIALOG_ID);

    const handleClose = (): void => {
        trackEvent('compare_ad_cancel');
        closeDialog();
    };

    return (
        <>
            <Heading>
                <CompareIcon />
                <H4 variant="bold">{t('frontend.widget.compare-ads.heading')}</H4>
            </Heading>
            <StyledCloseIcon onClick={handleClose} data-testid="close-modal-icon" />
        </>
    );
};

export const DialogHeading = (): JSX.Element => {
    return (
        <StyledDialogHeader>
            <DialogHeadingContent />
        </StyledDialogHeader>
    );
};
