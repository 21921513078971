import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Snackbar } from '@nexus/lib-react/dist/core/Snackbar';

export const ButtonContentWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space['8']};
`;

export const StyledSnackbar = styled(Snackbar)`
    z-index: ${theme.zIndices.popover};

    @media (max-width: ${theme.breakpoints.sm}) {
        bottom: 80px;
        width: 90%;

        & > div {
            width: 90%;
        }
    }
`;
