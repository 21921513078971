import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Accordion } from '@nexus/lib-react/dist/core/Accordion';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import Check from '@nexus/lib-react/dist/core/Icon/icons/default/Check';
import { H4, P3 } from '@nexus/lib-react/dist/core/Typography';
import type { CSSProperties } from 'react';

export const StyledListContainer = styled.div`
    width: 100%;
    padding: ${theme.space['24']};
    background-color: ${theme.colors.$01_white};

    @media ${theme.mediaQueries.mdMin} {
        border-radius: ${theme.radii['4']};
    }
`;

export const ItemGridContainer = styled.div`
    display: grid;
    grid-template-columns: 50% 50%;
`;

export const Item = styled(P3)<{ isNoInformationLabel?: boolean }>`
    display: flex;
    flex-direction: column;
    gap: 8px;
    margin: ${theme.space['8']} ${theme.space['0']};
    color: ${({ isNoInformationLabel }): CSSProperties['color'] =>
        isNoInformationLabel ? theme.colors['text-global-disabled'] : theme.colors['text-global-primary']};
`;

export const StyledAccordion = styled(Accordion)`
    width: 100%;
    padding: ${theme.space['0']};
`;

export const ListContainer = styled.span`
    display: flex;
    align-items: center;
    gap: ${theme.space['8']};
`;

export const StyledCheckIcon = styled(Check)`
    flex-shrink: 0;
`;

export const StyledDivider = styled(Divider)`
    margin: ${theme.space['24']} ${theme.space['0']} ${theme.space['16']};

    @media ${theme.mediaQueries.lgMin} {
        margin-bottom: ${theme.space['24']};
    }
`;

export const Title = styled(H4)`
    margin-bottom: ${theme.space['24']};
`;
