import { Icon } from '@domains/shared/components/dataDisplay/Icon/Icon';
import { faUser } from '@fortawesome/free-regular-svg-icons/faUser';
import type { FC } from 'react';

import { AvatarPicture, Container } from './Avatar.theme';

interface Props {
    className?: string;
    url?: string | null;
    avatarSize?: number;
}
export const Avatar: FC<Props> = ({ className, url, avatarSize = 48 }) => {
    return (
        <Container className={className} avatarSize={avatarSize}>
            {url ? <AvatarPicture url={url} /> : <Icon icon={faUser} size="lg" />}
        </Container>
    );
};
