import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import { useSnackbarState } from '@nexus/lib-react/dist/core/Snackbar';
import { Container } from '@widgets/CompareAds/CompareAdsDialog/components/FooterButtons/FooterButtons.theme';
import type { SelectedRate } from '@widgets/CompareAds/CompareAdsDialog/components/RateButtons/RateButtons';
import { isMessageValid } from '@widgets/CompareAds/CompareAdsDialog/helpers/isMessageValid';
import { COMPARE_ADS_DIALOG_ID } from '@widgets/CompareAds/constants/dialogId';
import { COMPARE_ADS_SNACKBAR_ID } from '@widgets/CompareAds/constants/snackbarId';
import type { JSX } from 'react';

interface Props {
    selectedRate: SelectedRate;
    onSelectedRateError: () => void;
    message: string;
    onMessageError: () => void;
}

export const FooterButtons = ({ selectedRate, onSelectedRateError, message, onMessageError }: Props): JSX.Element => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const { closeDialog } = useDialogState(COMPARE_ADS_DIALOG_ID);
    const { openSnackbar } = useSnackbarState(COMPARE_ADS_SNACKBAR_ID);

    const handleCancel = (): void => {
        trackEvent('compare_ad_cancel');
        closeDialog();
    };

    const handleSend = (): void => {
        if (!selectedRate) {
            onSelectedRateError();
        }

        if (!isMessageValid(message, selectedRate)) {
            onMessageError();
        }

        const shouldSendSurvey = selectedRate && isMessageValid(message, selectedRate);
        if (shouldSendSurvey) {
            trackEvent('compare_ad_send', {
                message_detail: message.trim(),
                action_type: selectedRate,
            });
            openSnackbar();
            closeDialog();
        }
    };

    return (
        <Container>
            <Button variant="tertiary" size="large" onClick={handleCancel}>
                {t('frontend.widget.compare-ads.footer-button-cancel')}
            </Button>
            <Button variant="primary" size="large" onClick={handleSend}>
                {t('frontend.widget.compare-ads.footer-button-send')}
            </Button>
        </Container>
    );
};
