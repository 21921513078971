import { getMicrodataItems } from '@domains/ad/helpers/generateMicroData';
import { BreadcrumbsWrapper } from '@domains/ad/nexusComponents/AdBreadcrumbs/AdBreadcrumbs.theme';
import type { AdBreadcrumb } from '@domains/ad/types/Ad';
import { Breadcrumbs } from '@domains/shared/components/Breadcrumbs/Breadcrumbs';
import type { JSX } from 'react';

interface Props {
    breadcrumbs: AdBreadcrumb[];
}

export const AdBreadcrumbs = ({ breadcrumbs }: Props): JSX.Element | null => {
    if (breadcrumbs.length === 0) {
        return null;
    }

    // Shared component Breadcrumbs needs id for every breadcrumb but on AdPage API doesn't set any id
    const items = breadcrumbs.map((item, index) => ({ ...item, id: `${index}` }));

    const microdataItems = getMicrodataItems(breadcrumbs);

    return (
        <BreadcrumbsWrapper>
            <Breadcrumbs breadcrumbItems={items} shouldUseNexusTheme={true} shouldShowHomePageIcon={false} />
            <script
                type="application/ld+json"
                data-testid="breadcrumbs-microdata"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(microdataItems) }}
            />
        </BreadcrumbsWrapper>
    );
};
