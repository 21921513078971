import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';

export const getRent = (characteristics: AdvertCharacteristic[], t: Translator): string | null => {
    const getCharacteristic = getAdCharacteristic(characteristics);
    const rentFromCharacteristic = getCharacteristic('rent')?.localizedValue;

    return rentFromCharacteristic ? `+ ${rentFromCharacteristic} ${t('frontend.ad.user-ads.rent')}` : null;
};
