import type { JSX, PropsWithChildren } from 'react';

import { ContactSectionWrapper, SlideImage, SlideImageContainer } from './ImageGalleryItem.theme';

type Props = PropsWithChildren<{
    source?: string;
}>;

// original `react-image-gallery` class to keep consistent styles with other slides
const GALLERY_IMAGE_STYLE = 'image-gallery-image';

/**
 * renders `ContactSection` as the last slide
 * mode: fullscreen only
 */
export const ImageGalleryItem = ({ source = '', children }: Props): JSX.Element => {
    return (
        <SlideImageContainer>
            <ContactSectionWrapper>{children}</ContactSectionWrapper>
            <SlideImage className={GALLERY_IMAGE_STYLE} alt="" src={source} />
        </SlideImageContainer>
    );
};
