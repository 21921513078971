import { StyledDialog, StyledDialogBody } from '@widgets/CompareAds/CompareAdsDialog/CompareAdsDialog.theme';
import { DialogContent } from '@widgets/CompareAds/CompareAdsDialog/components/DialogContent/DialogContent';
import { DialogHeading } from '@widgets/CompareAds/CompareAdsDialog/components/DialogHeading/DialogHeading';
import type { JSX } from 'react';

import { COMPARE_ADS_DIALOG_ID } from '../constants/dialogId';

export const CompareAdsDialog = (): JSX.Element => {
    return (
        <StyledDialog id={COMPARE_ADS_DIALOG_ID} enableCloseButton={false}>
            <DialogHeading />
            <StyledDialogBody>
                <DialogContent />
            </StyledDialogBody>
        </StyledDialog>
    );
};
