import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { SliderInput } from '@nexus/lib-react/dist/core/SliderInput';

export const Wrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    width: 100%;

    @media ${theme.mediaQueries.smMin} {
        padding-right: ${theme.space[24]};
    }
`;

export const Extreme = styled.span`
    position: relative;
    top: ${theme.space[10]};
    white-space: nowrap;
`;

export const StyledSliderInput = styled(SliderInput)`
    width: 100%;

    > div:nth-of-type(2),
    input {
        width: 100%;
    }
`;
