import { AddToFavorites } from '@domains/ad/nexusComponents/AddToFavorites/AddToFavorites';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { SavedAdsContext } from '@domains/shared/contexts/SavedAdsContext/SavedAdsContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { JSX } from 'react';
import { useContext, useState } from 'react';

interface Props {
    adId: number;
}

export const SaveAdButton = ({ adId }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { checkIfAdIsSaved } = useContext(SavedAdsContext);
    const { isDesktop } = useContext(RWDContext);
    const [isRedirectToLoginModalOpen, setIsRedirectToLoginModalOpen] = useState(false);

    const isSubscribed = checkIfAdIsSaved(adId);

    const getButtonLabel = (): string | undefined => {
        if (!isDesktop) {
            return;
        }

        const label = isSubscribed
            ? 'frontend.atoms.ad-subscribe-button.saved'
            : 'frontend.atoms.ad-subscribe-button.save';

        return t(label);
    };

    return (
        <AddToFavorites
            id={adId}
            buttonSize="large"
            buttonVariant="flat"
            label={getButtonLabel()}
            isRedirectToLoginModalOpen={isRedirectToLoginModalOpen}
            setIsRedirectToLoginModalOpen={setIsRedirectToLoginModalOpen}
        />
    );
};
