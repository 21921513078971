import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { DEFAULT_FIELD_OPTION } from '@widgets/MortgageCalculator/core/consts/fieldOption';
import { FIELDS_NAME } from '@widgets/MortgageCalculator/core/consts/fieldsName';
import type { OwnContributionFieldOptions } from '@widgets/MortgageCalculator/core/hooks/useMortgageCalculation';
import type { DefaultValues } from '@widgets/MortgageCalculator/core/types';
import type { Dispatch, JSX, SetStateAction } from 'react';
import type { Control, UseFormClearErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { BaseFieldProps } from '../../AdCalculator';
import {
    FieldDescription,
    FieldLabel,
    FieldLeftColumn,
    FieldRightColumn,
    FieldWrapper,
    RangeSliderWrapper,
} from '../../AdCalculator.theme';
import { NumberInput } from '../NumberInput/NumberInput';
import { RangeSlider as NexusRangeSlider } from '../RangeSlider/RangeSlider';
import { Container, PercentInputWrapper } from './OwnContribution.theme';

interface OwnContributionProps {
    control: Control<DefaultValues>;
    shouldRecalculateOwnContribution: boolean;
    setShouldRecalculateOwnContribution: Dispatch<SetStateAction<boolean>>;
    baseFieldProps: BaseFieldProps;
    ownContributionPercentWatched: number;
    ownContributionFieldOptions: OwnContributionFieldOptions;
    setValue: UseFormSetValue<DefaultValues>;
    ownContributionWatched: number;
    clearErrors: UseFormClearErrors<DefaultValues>;
    register: UseFormRegister<DefaultValues>;
}

export const OwnContribution = ({
    control,
    shouldRecalculateOwnContribution,
    setShouldRecalculateOwnContribution,
    baseFieldProps,
    ownContributionPercentWatched,
    ownContributionFieldOptions,
    setValue,
    ownContributionWatched,
    clearErrors,
    register,
}: OwnContributionProps): JSX.Element => {
    const [t] = useTranslations();

    const ownContributionPercentFieldProps = {
        ...baseFieldProps,
        name: FIELDS_NAME.ownContributionPercent,
        options: DEFAULT_FIELD_OPTION[FIELDS_NAME.ownContributionPercent],
        tracking: {
            eventName: 'calc_deposit_scroll',
            data: {
                touch_point_button: 'mortgage_calculator_percent',
            },
        },
        defaultValue: ownContributionPercentWatched,
    };

    const onContributionSliderChangeCallback = (): void => {
        if (!shouldRecalculateOwnContribution) {
            setShouldRecalculateOwnContribution(true);
        }
    };

    return (
        <>
            <div>
                <FieldLabel htmlFor={FIELDS_NAME.ownContribution}>
                    {t('frontend.widget.mortgage-calculator.ad-variant-contribution')}
                </FieldLabel>{' '}
                <FieldDescription>
                    ({t('frontend.widget.mortgage-calculator.ad-variant-contribution-description')})
                </FieldDescription>
            </div>
            <FieldWrapper>
                <FieldLeftColumn>
                    <Controller
                        control={control}
                        name={FIELDS_NAME.ownContributionPercent}
                        render={({ field: { onChange, value } }): JSX.Element => {
                            const fieldProps = {
                                ...ownContributionPercentFieldProps,
                                value: value || 0,
                            };

                            return (
                                <RangeSliderWrapper>
                                    <NexusRangeSlider
                                        {...fieldProps}
                                        onRegisterChange={onChange}
                                        onChangeCallback={onContributionSliderChangeCallback}
                                        extremeUnit={t(
                                            'frontend.widget.mortgage-calculator.ad-variant-contribution-extreme',
                                        )}
                                    />
                                </RangeSliderWrapper>
                            );
                        }}
                    />
                </FieldLeftColumn>
                <FieldRightColumn>
                    <Container>
                        <Controller
                            control={control}
                            name={FIELDS_NAME.ownContributionPercent}
                            render={({ field: { value } }): JSX.Element => {
                                const fieldProps = {
                                    ...ownContributionPercentFieldProps,
                                    value: value || 0,
                                };

                                return (
                                    <PercentInputWrapper>
                                        <NumberInput
                                            {...fieldProps}
                                            onChangeCallback={(): void => setShouldRecalculateOwnContribution(true)}
                                        />
                                    </PercentInputWrapper>
                                );
                            }}
                        />
                        <Controller
                            control={control}
                            name={FIELDS_NAME.ownContribution}
                            render={({ field: { value } }): JSX.Element => {
                                const mortgageItemTracking = {
                                    eventName: 'calc_deposit',
                                    data: {
                                        touch_point_button: 'mortgage_calculator',
                                    },
                                };

                                return (
                                    <NumberInput
                                        name={FIELDS_NAME.ownContribution}
                                        value={value || 0}
                                        options={ownContributionFieldOptions}
                                        setFormValue={setValue}
                                        tracking={mortgageItemTracking}
                                        defaultValue={ownContributionWatched}
                                        type="FLOAT"
                                        clearFormErrors={clearErrors}
                                        register={register}
                                        onChangeCallback={(): void => {
                                            setShouldRecalculateOwnContribution(false);
                                        }}
                                    />
                                );
                            }}
                        />
                    </Container>
                </FieldRightColumn>
            </FieldWrapper>
        </>
    );
};
