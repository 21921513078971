import { EXPERIMENT } from '@domains/ad/consts/experiment';
import {
    getAdDetailsMainSection,
    getAdDetailsSecondarySection,
    getAdDetailsThirdSection,
} from '@domains/ad/helpers/getAdDetails';
import { useAdvertFloorPlans } from '@domains/ad/hooks/useAdvertFloorPlans';
import {
    StyledAccordion,
    StyledDivider,
    StyledListContainer,
    Title,
} from '@domains/ad/nexusComponents/AdDetails/AdDetails.theme';
import { AdDetailsItems } from '@domains/ad/nexusComponents/AdDetails/AdDetailsItems';
import { extractHighlightsFromExtras } from '@domains/ad/nexusComponents/AdDetails/helpers/extractHighlightsFromExtras';
import {
    getAdditionalInformation,
    getCommercialAdditionalInformation,
} from '@domains/ad/nexusComponents/AdDetails/helpers/getAdInfo';
import { getEnergyCertificate } from '@domains/ad/nexusComponents/AdDetails/helpers/getEnergyCertificate';
import { getFilteredKeys } from '@domains/ad/nexusComponents/AdDetails/helpers/getFilteredKeys';
import { getFreeFrom } from '@domains/ad/nexusComponents/AdDetails/helpers/getFreeFrom';
import { hasItems } from '@domains/ad/nexusComponents/AdDetails/helpers/hasItems';
import { mapSafetyFeatures } from '@domains/ad/nexusComponents/AdDetails/helpers/mapSafetyFeatures';
import { AdDetailsHighlights } from '@domains/ad/nexusComponents/AdDetailsHighlights/AdDetailsHighlights';
import { AdMetadata } from '@domains/ad/nexusComponents/AdMetadata/AdMetadata';
import { AdMultimedia } from '@domains/ad/nexusComponents/AdMultimedia/AdMultimedia';
import type { Ad } from '@domains/ad/types/Ad';
import type { AdFullInformation } from '@domains/ad/types/adFullInformation';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useTracking } from '@lib/tracking/useTracking';
import type { AccordionItemType } from '@nexus/lib-react/dist';
import { H4, P2 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';
import { useCallback, useMemo, useRef } from 'react';

interface Props {
    ad: Ad;
}

const Header = ({ title }: { title: string }): JSX.Element => {
    const [t] = useTranslations();

    return <P2 variant="bold">{t(title)}</P2>;
};

const ADDITIONAL_INFORMATION_EXCEPTIONS = new Set(['extras_types', 'security_types', 'market', 'free_from']);

export const AdDetails = ({ ad }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { floorPlanIndex } = useAdvertFloorPlans(ad);

    const adDetailsRef = useRef<HTMLDivElement | null>(null);
    useTrackImpression(adDetailsRef, () => {
        trackEvent('ad_page_impression', {
            touch_point_button: 'details',
        });
    });
    const { isVariantEnabled } = useExperiments();
    const isEURE25554VariantB = isVariantEnabled(EXPERIMENT.eure25554, 'b');
    const isEURE25554VariantC = isVariantEnabled(EXPERIMENT.eure25554, 'c');
    const isEURE2554Enabled = isEURE25554VariantB || isEURE25554VariantC;

    const filteredAdditionalInformation = ad.additionalInformation.filter(
        (info) => !ADDITIONAL_INFORMATION_EXCEPTIONS.has(info.label),
    );
    const additionalInformation = getAdditionalInformation([...ad.topInformation, ...ad.additionalInformation]);
    const commercialAdditionalInformation = getCommercialAdditionalInformation(
        ad.additionalInformation,
        ad.adCategory.name,
    );
    const energyCertificate = getEnergyCertificate(ad.characteristics, t);
    const safetyFeatures = mapSafetyFeatures(ad.additionalInformation);
    const market = ad.characteristics.find((characteristic) => characteristic.key === 'market');
    const freeFrom = getFreeFrom(ad.characteristics);
    const marketAndOwnerInformation = useMemo(
        () => [
            ...(market
                ? [
                      {
                          label: 'market',
                          values: [`market::${market.value}`],
                          unit: '',
                      },
                  ]
                : []),
            // Ads imported from Obido always have additionalInformation = [], so we need to get the seller type from owner.type
            // For other ads we should get from additionalInformation::advertiser_type
            ...(ad.additionalInformation.length === 0
                ? [
                      {
                          label: 'advertiser_type',
                          values: [`advertiser_type::${ad.owner.type}`],
                          unit: '',
                      },
                  ]
                : []),
        ],
        [ad, market],
    );

    const { trackEvent } = useTracking();
    const handleExpandAccordion = useCallback(
        (id: string): void => {
            trackEvent('expand_click', {
                touch_point_button: id,
            });
        },
        [trackEvent],
    );

    const generateMainSection = useCallback((): JSX.Element | null => {
        const mainSectionKeys = getAdDetailsMainSection(ad.adCategory);
        if (!mainSectionKeys) {
            return null;
        }

        const adHighlights = isEURE2554Enabled
            ? []
            : extractHighlightsFromExtras(
                  mainSectionKeys.highlights.map((highlight) => highlight.key),
                  ad.additionalInformation.find((item) => item.label === 'extras_types'),
                  t,
              );
        const fullAdDetails: AdFullInformation = [
            ...ad.topInformation,
            ...filteredAdditionalInformation,
            ...safetyFeatures,
            additionalInformation,
            energyCertificate,
            freeFrom,
            ...adHighlights,
            ...marketAndOwnerInformation,
        ];

        return (
            <>
                {!isEURE2554Enabled && (
                    <AdDetailsHighlights
                        propertyHighlights={mainSectionKeys.highlights}
                        fullAdDetails={fullAdDetails}
                    />
                )}
                <AdDetailsItems
                    adInformation={fullAdDetails}
                    detailsKeys={mainSectionKeys.detailsKeys}
                    testId="ad-page.ad.details.main-section"
                />
            </>
        );
    }, [
        ad,
        additionalInformation,
        energyCertificate,
        freeFrom,
        safetyFeatures,
        t,
        marketAndOwnerInformation,
        filteredAdditionalInformation,
        isEURE2554Enabled,
    ]);

    const generateAccordion = useCallback((): JSX.Element | null => {
        const fullAdDetails: AdFullInformation = [
            ...ad.topInformation,
            ...filteredAdditionalInformation,
            ...safetyFeatures,
            energyCertificate,
            commercialAdditionalInformation,
            ...marketAndOwnerInformation,
        ];
        const secondarySectionKeys = getAdDetailsSecondarySection(ad.adCategory.name);
        const accordionContent: AccordionItemType[] = [];

        if (secondarySectionKeys && hasItems(secondarySectionKeys, fullAdDetails)) {
            const keys = getFilteredKeys(fullAdDetails, secondarySectionKeys.keys);
            const Items = (): JSX.Element => (
                <AdDetailsItems
                    adInformation={fullAdDetails}
                    detailsKeys={keys}
                    testId="ad-page.ad.details.second-section"
                />
            );
            accordionContent.push({
                id: 'building',
                isOpen: false,
                header: () => <Header title={secondarySectionKeys.title} />,
                content: Items,
            });
        }

        const thirdSectionKeys = getAdDetailsThirdSection(ad.adCategory.name);
        if (thirdSectionKeys && hasItems(thirdSectionKeys, fullAdDetails)) {
            const keys = getFilteredKeys(fullAdDetails, thirdSectionKeys.keys);
            const Items = (): JSX.Element => (
                <AdDetailsItems
                    adInformation={fullAdDetails}
                    detailsKeys={keys}
                    testId="ad-page.ad.details.third-section"
                />
            );

            accordionContent.push({
                id: 'other',
                isOpen: false,
                header: () => <Header title={thirdSectionKeys.title} />,
                content: Items,
            });
        }

        return (
            <StyledAccordion content={accordionContent} openBehavior="multiple" onItemOpen={handleExpandAccordion} />
        );
    }, [
        ad,
        energyCertificate,
        safetyFeatures,
        marketAndOwnerInformation,
        handleExpandAccordion,
        filteredAdditionalInformation,
        commercialAdditionalInformation,
    ]);

    return (
        <StyledListContainer ref={adDetailsRef}>
            {isEURE25554VariantC ? (
                <Title>{t('frontend.ad.details-section.title')}</Title>
            ) : (
                <H4>
                    {t(
                        `frontend.ad-page.details-section.title.${ad.adCategory.name.toLocaleLowerCase()}-${ad.adCategory.type.toLocaleLowerCase()}`,
                    )}
                </H4>
            )}
            {generateMainSection()}
            {generateAccordion()}
            <AdMultimedia {...ad.links} floorPlanIndex={floorPlanIndex} />
            {isEURE25554VariantC && (
                <>
                    <StyledDivider variant="secondary-light" />
                    <AdMetadata ad={ad} />
                </>
            )}
        </StyledListContainer>
    );
};
