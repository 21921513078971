import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const BreadcrumbsWrapper = styled.div`
    padding: ${theme.space['16']} ${theme.space['24']};

    @media ${theme.mediaQueries.xlMin} {
        padding: ${theme.space['0']};
    }
`;
