import type { AdInformation } from '@type/ad/adDetails';
import type { AdInformationValue } from '@type/ad/adInformationValue';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';

const FREE_FROM_KEY = 'free_from';
export const getFreeFrom = (adCharacteristics: AdvertCharacteristic[]): AdInformation => {
    const freeFrom = adCharacteristics.find(({ key }) => key === FREE_FROM_KEY)?.localizedValue;

    if (!freeFrom) {
        return {
            unit: '',
            label: FREE_FROM_KEY,
            values: [],
        };
    }

    return {
        unit: '',
        label: FREE_FROM_KEY,
        values: [freeFrom as AdInformationValue],
    };
};
