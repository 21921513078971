import { EXPERIMENT } from '@domains/ad/consts/experiment';
import { ActionButtonsContainer, Container } from '@domains/ad/nexusComponents/AdPageTopBar/AdPageTopBar.theme';
import BackToListingButton from '@domains/ad/nexusComponents/AdPageTopBar/components/BackToListingButton/BackToListingButton';
import { SaveAdButton } from '@domains/ad/nexusComponents/AdPageTopBar/components/SaveAdButton/SaveAdButton';
import ShareAdButton from '@domains/ad/nexusComponents/AdPageTopBar/components/ShareAdButton/ShareAdButton';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { CompareAdsButton } from '@widgets/CompareAds';
import type { JSX } from 'react';

interface Props {
    referer: string | null;
    adTitle: string;
    adUrl: string;
    adId: number;
}

const AdPageTopBar = ({ referer, adId, adTitle, adUrl }: Props): JSX.Element => {
    const { isVariantEnabled } = useExperiments();
    const isEURE25548VariantB = isVariantEnabled(EXPERIMENT.eure25548, 'b');

    return (
        <Container>
            <BackToListingButton referer={referer} />

            <ActionButtonsContainer>
                {isEURE25548VariantB ? <CompareAdsButton /> : null}
                <ShareAdButton title={adTitle} url={adUrl} adId={String(adId)} showLabel />
                <SaveAdButton adId={adId} />
            </ActionButtonsContainer>
        </Container>
    );
};

export default AdPageTopBar;
