import { SITE_CONFIG } from '@config/siteConfig';
import { createRoomsNumberLabel } from '@domains/ad/helpers/createRoomsNumberLabel';
import type { AdFullInformation } from '@domains/ad/types/adFullInformation';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { FunctionComponent, JSX } from 'react';

import { HighlightsContainer, StyledChip } from './AdDetailsHighlights.theme';

interface AdDetailsHighlights {
    propertyHighlights: { icon: FunctionComponent; key: string }[];
    fullAdDetails: AdFullInformation;
}

export const AdDetailsHighlights = ({ propertyHighlights, fullAdDetails }: AdDetailsHighlights): JSX.Element => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const { shouldUseTypologyInsteadOfNumberOfRooms } = SITE_CONFIG;

    const highlights = Object.values(propertyHighlights).map(({ key, icon }): JSX.Element[] | null => {
        const adDetail = fullAdDetails.find((characteristic) => characteristic.label === key);

        if (!adDetail) {
            return null;
        }

        const renderChip = (value: string): JSX.Element => {
            return (
                <StyledChip
                    key={value}
                    content={`${value}${adDetail.unit}`}
                    variant="indicative-dark"
                    prefixIcon={icon}
                    data-testid={`ad-details-highlight-${key}`}
                />
            );
        };

        return adDetail.values.map((detailValue) => {
            const value = typeof detailValue === 'string' ? detailValue : detailValue.value;
            if (key === 'roomsize') {
                switch (value) {
                    case 'roomsize::one': {
                        return renderChip(t('frontend.ad.details.list-label-roomsize-one'));
                    }
                    case 'roomsize::two': {
                        return renderChip(t('frontend.ad.details.list-label-roomsize-two'));
                    }
                    case 'roomsize::three': {
                        return renderChip(t('frontend.ad.details.list-label-roomsize-three-and-more'));
                    }
                    default: {
                        break;
                    }
                }
            }

            if (value === '::y') {
                switch (key) {
                    case 'office_space': {
                        return renderChip(t('frontend.ad.details.list-label-office-spaces'));
                    }
                    case 'social_facilities': {
                        return renderChip(t('frontend.ad.details.list-label-break-room'));
                    }
                    case 'recreational': {
                        return renderChip(t('frontend.ad.details.list-label-recreational'));
                    }
                    case 'ramp': {
                        return renderChip(t('frontend.ad.details.list-label-ramp'));
                    }
                    case 'rent_to_students': {
                        return renderChip(t('frontend.ad.details.list-label-rent-to-students'));
                    }
                    case 'non_smokers_only': {
                        return renderChip(t('frontend.ad.details.list-label-non-smokers-only'));
                    }
                    default: {
                        break;
                    }
                }
            }
            // Use the localize room number.
            // For example: In Portugal, the convention is to use 'T' followed by the bedroom count (T0, T1, T2, etc).
            if (key === 'rooms_num') {
                return renderChip(createRoomsNumberLabel(t, lang, value, shouldUseTypologyInsteadOfNumberOfRooms));
            }

            if (value === 'bathrooms_num::4_or_more') {
                return renderChip(t('frontend.ad.details.bathrooms-num-4-or-more'));
            }

            return renderChip(value);
        });
    });

    return <HighlightsContainer data-testid="ad-details.highlights">{highlights}</HighlightsContainer>;
};
