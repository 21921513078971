import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Textarea } from '@nexus/lib-react/dist/core/Textarea';
import { P4 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: ${theme.space['24']} ${theme.space['0']} ${theme.space['16']};
    gap: ${theme.space['16']};
`;

export const StyledTextArea = styled(Textarea)`
    width: 100%;
    margin: ${theme.space['8']} ${theme.space['0']} ${theme.space[4]} ${theme.space['0']};
`;

export const ErrorInfo = styled(P4)`
    margin-top: -${theme.space['36']};
    color: ${theme.colors['text-global-error']};
`;
