import { AD_CONFIG } from '@config/ad/adConfig';
import type { InterestRate } from '@config/finance/financeConfig';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTracking } from '@lib/tracking/useTracking';
import type { ReverseGeocoding } from '@type/location/reverseGeocoding';
import type { MortgageDetails } from '@widgets/MortgageCalculator/core/components/AskAboutOfferModal/InputView';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { useRef, useState } from 'react';
import type { UseFormClearErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';

import { useMortgageCalculation } from '../core/hooks/useMortgageCalculation';
import type { DefaultValues } from '../core/types';
import { Container } from './AdCalculator.theme';
import { BikSection } from './components/BikSection/BikSection';
import { Header } from './components/Header/Header';
import { OwnContribution } from './components/OwnContribution/OwnContribution';
import { PropertyPrice } from './components/PropertyPrice/PropertyPrice';
import { RepaymentSection } from './components/RepaymentSection/RepaymentSection';
import { RepaymentTerm } from './components/RepaymentTerm/RepaymentTerm';
import { useAdCalculatorConfig } from './hooks/useAdCalculatorConfig';

const LazyNexusAskAboutOfferModal = dynamic(
    () => import('@widgets/MortgageCalculator/core/components/NexusAskAboutOfferModal/NexusAskAboutOfferModal'),
    {
        ssr: false,
    },
);

const LazyAskAboutOfferModal = dynamic(
    () => import('@widgets/MortgageCalculator/core/components/AskAboutOfferModal/AskAboutOfferModal'),
    {
        ssr: false,
    },
);

export interface AdCalculatorProps {
    price: number;
    interestRate: InterestRate;
    adId: number;
    reverseGeocoding?: ReverseGeocoding;
}

export interface BaseFieldProps {
    register: UseFormRegister<DefaultValues>;
    clearFormErrors: UseFormClearErrors<DefaultValues>;
    setFormValue: UseFormSetValue<DefaultValues>;
    type: 'INTEGER';
}

export const AdCalculator = ({ price, interestRate, adId, reverseGeocoding }: AdCalculatorProps): JSX.Element => {
    const [isAskAboutOfferModalOpen, setIsAskAboutOfferModalOpen] = useState(false);

    const { selectedCurrency, selectedCurrencyCode, propertyPrice, handleCurrencyCodeChange } =
        useAdCalculatorConfig(price);

    const {
        control,
        register,
        clearErrors,
        setValue,
        propertyPriceWatched,
        ownContributionPercentWatched,
        shouldRecalculateOwnContribution,
        setShouldRecalculateOwnContribution,
        ownContributionFieldOptions,
        ownContributionWatched,
        loanTermWatched,
        formattedRepayment,
        repayment,
    } = useMortgageCalculation({ propertyPrice, interestRate, selectedCurrency });

    const baseFieldProps = {
        register,
        clearFormErrors: clearErrors,
        setFormValue: setValue,
        type: 'INTEGER' as const,
    };

    const { adMortgageSimulatorConfig, mortgageCalculator } = AD_CONFIG;
    const shouldDisplayBikSection = !!adMortgageSimulatorConfig && mortgageCalculator.isBikSectionEnabled;

    const { trackEvent } = useTracking();

    const financeCalculatorRef = useRef<HTMLDivElement | null>(null);
    useTrackImpression(financeCalculatorRef, () => {
        trackEvent('finance_calculator_impression', {
            touch_point_button: 'banner_under_map',
        });
    });

    const mortgageDetails: MortgageDetails = {
        propertyValue: propertyPriceWatched,
        upfrontPaymentPercent: ownContributionPercentWatched,
        mortgageLengthYears: loanTermWatched,
        monthlyInstallment: repayment,
    };

    return (
        <>
            <Container data-cy="ad-calculator.container" id="financial-calculator" ref={financeCalculatorRef}>
                <Header selectedCurrencyCode={selectedCurrencyCode} onCurrencyCodeChange={handleCurrencyCodeChange} />
                <form>
                    <PropertyPrice
                        control={control}
                        baseFieldProps={baseFieldProps}
                        propertyPriceWatched={propertyPriceWatched}
                        selectedCurrency={selectedCurrency}
                    />
                    <OwnContribution
                        control={control}
                        shouldRecalculateOwnContribution={shouldRecalculateOwnContribution}
                        setShouldRecalculateOwnContribution={setShouldRecalculateOwnContribution}
                        baseFieldProps={baseFieldProps}
                        ownContributionPercentWatched={ownContributionPercentWatched}
                        ownContributionFieldOptions={ownContributionFieldOptions}
                        setValue={setValue}
                        ownContributionWatched={ownContributionWatched}
                        clearErrors={clearErrors}
                        register={register}
                    />
                    <RepaymentTerm
                        control={control}
                        baseFieldProps={baseFieldProps}
                        loanTermWatched={loanTermWatched}
                    />
                </form>
                <RepaymentSection
                    repayment={formattedRepayment}
                    setIsAskAboutOfferModalOpen={setIsAskAboutOfferModalOpen}
                />
                {shouldDisplayBikSection ? <BikSection {...adMortgageSimulatorConfig} /> : null}
            </Container>
            {isAskAboutOfferModalOpen && mortgageCalculator.shouldUseNexusAskAboutOfferModal ? (
                <LazyNexusAskAboutOfferModal
                    adId={adId}
                    mortgageDetails={mortgageDetails}
                    reverseGeocoding={reverseGeocoding}
                />
            ) : null}
            {isAskAboutOfferModalOpen && !mortgageCalculator.shouldUseNexusAskAboutOfferModal ? (
                <LazyAskAboutOfferModal
                    setIsAskAboutOfferModalOpen={setIsAskAboutOfferModalOpen}
                    adId={adId}
                    mortgageDetails={mortgageDetails}
                />
            ) : null}
        </>
    );
};
