import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTracking } from '@lib/tracking/useTracking';
import { disableBodyScroll, enableBodyScroll } from 'body-scroll-lock';
import type { ChangeEvent, Dispatch, MouseEvent, MutableRefObject, SetStateAction, SyntheticEvent } from 'react';
import { useContext } from 'react';
import type { ReactImageGalleryItem } from 'react-image-gallery';
import type ReactImageGallery from 'react-image-gallery';

interface Props {
    galleryRef: MutableRefObject<ReactImageGallery | null>;
    currentIndex: number;
    isGallery: boolean;
    isFullScreen: boolean;
    isAdvancedGallery: boolean;
    isLastSlideIndex: boolean;
    shouldDisplayWalkaroundOverlay: boolean;
    slidesTotalCount: number;
    onWalkaroundOverlayDismiss?: () => void;
    setIsContactFormSlide: Dispatch<SetStateAction<boolean>>;
    setIsTransitionInProgress: Dispatch<SetStateAction<boolean>>;
    setCurrentIndex: Dispatch<SetStateAction<number>>;
    setGalleryImageSets: Dispatch<SetStateAction<ReactImageGalleryItem[]>>;
    setIsFullScreen: Dispatch<SetStateAction<boolean>>;
    setIsGallery: Dispatch<SetStateAction<boolean>>;
    setIsKeyboardNavigationEnabled: Dispatch<SetStateAction<boolean>>;
    setTransition: Dispatch<SetStateAction<number>>;
    isNexusGallery?: boolean;
}

interface GalleryEventMethods {
    onThumbnailClick: (event: MouseEvent<HTMLAnchorElement>, index: number) => void;
    onSlide: (index: number) => void;
    onThumbnailError: (event: SyntheticEvent<HTMLImageElement, Event>) => void;
    onScreenChange: (isFullScreen: boolean) => void;
}

export const useGalleryEventMethods = ({
    galleryRef,
    currentIndex,
    isGallery,
    isFullScreen,
    isAdvancedGallery,
    isLastSlideIndex,
    shouldDisplayWalkaroundOverlay,
    slidesTotalCount,
    setIsContactFormSlide,
    setIsTransitionInProgress,
    setCurrentIndex,
    setGalleryImageSets,
    onWalkaroundOverlayDismiss,
    setTransition,
    setIsFullScreen,
    setIsGallery,
    setIsKeyboardNavigationEnabled,
    isNexusGallery = false,
}: Props): GalleryEventMethods => {
    const { trackEvent } = useTracking();
    const { isDesktop } = useContext(RWDContext);

    const onThumbnailClick = (_: MouseEvent<HTMLAnchorElement>, index: number): void => {
        // avoid blocking gallery navigation arrows
        // e.g. `disabled={disabled || isTransitionInProgress}`
        // if the active thumbnail is clicked
        if (index !== currentIndex) {
            setIsTransitionInProgress(true);
        }

        setCurrentIndex(index);

        if (shouldDisplayWalkaroundOverlay) {
            onWalkaroundOverlayDismiss?.();
        }

        if (index === slidesTotalCount - 1) {
            trackEvent('gallery_swipe_end');

            return;
        }

        trackEvent('gallery_swipe');
    };

    const onSlide = (index: number): void => {
        setIsTransitionInProgress(false);

        if (isFullScreen || isNexusGallery) {
            setCurrentIndex(index);
        }

        if (index === slidesTotalCount - 1) {
            trackEvent('gallery_open_swipe_end');

            if (isAdvancedGallery && isFullScreen) {
                return setIsContactFormSlide(true);
            }
        }

        setIsContactFormSlide(false);

        trackEvent('gallery_open_swipe');
    };

    const onThumbnailError = (event: SyntheticEvent<HTMLImageElement, Event>): void => {
        const typedEvent = event as unknown as ChangeEvent<HTMLImageElement>; // library has wrong type for event variable
        const brokenThumbnailUrl = typedEvent.target.currentSrc;

        setGalleryImageSets((imageSets) => imageSets.filter((set) => set.thumbnail !== brokenThumbnailUrl));
        typedEvent.persist();
    };

    const onScreenChange = (isFullScreen: boolean): void => {
        if (!isGallery && !isFullScreen) {
            return;
        }
        // next two setters are needed for high quality photos in fullscreen
        setIsKeyboardNavigationEnabled(true);

        const currentIndex = galleryRef.current?.getCurrentIndex() || 0;

        if (!isFullScreen) {
            if (isAdvancedGallery && isLastSlideIndex) {
                // display first gallery item when exiting Fullscreen from Contact Form
                setCurrentIndex(0);
            } else {
                setCurrentIndex(currentIndex);
            }
        }

        const body = document.body;

        if (isFullScreen) {
            disableBodyScroll(body);
            setIsFullScreen(true);
            setCurrentIndex(currentIndex);
        } else {
            setIsFullScreen(false);
            setIsGallery(false);

            if (isAdvancedGallery && isDesktop) {
                setTransition(0);
                setIsContactFormSlide(false);
            }

            enableBodyScroll(body);

            trackEvent('gallery_close');
        }
    };

    return { onThumbnailClick, onSlide, onThumbnailError, onScreenChange };
};
