import { AD_CONFIG } from '@config/ad/adConfig';
import { BANK_RATE_QUERY, EXPECTED_BANK_RATE_QUERY_TYPENAME } from '@domains/shared/graphql/queries/BankRateQuery';
import { useAssertGraphqlResponse } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import type { ReverseGeocoding } from '@type/location/reverseGeocoding';
import { AdCalculator } from '@widgets/MortgageCalculator/AdCalculator';
import type { JSX } from 'react';
import { useQuery } from 'urql';

interface Props {
    propertyPrice: number;
    adId: number;
    reverseGeocoding?: ReverseGeocoding;
}

const INTEREST_RATE = {
    lowest: 7.8,
    average: 8.5,
};

export const MortgageCalculator = ({ propertyPrice, adId, reverseGeocoding }: Props): JSX.Element => {
    const { adMortgageSimulatorConfig } = AD_CONFIG;

    const [{ data, error, operation, fetching }] = useQuery({
        query: BANK_RATE_QUERY,
        pause: !adMortgageSimulatorConfig?.shouldFetchBankRate,
    });

    const bankRateData = useAssertGraphqlResponse({
        data: data?.bankRate,
        expectedTypenames: EXPECTED_BANK_RATE_QUERY_TYPENAME,
        graphqlError: error,
        logErrorPrefix: '[MortgageCalculator]',
        operation,
        fetching,
        pause: !adMortgageSimulatorConfig?.shouldFetchBankRate,
    });

    return (
        <AdCalculator
            price={propertyPrice}
            interestRate={bankRateData || INTEREST_RATE}
            adId={adId}
            reverseGeocoding={reverseGeocoding}
        />
    );
};
