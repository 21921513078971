import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { Tooltip } from '@nexus/lib-react/dist/core/Tooltip';

export const FullscreenActionButtonsContainer = styled.div`
    display: flex;
    position: fixed;
    z-index: ${theme.zIndices.modal};
    bottom: 114px;
    left: 24px;
    gap: ${theme.space['16']};
`;

export const StyledButton = styled(Button)`
    padding: ${theme.space[10]};
    border: none;
    background-color: ${theme.colors['background-brand-primary']};
    svg {
        color: ${theme.colors['background-global-primary']};
    }
    .button-text-wrapper {
        width: ${theme.sizes[24]};
        height: ${theme.sizes[24]};
    }
`;
export const StyledTooltip = styled(Tooltip)`
    --bgColor: ${theme.colors.$01_white};

    color: ${theme.colors['background-brand-primary']};
`;
