import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    display: flex;
    position: fixed;
    bottom: 0;
    left: 0;
    justify-content: center;
    width: 100%;
    padding: ${theme.space['40']} ${theme.space['0']} ${theme.space['24']};
    gap: ${theme.space['40']};

    @media ${theme.mediaQueries.smMin} {
        position: relative;
    }
`;
