import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Box } from '@nexus/lib-react/dist/core/Box';

export const StyledBox = styled(Box)`
    width: 100%;
    margin-top: ${theme.space[16]};
    margin-bottom: 0;

    @media ${theme.mediaQueries.mdMin} {
        margin-top: ${theme.space[12]};
    }
`;
