import { FULL_WIDTH_MAIN_CONTAINER_DESKTOP_WIDTH } from '@domains/ad/consts/adPageContentWidths';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.section`
    height: 344px;
    padding-left: ${theme.space['16']};

    @media ${theme.mediaQueries.xlMin} {
        padding-left: ${theme.space['0']};
    }
`;

export const Heading = styled.div`
    display: flex;
    gap: ${theme.space['8']};
    margin-bottom: ${theme.space['32']};
`;

export const ItemsContainer = styled.div`
    display: flex;
    max-width: 100vw;
    padding-right: ${theme.space['16']};
    overflow: scroll hidden;
    white-space: nowrap;
    gap: ${theme.space['16']};
    scrollbar-width: none;

    @media ${theme.mediaQueries.xlMin} {
        max-width: ${FULL_WIDTH_MAIN_CONTAINER_DESKTOP_WIDTH};
        padding-right: ${theme.space['0']};
    }
`;
