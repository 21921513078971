import type { ReactHookFormFields, ReactHookFormRef } from '@domains/shared/components/NumberInput/reactHookFormTypes';
import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';
import { useCallback } from 'react';
import type { Path, PathValue, UnpackNestedValue } from 'react-hook-form';

import { Container, InputField, Suffix } from './NumberInput.theme';

interface Options {
    min: number;
    max: number;
    step: number;
    suffix?: string;
}

interface RangeSliderTracking {
    eventName: string;
    data: {
        [k: string]: number | string;
    };
}
interface NumberInput<T extends ReactHookFormFields = ReactHookFormFields> extends ReactHookFormRef<T> {
    value: number;
    options: Options;
    defaultValue: number;
    type?: 'INTEGER' | 'FLOAT';
    onChangeCallback?: () => void;
    tracking: RangeSliderTracking;
}

export const NumberInput = <T extends ReactHookFormFields = ReactHookFormFields>({
    name,
    value,
    defaultValue,
    options,
    setFormValue,
    register,
    clearFormErrors,
    type = 'INTEGER',
    onChangeCallback,
    tracking,
}: NumberInput<T>): JSX.Element => {
    const { trackEvent } = useTracking();
    const { min, max, suffix } = options;
    const { eventName: trackingEventName, data: trackingData } = tracking;

    const onNumberInputBlur = useCallback((): void => {
        if (value < min) {
            setFormValue(name, min as UnpackNestedValue<PathValue<T, Path<T>>>);
            trackEvent(`${trackingEventName}_error`, trackingData);

            return;
        }

        if (value > max) {
            setFormValue(name, max as UnpackNestedValue<PathValue<T, Path<T>>>);
            trackEvent(`${trackingEventName}_error`, trackingData);

            return;
        }

        if (value === 0) {
            setFormValue(name, 0 as UnpackNestedValue<PathValue<T, Path<T>>>);

            return;
        }

        trackEvent(`${trackingEventName}_valid`, trackingData);
    }, [max, min, value, setFormValue, name, trackEvent, trackingEventName, trackingData]);

    const onNumberInputChange = useCallback((): void => {
        onChangeCallback?.();
    }, [onChangeCallback]);

    const onNumberInputFocus = useCallback((): void => {
        trackEvent(`${trackingEventName}_start`, {
            touch_point_button: trackingData.touch_point_button,
        });
    }, [trackEvent, trackingEventName, trackingData]);

    return (
        <Container>
            <InputField
                clearFormErrors={clearFormErrors}
                inputId={name}
                name={name}
                setFormValue={setFormValue}
                register={register}
                type={type}
                registerOptions={{ min, max }}
                defaultValue={defaultValue}
                onBlur={onNumberInputBlur}
                onChangeCallback={onNumberInputChange}
                onFocus={onNumberInputFocus}
            />
            {suffix ? <Suffix>{suffix}</Suffix> : null}
        </Container>
    );
};
