import { getFormattedAgencyName } from '@domains/ad/helpers/getFormattedAgencyName';
import { useRelatedOwnerAdverts } from '@domains/ad/hooks/useRelatedOwnerAdverts';
import { RecommendedAdItem } from '@domains/ad/nexusComponents/RecommendedAdItem/RecommendedAdItem';
import type { Ad } from '@domains/ad/types/Ad';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { H4 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';
import { useRef } from 'react';

import { Container, Heading, ItemsContainer } from './SellerAdsSection.theme';

interface Props {
    ad: Ad;
}

export const SellerAdsSection = ({ ad }: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { agency, id, owner } = ad;
    const { relatedOwnerAdverts, fetching } = useRelatedOwnerAdverts(id, 4);
    const { trackEvent } = useTracking();
    const sellerName = agency ? agency.name : owner.name;

    const sellerAdsRef = useRef<HTMLDivElement | null>(null);
    useTrackImpression(sellerAdsRef, () => {
        trackEvent('ad_impressions', {
            touch_point_button: 'more_from_seller',
        });
    });

    if (fetching) {
        return <Container />;
    }

    if (!sellerName) {
        return null;
    }

    if (!fetching && relatedOwnerAdverts.length === 0) {
        return null;
    }

    const agencyName = getFormattedAgencyName(sellerName);

    return (
        <Container ref={sellerAdsRef} aria-labelledby="section-title">
            <Heading>
                <H4 variant="bold" id="section-title">
                    {t('frontend.ad.user-ads.more-ads-from')} {agencyName}
                </H4>
            </Heading>
            <ItemsContainer>
                {relatedOwnerAdverts.map((ad) => (
                    <RecommendedAdItem key={ad.adId} ad={ad} adsType="user-ads" />
                ))}
            </ItemsContainer>
        </Container>
    );
};
