import { css } from '@emotion/react';
import type { SerializedStyles } from '@emotion/serialize';
import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { P4 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.section`
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-top: ${theme.space['24']};
    gap: ${theme.space['16']};
`;

export const ButtonsWrapper = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${theme.space['24']};
`;

export const StyledButton = styled(Button)<{ isSelected: boolean }>`
    height: ${theme.sizes[30]};

    ${({ isSelected }): SerializedStyles | undefined => {
        if (isSelected) {
            return css`
                border-width: ${theme.borderWidths.xLarge};
            `;
        }
    }};
`;

export const ErrorInfo = styled(P4)`
    color: ${theme.colors['text-global-error']};
`;
