import type { Translator } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { AdvertImage as LegacyAdvertImage } from '@type/gallery/advertImage';
import type { ReactImageGalleryItem } from 'react-image-gallery';

import { createSingleImageSet } from './createSingleImageSet';

interface GetGalleryDataProps {
    images: LegacyAdvertImage[];
    title: string;
    t: Translator;
}

export const getGalleryData = ({ images, title, t }: GetGalleryDataProps): ReactImageGalleryItem[] => {
    return images.map((image, index, total) => {
        return createSingleImageSet({ image, title, t, index, totalCount: total.length });
    });
};
