import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import ArrowRight from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowRight';
import { P3 } from '@nexus/lib-react/dist/core/Typography';

export const StyledButton = styled.button`
    display: flex;
    align-items: center;
    width: 100%;
    height: 32px;
    margin: ${theme.space['12']} ${theme.space['0']};
    padding: ${theme.space['0']} ${theme.space['16']};
    border: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
    gap: ${theme.space['8']};

    @media ${theme.mediaQueries.lgMin} {
        justify-content: flex-start;
        padding: ${theme.space['0']};
    }
`;

export const AgencyDetails = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space['8']};
    overflow: hidden;
`;

export const Logo = styled.img`
    width: auto;
    max-width: 100px;
    height: 32px;
    object-fit: contain;
`;

export const AgencyName = styled(P3)`
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;

    @media ${theme.mediaQueries.lgMin} {
        max-width: 600px;
    }
`;

export const ArrowIcon = styled(ArrowRight)`
    width: ${theme.space['16']};
    min-width: ${theme.space['16']};
`;
