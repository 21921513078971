import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';

export const Wrapper = styled.span`
    padding-top: ${theme.space[4]};
`;

export const StyledButton = styled(Button)`
    height: ${theme.sizes[30]};
    padding: ${theme.space['6']} ${theme.space['16']};
    outline: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-secondary']};
    box-shadow: none;
    color: ${theme.colors.$07_black};
    font-weight: ${theme.fontWeights.regular};
    letter-spacing: normal;

    &:hover {
        outline-width: ${theme.borderWidths[3]};
    }

    &:hover:not(:active) {
        box-shadow: none;
    }
`;
