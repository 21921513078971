import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import { useSnackbarState } from '@nexus/lib-react/dist/core/Snackbar';
import { InteractiveIsland } from '@widgets/AdvertCard/Navigation';
import { ButtonContentWrapper, StyledSnackbar } from '@widgets/CompareAds/CompareAdsButton/CompareAdsButton.theme';
import { COMPARE_ADS_DIALOG_ID } from '@widgets/CompareAds/constants/dialogId';
import { COMPARE_ADS_SNACKBAR_ID } from '@widgets/CompareAds/constants/snackbarId';
import type { JSX } from 'react';

import CompareIcon from '../icons/compare.svg';

export const CompareAdsButton = (): JSX.Element => {
    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const { openDialog } = useDialogState(COMPARE_ADS_DIALOG_ID);
    const { isOpen: isSnackbarOpen } = useSnackbarState(COMPARE_ADS_SNACKBAR_ID);

    const handleClick = (): void => {
        trackEvent('compare_ad_click');
        openDialog();
    };

    return (
        <>
            <InteractiveIsland shouldStopEventPropagation>
                <Button onClick={handleClick} variant="flat" data-testid="compare-ads-button">
                    <ButtonContentWrapper>
                        <CompareIcon />
                        <span>{t('frontend.widget.compare-ads.button-label')}</span>
                    </ButtonContentWrapper>
                </Button>
            </InteractiveIsland>
            {isSnackbarOpen ? (
                <StyledSnackbar
                    id={COMPARE_ADS_SNACKBAR_ID}
                    content={t('frontend.widget.compare-ads.snackbar')}
                    position="bottom-center"
                    variant="neutral"
                />
            ) : null}
        </>
    );
};
