import { TRACKING_EXTRA_DATA } from '@domains/ad/components/AdBreadcrumbs/constants/tracking';
import { ButtonContentWrapper, StyledButton } from '@domains/ad/nexusComponents/AdPageTopBar/AdPageTopBar.theme';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { checkIfInternalReferrer } from '@lib/pages/checkIfInternalReferrer';
import { useTracking } from '@lib/tracking/useTracking';
import type { ButtonProps } from '@nexus/lib-react/dist';
import ArrowLeft from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowLeft';
import { useRouter } from 'next/router';
import { type JSX, useEffect } from 'react';

interface Props {
    referer: string | null;
}

const BackToListingButton = ({ referer }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const router = useRouter();
    const { lang } = useSiteSettings();

    const isInternalReferer = referer && checkIfInternalReferrer(referer);

    useEffect(() => {
        if (isInternalReferer && !referer.includes('login')) {
            window.localStorage.setItem('previousUrl', referer);
        }
    }, [isInternalReferer, referer, lang]);

    const handleGoBackButtonClick: ButtonProps['onClick'] = () => {
        trackEvent('breadcrumb_click', TRACKING_EXTRA_DATA);

        const previousUrl = window.localStorage.getItem('previousUrl');

        if (isInternalReferer && previousUrl) {
            window.localStorage.removeItem('previousUrl');
            router.push(previousUrl);
        } else {
            router.push('/');
        }
    };

    return (
        <StyledButton variant="flat" onClick={handleGoBackButtonClick} data-cy="breadcrumb-go-back-button">
            <ButtonContentWrapper>
                <ArrowLeft />
                {t('frontend.ad.breadcrumbs.go-back-to-list')}
            </ButtonContentWrapper>
        </StyledButton>
    );
};

export default BackToListingButton;
