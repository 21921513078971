import type { SimilarOfferResponse, SimilarOfferVariables } from '@domains/ad/types/savedSearchCreateSimilarOfferType';
import { gql } from '@urql/core';

export const SAVED_SEARCH_CREATE_SIMILAR_OFFER_MUTATION = gql<SimilarOfferResponse, SimilarOfferVariables>`
    mutation SavedSearchCreateSimilarOfferMutation($id: Int64!) {
        savedSearchCreateSimilarOffer(advertID: $id) {
            ... on OperationSearchSuccess {
                success
            }
            ... on SimilarOfferSearchExists {
                success
            }
            ... on OperationSearchError {
                error
            }
        }
    }
`;
