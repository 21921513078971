import { useTracking } from '@lib/tracking/useTracking';
import type { ChangeEvent, JSX } from 'react';
import { useCallback } from 'react';

import { Extreme, StyledSliderInput, Wrapper } from './RangeSlider.theme';

interface Options {
    min: number;
    max: number;
    step: number;
}

interface RangeSliderProps {
    name: string;
    value: number;
    options: Options;
    onRegisterChange: (...event: unknown[]) => void;
    defaultValue: number;
    onChangeCallback?: () => void;
    extremeUnit?: string;
    tracking?: { eventName: string; data?: Record<string, string> };
}

export const RangeSlider = ({
    name,
    value,
    options,
    onRegisterChange,
    onChangeCallback,
    tracking,
    extremeUnit = '',
}: RangeSliderProps): JSX.Element => {
    const { min, max, step } = options;
    const { trackEvent } = useTracking();

    // Be proxy when input - send a different value to react-hook-form when it is displayed
    const onRangeInputChange = useCallback(
        (event: ChangeEvent<HTMLInputElement>) => {
            const { currentTarget } = event;
            const currentValue = currentTarget.value;

            const target = {
                name: currentTarget.name,
                value: currentValue !== '' && currentValue !== null ? +currentValue : null,
            };

            if (tracking) {
                trackEvent(tracking.eventName, tracking.data);
            }

            onRegisterChange({
                ...event,
                target,
                currentTarget: target,
            });
            onChangeCallback?.();
        },
        [onRegisterChange, onChangeCallback, trackEvent, tracking],
    );

    const extremeMin = `${min}${extremeUnit}`;
    const extremeMax = `${max}${extremeUnit}`;

    return (
        <Wrapper>
            <Extreme>{extremeMin}</Extreme>
            <StyledSliderInput
                min={min}
                value={value}
                max={max}
                onChange={onRangeInputChange}
                step={step}
                data-testid={`range-input-${name}`}
                data-cy={`range-input-${name}`}
                showExtremes={false}
            />
            <Extreme>{extremeMax}</Extreme>
        </Wrapper>
    );
};
