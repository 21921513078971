import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import QuestionMark from '@nexus/lib-react/dist/core/Icon/icons/default/QuestionMark';
import { Popover } from '@nexus/lib-react/dist/core/Popover';
import { P4 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    height: 344px;
    padding-left: ${theme.space['16']};

    @media ${theme.mediaQueries.xlMin} {
        padding-left: ${theme.space['0']};
    }
`;

export const Heading = styled.div`
    display: flex;
    gap: ${theme.space['8']};
    margin-bottom: ${theme.space['32']};
`;

export const StyledPopover = styled(Popover)`
    --bgColor: ${theme.colors['background-global-inverse']};

    width: 260px;
    padding: ${theme.space['8']} ${theme.space['16']};

    @media ${theme.mediaQueries.lgMin} {
        width: 300px;
    }
`;

export const StyledQuestionMark = styled(QuestionMark)`
    width: 24px;
    height: 24px;
    margin-top: -2px;
    color: ${theme.colors['icon-global-disabled']};
`;

export const TooltipText = styled(P4)`
    color: ${theme.colors.$01_white};
`;

export const ItemsContainer = styled.div`
    display: flex;
    max-width: 100vw;
    padding-right: ${theme.space['16']};
    overflow: scroll hidden;
    white-space: nowrap;
    gap: ${theme.space['16']};
    scrollbar-width: none;

    @media ${theme.mediaQueries.xlMin} {
        max-width: calc(816px + 400px);
        padding-right: ${theme.space['0']};
    }
`;
