import { AdvertSlot } from '@domains/shared/components/Advertising/AdvertSlot';
import { useBaxterAdvertising } from '@domains/shared/components/Advertising/useBaxterAdvertising';
import type { FC } from 'react';

interface Props {
    isMobile: boolean;
}

export const AdMiddleAdvert: FC<Props> = ({ isMobile }) => {
    useBaxterAdvertising();

    if (!isMobile) {
        return null;
    }

    return <AdvertSlot id="baxter-a-middle-1" />;
};
