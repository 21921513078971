import type { CategoryCodes } from '@olxeu-eprivacy-storage/react';
import { isConsentGiven as checkIsConsentGiven, waitForUserDecision } from '@olxeu-eprivacy-storage/react';
import { useEffect, useState } from 'react';

/**
 * Checks if user gave consent for the following categories:
 * C0002 -> performance cookies
 * C0003 -> functional cookies
 * C0004 -> targeting cookies
 * @link https://app-de.onetrust.com/cookies/categorizations?tab=Categories
 */
export const useUserConsentForEmbeddedContent = (): { isConsentGiven: boolean } => {
    const [isConsentGiven, setIsConsentGiven] = useState<boolean>(false);
    useEffect(() => {
        let isMounted = true;

        waitForUserDecision().finally(() => {
            if (!isMounted) {
                return;
            }

            setIsConsentGiven(
                checkIsConsentGiven('C0002' as CategoryCodes) &&
                    checkIsConsentGiven('C0003' as CategoryCodes) &&
                    checkIsConsentGiven('C0004' as CategoryCodes),
            );
        });

        return (): void => {
            isMounted = false;
        };
    }, []);

    return { isConsentGiven };
};
