import 'react-image-gallery/styles/css/image-gallery.css';

import styled from '@emotion/styled';
import { LAYER } from '@lib/styles/partials/layers';
import { theme } from '@lib/styles/themes/nexus';
import type { CSSProperties } from 'react';

const NavContainer = styled.div`
    position: absolute;
    z-index: ${LAYER.x5};
    cursor: pointer;

    svg {
        width: ${theme.sizes[32]};
        height: ${theme.sizes[32]};
        padding: ${theme.space[6]};
        border-radius: 100%;
        background-color: ${theme.colors['background-brand-primary']}4D;
        color: ${theme.colors['$01_white']};
    }
`;

const BORDER_GLOBAL_SECONDARY = theme.colors['borders-global-secondary'];

export const GalleryWrapper = styled.div<{ isFullscreen: boolean; shouldShowThumbnails: boolean }>`
    position: relative;

    .image-gallery {
        .image-gallery-thumbnails-wrapper {
            height: 96px;
        }
        &.fullscreen-modal {
            display: flex;
            z-index: ${theme.zIndices.modalBackdrop};
            align-items: center;
        }
        &-thumbnails {
            padding: ${theme.space['0']};

            &-container {
                padding: ${theme.space['8']} ${theme.space['6']} 0 ${theme.space['6']};
                background: ${theme.colors['$01_white']};
                text-align: left;
            }
        }
        &-thumbnail {
            width: 140px;
            margin: 0 ${theme.space[4]};
            transition: none;
            cursor: pointer;

            &-inner {
                &:focus {
                    border: ${theme.sizes['4']} ${theme.colors['$05_gray']} solid;
                }

                img {
                    width: 100%;
                    height: 80px;
                    object-fit: cover;
                }
            }

            &.active,
            &.active &-inner {
                border: ${theme.sizes['4']} ${theme.colors['$05_gray']} solid;
            }

            &:hover {
                border: ${theme.sizes['4']} ${theme.borderStyles.solid} ${BORDER_GLOBAL_SECONDARY};
            }
        }
        .image-gallery-slides {
            display: inherit;
        }
        &-content {
            &.fullscreen {
                display: grid;
                grid-template-columns: 100%;
                grid-template-rows: ${({ shouldShowThumbnails }): CSSProperties['gridTemplateRows'] =>
                    shouldShowThumbnails ? '1fr 106px' : 'initial'};
                width: 100vw;
                height: 100vh;
                background-color: ${theme.colors['background-brand-primary']};

                .image-gallery-slide-wrapper {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background: ${({ isFullscreen }): CSSProperties['background'] =>
                        isFullscreen
                            ? 'linear-gradient(' + theme.colors['$07_black'] + ' 0%, transparent 10%)'
                            : 'none'};
                }

                .image-gallery-swipe {
                    width: 100%;

                    img {
                        position: relative;
                        width: 100%;
                        height: 100%;
                        object-fit: contain !important;
                        max-height: ${({ shouldShowThumbnails }): CSSProperties['padding'] =>
                            shouldShowThumbnails ? 'calc(100vh - 100px)' : '100vh'};

                        @media ${theme.mediaQueries.lgMin} {
                            width: calc(100% - 100px);
                            padding: ${({ shouldShowThumbnails }): CSSProperties['padding'] =>
                                shouldShowThumbnails ? '0 60px' : '0'};
                        }
                    }
                }

                .image-gallery-slides {
                    aspect-ratio: auto;
                }
                .image-gallery-thumbnails {
                    &-container {
                        padding: ${theme.space['6']};
                        background: ${theme.colors['background-brand-primary']};
                        text-align: center;
                    }
                    &-wrapper {
                        background: ${theme.colors['background-brand-primary']};
                    }
                }
                .image-gallery-thumbnail {
                    &-inner {
                        &:focus {
                            border: ${theme.sizes['4']} ${theme.colors['$01_white']} solid;
                        }
                    }
                    &.active,
                    &.active &-inner {
                        border: ${theme.sizes['4']} ${theme.colors['$01_white']} solid;
                    }
                    &:hover {
                        border: ${theme.sizes['4']} ${BORDER_GLOBAL_SECONDARY} ${theme.borderStyles.solid};
                    }
                }
            }
        }
    }
`;

/* Default styles, apply for mobile or narrow viewports */
export const IndexCounter = styled.div<{
    isFullscreen: boolean;
    shouldShowThumbnails: boolean;
}>`
    display: flex;
    position: ${({ isFullscreen }): CSSProperties['position'] => (isFullscreen ? 'fixed' : 'absolute')};
    z-index: ${({ isFullscreen }): CSSProperties['zIndex'] => (isFullscreen ? theme.zIndices.modal : 50)};
    right: ${theme.space[24]};
    bottom: ${({ shouldShowThumbnails }): CSSProperties['bottom'] =>
        shouldShowThumbnails ? '124px' : theme.space['8']};
    align-items: center;
    padding: ${theme.space['4']} ${theme.space['8']};
    border-radius: ${theme.space['30']};
    background-color: ${({ isFullscreen }): CSSProperties['backgroundColor'] =>
        isFullscreen ? theme.colors['background-brand-primary'] : 'transparent'};
    color: ${theme.colors['$01_white']};
    font-family: ${theme.fonts.family};
    font-size: ${theme.fontSizes['12']};
    font-weight: ${theme.fontWeights['700']};
    line-height: ${theme.lineHeights['14']};
    gap: ${theme.space['8']};

    svg {
        color: ${theme.colors['$01_white']};
    }

    @media ${theme.mediaQueries.lgMin} {
        bottom: ${({ shouldShowThumbnails }): CSSProperties['bottom'] =>
            shouldShowThumbnails ? '112px' : theme.space[24]};
        background-color: ${theme.colors['background-brand-primary']};
    }
`;

export const LeftNav = styled(NavContainer)<{ isFullscreen: boolean }>`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
        top: ${({ isFullscreen }): CSSProperties['top'] => (isFullscreen ? 'initial' : '46.5%')};
        left: ${theme.space['16']};
    }
`;

export const RightNav = styled(NavContainer)<{ isFullscreen: boolean }>`
    display: none;

    @media (min-width: ${theme.breakpoints.md}) {
        display: block;
        top: ${({ isFullscreen }): CSSProperties['top'] => (isFullscreen ? 'initial' : '46.5%')};
        right: ${theme.space['16']};
    }
`;
