import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const MainPriceWrapper = styled.div`
    display: flex;
    flex-wrap: nowrap;
    margin-right: ${theme.space[20]};
`;

export const Price = styled.strong`
    font-size: ${theme.fontSizes.h2};
    line-height: ${theme.lineHeights[34]};
`;

export const PriceSuffixWrapper = styled.span`
    display: flex;
    margin-left: ${theme.space[4]};
    padding-bottom: ${theme.space[2]};
`;

export const PriceSuffix = styled.span`
    align-self: flex-end;
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes[16]};
    font-weight: ${theme.fontWeights.regular};
`;

export const AdditionalPriceWrapper = styled.div`
    display: flex;
    flex-grow: 2;
    margin-right: ${theme.space[10]};
`;

export const AdditionalPriceElement = styled.div`
    align-self: flex-end;
    color: ${theme.colors['text-global-highlight']};
    font-size: ${theme.sizes[16]};
`;
