import { useGetAdLocationAddress } from '@domains/ad/hooks/useGetAdLocationAddress';
import { AdMapSkeleton } from '@domains/ad/shared/components/AdMap.skeleton';
import { useIntersection } from '@domains/shared/hooks/useIntersection/useIntersection';
import { useTracking } from '@lib/tracking/useTracking';
import type { AdvertLocationDetails } from '@type/location/advertLocation';
import dynamic from 'next/dynamic';
import type { JSX } from 'react';
import { memo, useRef, useState } from 'react';

import { Container, Heading, LocationWrapper, StyledMapViewIcon } from './AdMap.theme';

interface Props {
    title: string;
    location: AdvertLocationDetails;
}

const LazyAdMap = dynamic(() => import('./Map'), {
    loading: () => <AdMapSkeleton />,
    ssr: false,
});

const BaseAdMap = (props: Props): JSX.Element => {
    const { title, location } = props;

    const titleRef = useRef<HTMLHeadingElement>(null);
    const [isIntersecting, setIsIntersecting] = useState(false);
    const [isImpressionTracked, setIsImpressionTracked] = useState(false);

    const isExactLocation = location.mapDetails?.radius === 0;

    const locationAddress = useGetAdLocationAddress(location);

    const { trackEvent } = useTracking();

    useIntersection(titleRef, () => {
        setIsIntersecting(true);

        if (!isImpressionTracked) {
            trackEvent('map_ad_page_impression');
            setIsImpressionTracked(true);
        }
    });

    return (
        <Container id="map">
            <Heading innerRef={titleRef}>{title}</Heading>
            {isExactLocation && (
                <LocationWrapper>
                    <StyledMapViewIcon />
                    {locationAddress}
                </LocationWrapper>
            )}
            {isIntersecting ? <LazyAdMap location={location} isExactLocation={isExactLocation} /> : <AdMapSkeleton />}
        </Container>
    );
};

export const AdMap = memo(BaseAdMap);
