import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: ${theme.space['16']};
    gap: ${theme.space['16']};
    background-color: ${theme.colors['background-brand-notification-neutral']};

    @media ${theme.mediaQueries.smMin} {
        flex-direction: row;
        justify-content: space-between;
    }
`;

export const InfoContainer = styled.div`
    display: flex;
    gap: ${theme.space['16']};
`;

export const Description = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${theme.space['10']};
`;
