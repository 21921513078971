import { gql } from '@urql/core';

interface Data {
    advertFloorPlans: {
        urls: string[];
    };
}

interface Variables {
    advertId: number;
}

export const ADVERT_FLOOR_PLANS_QUERY = gql<Data, Variables>`
    query advertFloorPlans($advertId: ID!) {
        advertFloorPlans(advertId: $advertId) {
            ... on AdvertFloorPlansResponse {
                urls
            }
        }
    }
`;
