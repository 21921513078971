import type { Dispatch, JSX, PropsWithChildren, SetStateAction } from 'react';
import { createContext, useState } from 'react';

export type ContactFormState = 'form' | 'success-view';
export type ContactFormType = 'standard' | 'ask-for-price';

interface ContactFormContextProps {
    state: ContactFormState;
    setState: Dispatch<SetStateAction<ContactFormState>>;
    trackingData: Record<string, unknown>;
    setTrackingData: Dispatch<Record<string, unknown>>;
    setDefaultTrackingData: () => void;
    formType: ContactFormType;
    setFormType: Dispatch<ContactFormType>;
}

const DEFAULT_TRACKING = {
    touch_point_button: 'aside_form',
    contact_form_type: 'main_contact_form',
    message_detail: 'default_predefined_message',
};

export const ContactFormContext = createContext<ContactFormContextProps>({
    state: 'form',
    setState: () => undefined,
    trackingData: DEFAULT_TRACKING,
    setTrackingData: () => undefined,
    setDefaultTrackingData: () => undefined,
    formType: 'standard',
    setFormType: () => undefined,
});

export const ContactFormContextProvider = ({ children }: PropsWithChildren<unknown>): JSX.Element => {
    const [state, setState] = useState<ContactFormState>('form');
    const [trackingData, setTrackingData] = useState<Record<string, unknown>>(DEFAULT_TRACKING);
    const [formType, setFormType] = useState<ContactFormType>('standard');

    const setDefaultTrackingData = (): void => {
        setTrackingData(DEFAULT_TRACKING);
    };

    return (
        <ContactFormContext.Provider
            value={{ state, setState, trackingData, setTrackingData, setDefaultTrackingData, formType, setFormType }}
        >
            {children}
        </ContactFormContext.Provider>
    );
};
