import type {
    CreateUnconfirmedSimilarOfferSearchResponse,
    CreateUnconfirmedSimilarOfferSearchVariables,
} from '@domains/ad/types/createUnconfirmedSimilarOfferSearchType';
import { gql } from '@urql/core';

export const CREATE_UNCONFIRMED_SIMILAR_OFFER_SEARCH_MUTATION = gql<
    CreateUnconfirmedSimilarOfferSearchResponse,
    CreateUnconfirmedSimilarOfferSearchVariables
>`
    mutation CreateUnconfirmedSimilarOfferSearch($advertID: Int64!, $email: String!) {
        createUnconfirmedSimilarOfferSearch(advertID: $advertID, email: $email) {
            ... on OperationSearchSuccess {
                success
            }
            ... on OperationSearchError {
                error
            }
        }
    }
`;
