import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P3 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    width: 100%;
    padding: ${theme.space[24]} ${theme.space[24]} ${theme.space[12]} ${theme.space[24]};
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors['background-global-primary']};
`;

export const FieldWrapper = styled.div`
    display: flex;
    flex-flow: column nowrap;
    margin-bottom: ${theme.space[20]};
    padding-top: ${theme.space[12]};
    padding-bottom: ${theme.space[24]};
    border-bottom: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};

    @media ${theme.mediaQueries.smMin} {
        flex-direction: row;
        padding-bottom: ${theme.space[4]};
    }
`;

export const FieldLeftColumn = styled.div`
    width: 100%;
`;

export const FieldRightColumn = styled.div`
    min-width: 280px;
`;

export const FieldDescription = styled(P3)`
    display: inline-block;
    color: ${theme.colors['text-global-secondary']};
`;

export const FieldLabel = styled.label`
    margin-bottom: ${theme.space[4]};
    font-size: ${theme.fontSizes.p2};
    font-weight: ${theme.fontWeights.bold};
`;

export const RangeSliderWrapper = styled.div`
    width: 100%;
    margin-top: ${theme.space[4]};
    margin-bottom: ${theme.space[20]};
`;
