import { getAddress } from '@domains/ad/nexusComponents/RecommendedAdItem/helpers/getAddress';
import { getArea } from '@domains/ad/nexusComponents/RecommendedAdItem/helpers/getArea';
import { getPrice } from '@domains/ad/nexusComponents/RecommendedAdItem/helpers/getPrice';
import { getPricePerMeter } from '@domains/ad/nexusComponents/RecommendedAdItem/helpers/getPricePerMeter';
import { getProjectArea } from '@domains/ad/nexusComponents/RecommendedAdItem/helpers/getProjectArea';
import { getProjectPrice } from '@domains/ad/nexusComponents/RecommendedAdItem/helpers/getProjectPrice';
import { getRent } from '@domains/ad/nexusComponents/RecommendedAdItem/helpers/getRent';
import { getRoomNumber } from '@domains/ad/nexusComponents/RecommendedAdItem/helpers/getRoomNumber';
import {
    AdvertAddress,
    AdvertDetail,
    AdvertDetailsWrapper,
    DescriptionWrapper,
    PriceWrapper,
    RentContainer,
} from '@domains/ad/nexusComponents/RecommendedAdItem/RecommendedAdItemDetails.theme';
import type { RecommendedAd } from '@domains/ad/types/Ad';
import { useAdvertImpressionsTracking } from '@domains/shared/hooks/useAdvertImpressionsTracking';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { H4, P3 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';
import { useRef } from 'react';

interface Props {
    ad: RecommendedAd;
    adsType: 'similar-ads' | 'user-ads';
}

export const RecommendedAdItemDetails = ({ ad, adsType }: Props): JSX.Element => {
    const containerRef = useRef<HTMLDivElement | null>(null);
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const trackingParam = adsType === 'similar-ads' ? 'similar_ads_recommendations' : 'more_from_seller';
    const { characteristics, adId, adCategory, location } = ad;

    const price = getPrice(characteristics, lang);
    const pricePerMeter = getPricePerMeter(characteristics, lang);
    const projectPricePerMeter = getProjectPrice(characteristics, t);
    const locationAddress = getAddress(location);
    const area = getArea(characteristics);
    const roomNumbers = getRoomNumber(characteristics, t, lang);
    const projectArea = getProjectArea(characteristics);
    const rent = getRent(characteristics, t);

    useAdvertImpressionsTracking(containerRef, {
        touch_point_button: trackingParam,
        ad_id: adId,
    });
    const advertDetails = [roomNumbers, area, pricePerMeter, projectArea].filter((detail) => detail !== null);

    return (
        <DescriptionWrapper>
            <PriceWrapper>
                <H4 variant="bold">{price ?? projectPricePerMeter ?? t('frontend.ad.user-ads.price-on-demand')}</H4>
                {adCategory?.type === 'RENT' && (
                    <RentContainer>
                        {rent} {t('frontend.ad.user-ads.per-month')}
                    </RentContainer>
                )}
            </PriceWrapper>
            <AdvertAddress>{locationAddress}</AdvertAddress>
            {advertDetails.length > 0 && (
                <AdvertDetailsWrapper data-testid="recommended-ad-details">
                    {advertDetails.map((detail, i) => (
                        <AdvertDetail key={detail}>
                            {i > 0 && <div>&#x25A0;</div>} <P3>{detail}</P3>
                        </AdvertDetail>
                    ))}
                </AdvertDetailsWrapper>
            )}
        </DescriptionWrapper>
    );
};
