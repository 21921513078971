import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Button } from '@nexus/lib-react/dist/core/Button';

export const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${theme.space['8']} ${theme.space['8']};
    background: ${theme.colors.$01_white};

    @media ${theme.mediaQueries.smMin} {
        padding: ${theme.space['8']} ${theme.space['24']};
    }
`;

export const StyledButton = styled(Button)`
    height: ${theme.space['24']};
    font-size: ${theme.fontSizes['14']};
`;

export const ButtonContentWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space['8']};
`;

export const ActionButtonsContainer = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space['8']};
`;
