import { AD_CONFIG } from '@config/ad/adConfig';
import Ad from '@nexus/lib-react/dist/core/Icon/icons/default/Ad';
import AdditionalSocialBenefits from '@nexus/lib-react/dist/core/Icon/icons/default/AdditionalSocialBenefits';
import AirConditioning01 from '@nexus/lib-react/dist/core/Icon/icons/default/AirConditioning01';
import Bathroom from '@nexus/lib-react/dist/core/Icon/icons/default/Bathroom';
import EducationBudget from '@nexus/lib-react/dist/core/Icon/icons/default/EducationBudget';
import Floor from '@nexus/lib-react/dist/core/Icon/icons/default/Floor';
import Heating from '@nexus/lib-react/dist/core/Icon/icons/default/Heating';
import LandArea from '@nexus/lib-react/dist/core/Icon/icons/default/LandArea';
import NonSmoker from '@nexus/lib-react/dist/core/Icon/icons/default/NonSmoker';
import NPeopleInTheRoom from '@nexus/lib-react/dist/core/Icon/icons/default/NPeopleInTheRoom';
import NRooms from '@nexus/lib-react/dist/core/Icon/icons/default/NRooms';
import ParkingSpace from '@nexus/lib-react/dist/core/Icon/icons/default/ParkingSpace';
import RealEstateConsultant from '@nexus/lib-react/dist/core/Icon/icons/default/RealEstateConsultant';
import RoadMileage from '@nexus/lib-react/dist/core/Icon/icons/default/RoadMileage';
import SubsidizedHolidays from '@nexus/lib-react/dist/core/Icon/icons/default/SubsidizedHolidays';
import type { AdvertCategory } from '@type/ad/attributes/advertCategory';
import type { PropertyType } from '@type/ad/attributes/advertProperty';
import type { FunctionComponent } from 'react';

const getNumberOfUserLicense = (): {
    numberOfUserLicense?: 'number_of_user_license';
} => {
    return AD_CONFIG.adDetails.shouldDisplayNumberOfUserLicense
        ? { numberOfUserLicense: 'number_of_user_license' }
        : {};
};

export const APARTMENT_SALE = {
    heating: 'heating',
    floor: 'floor',
    rent: 'rent',
    finishingStage: 'construction_status',
    market: 'market',
    formOfOwnership: 'building_ownership',
    availableFrom: 'free_from',
    advertiserType: 'advertiser_type',
    extras: 'extras_types',
} as const;

export const APARTMENT_RENT = {
    heating: 'heating',
    floor: 'floor',
    finishingStage: 'construction_status',
    availableFrom: 'free_from',
    deposit: 'deposit',
    advertiserType: 'advertiser_type',
    extras: 'extras_types',
    ...getNumberOfUserLicense(),
} as const;

export const APARTMENT_BUILDING_MATERIALS = {
    constructionYear: 'build_year',
    lift: 'lift',
    buildingType: 'building_type',
    constructionMaterial: 'building_material',
    windows: 'windows_type',
    energyCertificate: 'energy_certificate',
    safety: 'safety',
} as const;

export const APARTMENT_EQUIPMENT = {
    furnitureAndEquipment: 'equipment_types',
    security: 'security_types',
    utilities: 'media_types',
} as const;

export const HOUSE_SALE = {
    buildingType: 'building_type',
    finishingStage: 'construction_status',
    market: 'market',
    availableFrom: 'free_from',
    advertiserType: 'advertiser_type',
    rent: 'rent',
    heating: 'heating_types',
    extras: 'extras_types',
} as const;

export const HOUSE_RENT = {
    buildingType: 'building_type',
    finishingStage: 'construction_status',
    availableFrom: 'free_from',
    advertiserType: 'advertiser_type',
    rent: 'rent',
    heating: 'heating_types',
    extras: 'extras_types',
    ...getNumberOfUserLicense(),
} as const;

export const HOUSE_BUILDING_DETAILS = {
    numFloors: 'floors_num',
    constructionYear: 'build_year',
    constructionMaterial: 'building_material',
    windows: 'windows_type',
    roofType: 'roof_type',
    roofing: 'roofing',
    typeAttic: 'garret_type',
    energyCertificate: 'energy_certificate',
    security: 'security_types',
} as const;

export const HOUSE_PLOT = {
    terrain_area: 'terrain_area',
    place: 'location',
    surroundings: 'vicinity_types',
    fence: 'fence_types',
    access: 'access_types',
    utilities: 'media_types',
} as const;

export const ROOM_RENT = {
    additionalFees: 'additional_cost',
    deposit: 'deposit',
    availableFrom: 'free_from',
    advertiserType: 'advertiser_type',
    buildingType: 'building_type',
    energyCertificate: 'energy_certificate',
    ...getNumberOfUserLicense(),
} as const;

export const ROOM_EQUIPMENT = {
    equipment: 'equipment_types',
    media: 'media_types',
} as const;

export const PLOT_SALE = {
    plotType: 'terrain_type',
    place: 'location',
    area: 'area',
    dimensions: 'dimensions',
    fence: 'fence',
    access: 'access_types',
    surroundings: 'vicinity_types',
    utilities: 'media_types',
    advertiserType: 'advertiser_type',
} as const;

export const PLOT_RENT = {
    plotType: 'terrain_type',
    place: 'location',
    area: 'area',
    dimensions: 'dimensions',
    fence: 'fence',
    access: 'access_types',
    surroundings: 'vicinity_types',
    utilities: 'media_types',
    advertiserType: 'advertiser_type',
    ...getNumberOfUserLicense(),
} as const;

export const COMMERCIAL_SALE = {
    floor: 'floor',
    intendedUse: 'use_types',
    premisesLocation: 'premises_location',
    finishingStage: 'construction_status',
    market: 'market',
    advertiserType: 'advertiser_type',
} as const;

export const COMMERCIAL_RENT = {
    floor: 'floor',
    intendedUse: 'use_types',
    premisesLocation: 'premises_location',
    availableFrom: 'free_from',
    advertiserType: 'advertiser_type',
    finishingStage: 'construction_status',
    ...getNumberOfUserLicense(),
} as const;

export const COMMERCIAL_FEATURES = {
    constructionYear: 'build_year',
    energyCertificate: 'energy_certificate',
    additionalInformation: 'extras_types',
    security: 'security_types',
    utilities: 'media_types',
} as const;

export const HALL_SALE = {
    intendedUse: 'use_types',
    finishingState: 'construction_status',
    market: 'market',
    advertiserType: 'advertiser_type',
} as const;

export const HALL_RENT = {
    intendedUse: 'use_types',
    finishingState: 'construction_status',
    advertiserType: 'advertiser_type',
    ...getNumberOfUserLicense(),
} as const;

export const HALL_BUILDING_MATERIALS = {
    height: 'height',
    buildingStructure: 'structure',
    buildingMaterial: 'building_material',
    heating: 'heating',
    flooring: 'flooring',
    fence: 'fence',
    parking: 'parking',
    access: 'access_types',
    energyCertificate: 'energy_certificate',
} as const;

export const HALL_UTILITIES_SECURITY = {
    utilities: 'media_types',
    security: 'security_types',
} as const;

export const GARAGE_SALE = {
    buildingStructure: 'structure',
    location: 'localization',
    heating: 'heating',
    lightning: 'lightning',
    market: 'market',
    energyCertificate: 'energy_certificate',
    advertiserType: 'advertiser_type',
} as const;

export const GARAGE_RENT = {
    buildingStructure: 'structure',
    location: 'localization',
    heating: 'heating',
    lightning: 'lightning',
    energyCertificate: 'energy_certificate',
    advertiserType: 'advertiser_type',
    ...getNumberOfUserLicense(),
} as const;

interface MainSection {
    highlights: { icon: FunctionComponent; key: string }[];
    detailsKeys: Record<string, string>;
}

export interface SecondarySection {
    title: string;
    keys: Record<string, string>;
}

export const getAdDetailsMainSection = (category: AdvertCategory): MainSection | null => {
    const { name, type } = category;
    switch (name) {
        case 'FLAT': {
            if (type === 'RENT') {
                return {
                    highlights: [
                        {
                            icon: LandArea,
                            key: 'area',
                        },
                        {
                            icon: EducationBudget,
                            key: 'rent_to_students',
                        },
                        {
                            icon: NRooms,
                            key: 'rooms_num',
                        },
                        {
                            icon: Bathroom,
                            key: 'bathrooms_num',
                        },
                        {
                            icon: NonSmoker,
                            key: 'non_smokers_only',
                        },
                        {
                            icon: Floor,
                            key: 'two_storey',
                        },
                    ],
                    detailsKeys: APARTMENT_RENT,
                };
            }

            return {
                highlights: [
                    {
                        icon: LandArea,
                        key: 'area',
                    },
                    {
                        icon: NRooms,
                        key: 'rooms_num',
                    },
                    {
                        icon: Bathroom,
                        key: 'bathrooms_num',
                    },
                    {
                        icon: Floor,
                        key: 'two_storey',
                    },
                ],
                detailsKeys: APARTMENT_SALE,
            };
        }
        case 'HOUSE': {
            if (type === 'RENT') {
                return {
                    highlights: [
                        {
                            icon: LandArea,
                            key: 'area',
                        },
                        {
                            icon: NonSmoker,
                            key: 'non_smokers_only',
                        },
                        {
                            icon: Bathroom,
                            key: 'bathrooms_num',
                        },
                        {
                            icon: NRooms,
                            key: 'rooms_num',
                        },
                        {
                            icon: SubsidizedHolidays,
                            key: 'recreational',
                        },
                    ],
                    detailsKeys: HOUSE_RENT,
                };
            }

            return {
                highlights: [
                    {
                        icon: LandArea,
                        key: 'area',
                    },
                    {
                        icon: NRooms,
                        key: 'rooms_num',
                    },
                    {
                        icon: Bathroom,
                        key: 'bathrooms_num',
                    },
                    {
                        icon: SubsidizedHolidays,
                        key: 'recreational',
                    },
                ],
                detailsKeys: HOUSE_SALE,
            };
        }
        case 'ROOM': {
            return {
                highlights: [
                    {
                        icon: LandArea,
                        key: 'area',
                    },
                    {
                        icon: NPeopleInTheRoom,
                        key: 'roomsize',
                    },
                    {
                        icon: NonSmoker,
                        key: 'non_smokers_only',
                    },
                ],
                detailsKeys: ROOM_RENT,
            };
        }
        case 'TERRAIN': {
            if (type === 'RENT') {
                return {
                    highlights: [
                        {
                            icon: LandArea,
                            key: 'area',
                        },
                    ],
                    detailsKeys: PLOT_RENT,
                };
            }

            return {
                highlights: [
                    {
                        icon: LandArea,
                        key: 'area',
                    },
                ],
                detailsKeys: PLOT_SALE,
            };
        }
        case 'COMMERCIAL_PROPERTY': {
            if (type === 'RENT') {
                return {
                    highlights: [
                        {
                            icon: LandArea,
                            key: 'area',
                        },
                        {
                            icon: Ad,
                            key: 'shop_window',
                        },
                        {
                            icon: ParkingSpace,
                            key: 'parking',
                        },
                        {
                            icon: RoadMileage,
                            key: 'asphalt_access',
                        },
                        {
                            icon: Heating,
                            key: 'heating',
                        },
                        {
                            icon: AirConditioning01,
                            key: 'air_conditioning',
                        },
                    ],
                    detailsKeys: COMMERCIAL_RENT,
                };
            }

            return {
                highlights: [
                    {
                        icon: LandArea,
                        key: 'area',
                    },
                    {
                        icon: Ad,
                        key: 'shop_window',
                    },
                    {
                        icon: ParkingSpace,
                        key: 'parking',
                    },
                    {
                        icon: RoadMileage,
                        key: 'asphalt_access',
                    },
                    {
                        icon: Heating,
                        key: 'heating',
                    },
                    {
                        icon: AirConditioning01,
                        key: 'air_conditioning',
                    },
                ],
                detailsKeys: COMMERCIAL_SALE,
            };
        }
        case 'HALL': {
            if (type === 'RENT') {
                return {
                    highlights: [
                        {
                            icon: LandArea,
                            key: 'area',
                        },
                        {
                            icon: RealEstateConsultant,
                            key: 'office_space',
                        },
                        {
                            icon: AdditionalSocialBenefits,
                            key: 'social_facilities',
                        },
                        {
                            icon: RoadMileage,
                            key: 'ramp',
                        },
                    ],
                    detailsKeys: HALL_RENT,
                };
            }

            return {
                highlights: [
                    {
                        icon: LandArea,
                        key: 'area',
                    },
                    {
                        icon: RealEstateConsultant,
                        key: 'office_space',
                    },
                    {
                        icon: AdditionalSocialBenefits,
                        key: 'social_facilities',
                    },
                    {
                        icon: RoadMileage,
                        key: 'ramp',
                    },
                ],
                detailsKeys: HALL_SALE,
            };
        }
        case 'GARAGE': {
            if (type === 'RENT') {
                return {
                    highlights: [
                        {
                            icon: LandArea,
                            key: 'area',
                        },
                    ],
                    detailsKeys: GARAGE_RENT,
                };
            }

            return {
                highlights: [
                    {
                        icon: LandArea,
                        key: 'area',
                    },
                ],
                detailsKeys: GARAGE_SALE,
            };
        }
        default: {
            return null;
        }
    }
};

export const getAdDetailsSecondarySection = (propertyType: PropertyType): SecondarySection | null => {
    switch (propertyType) {
        case 'FLAT': {
            return {
                title: 'frontend.ad.details.title-building-materials',
                keys: { ...APARTMENT_BUILDING_MATERIALS },
            };
        }
        case 'HOUSE': {
            return {
                title: 'frontend.ad.details.title-building-details',
                keys: { ...HOUSE_BUILDING_DETAILS },
            };
        }
        case 'ROOM': {
            return {
                title: 'frontend.ad.details.title-equipment',
                keys: { ...ROOM_EQUIPMENT },
            };
        }
        case 'COMMERCIAL_PROPERTY': {
            return {
                title: 'frontend.ad.details.title-features',
                keys: { ...COMMERCIAL_FEATURES },
            };
        }
        case 'HALL': {
            return {
                title: 'frontend.ad.details.title-building-materials',
                keys: { ...HALL_BUILDING_MATERIALS },
            };
        }
        default: {
            return null;
        }
    }
};

export const getAdDetailsThirdSection = (propertyType: PropertyType): SecondarySection | null => {
    switch (propertyType) {
        case 'FLAT': {
            return {
                title: 'frontend.ad.details.title-equipment',
                keys: { ...APARTMENT_EQUIPMENT },
            };
        }
        case 'HOUSE': {
            return {
                title: 'frontend.ad.details.title-plot-surroundings',
                keys: { ...HOUSE_PLOT },
            };
        }
        case 'HALL': {
            return {
                title: 'frontend.ad.details.title-utilities-security',
                keys: { ...HALL_UTILITIES_SECURITY },
            };
        }
        default: {
            return null;
        }
    }
};
