import { CONTACT_FORM_DIALOG_ID } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormDialogId';
import { ContactFormContext } from '@domains/ad/nexusComponents/ContactForm/contexts/ContactFormContext';
import { StyledButton } from '@domains/ad/nexusComponents/FixedMobileFooter/FixedMobileFooter.theme';
import type { Ad } from '@domains/ad/types/Ad';
import {
    MobileButtonsFooterContainer,
    PhoneButton,
} from '@domains/shared/components/nexus/MobileContactFormButtonsFooter/MobileContactFormButtonsFooter.theme';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { usePhoneRevealStatistics } from '@lib/tracking/usePhoneRevealStatistics';
import { useTracking } from '@lib/tracking/useTracking';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import type { JSX } from 'react';
import { useCallback, useContext, useState } from 'react';

interface Props {
    ad: Ad;
}

const TRACKING_DATA = {
    touch_point_button: 'contact_info',
    contact_form_type: 'mobile_sticky_footer',
};

export const FixedMobileFooter = ({ ad }: Props): JSX.Element => {
    const { openDialog } = useDialogState(CONTACT_FORM_DIALOG_ID);
    const { trackEvent } = useTracking();
    const [t] = useTranslations();
    const { updateShowPhoneStatistics } = usePhoneRevealStatistics();
    const { setTrackingData } = useContext(ContactFormContext);

    const [isPhoneNumberVisible, setIsPhoneNumberVisible] = useState<boolean>(false);
    const phone = ad.contactDetails?.phones[0];

    const handleClickMessageButton = useCallback((): void => {
        setTrackingData(TRACKING_DATA);
        trackEvent('reply_message_form_click', TRACKING_DATA);
        openDialog();
    }, [openDialog, trackEvent, setTrackingData]);

    const handleClickPhoneNumber = useCallback((): void => {
        trackEvent('reply_phone_call', TRACKING_DATA);

        if (!isPhoneNumberVisible) {
            trackEvent('reply_phone_show', TRACKING_DATA);
            setIsPhoneNumberVisible(true);
            updateShowPhoneStatistics({ advertId: ad.id });
        }
    }, [ad.id, isPhoneNumberVisible, trackEvent, updateShowPhoneStatistics]);

    return (
        <MobileButtonsFooterContainer>
            <StyledButton
                variant={phone ? 'secondary' : 'primary'}
                isPhoneAvailable={!!phone}
                onClick={handleClickMessageButton}
            >
                {t('frontend.shared.mobile-buttons-footer.message')}
            </StyledButton>
            {phone ? (
                <PhoneButton onClick={handleClickPhoneNumber} href={`tel:${phone}`}>
                    {isPhoneNumberVisible ? `${phone}` : t('frontend.shared.mobile-buttons-footer.call')}
                </PhoneButton>
            ) : null}
        </MobileButtonsFooterContainer>
    );
};
