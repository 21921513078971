import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { DEFAULT_FIELD_OPTION } from '@widgets/MortgageCalculator/core/consts/fieldOption';
import { FIELDS_NAME } from '@widgets/MortgageCalculator/core/consts/fieldsName';
import type { DefaultValues } from '@widgets/MortgageCalculator/core/types';
import type { JSX } from 'react';
import type { Control } from 'react-hook-form';
import { Controller } from 'react-hook-form';

import type { BaseFieldProps } from '../../AdCalculator';
import {
    FieldDescription,
    FieldLabel,
    FieldLeftColumn,
    FieldRightColumn,
    FieldWrapper,
    RangeSliderWrapper,
} from '../../AdCalculator.theme';
import { NumberInput } from '../NumberInput/NumberInput';
import { RangeSlider as NexusRangeSlider } from '../RangeSlider/RangeSlider';

interface RepaymentTermProps {
    control: Control<DefaultValues>;
    baseFieldProps: BaseFieldProps;
    loanTermWatched: number;
}

export const RepaymentTerm = ({ control, baseFieldProps, loanTermWatched }: RepaymentTermProps): JSX.Element => {
    const [t] = useTranslations();

    return (
        <>
            <div>
                <FieldLabel htmlFor={FIELDS_NAME.loanTerm}>
                    {t('frontend.widget.mortgage-calculator.ad-variant-loan-term')}
                </FieldLabel>{' '}
                <FieldDescription>
                    ({t('frontend.widget.mortgage-calculator.ad-variant-loan-term-description')})
                </FieldDescription>
            </div>
            <Controller
                control={control}
                name={FIELDS_NAME.loanTerm}
                render={({ field: { onChange, value } }): JSX.Element => {
                    const fieldProps = {
                        ...baseFieldProps,
                        name: FIELDS_NAME.loanTerm,
                        value: value || 0,
                        options: {
                            ...DEFAULT_FIELD_OPTION[FIELDS_NAME.loanTerm],
                            suffix: t('frontend.widget.mortgage-calculator.ad-variant-loan-term-input-suffix'),
                        },
                        tracking: {
                            eventName: 'calc_term_scroll',
                            data: {
                                touch_point_button: 'mortgage_calculator',
                            },
                        },
                        defaultValue: loanTermWatched,
                    };

                    return (
                        <FieldWrapper>
                            <FieldLeftColumn>
                                <RangeSliderWrapper>
                                    <NexusRangeSlider
                                        {...fieldProps}
                                        onRegisterChange={onChange}
                                        extremeUnit={t(
                                            'frontend.widget.mortgage-calculator.ad-variant-loan-term-extreme',
                                        )}
                                    />
                                </RangeSliderWrapper>
                            </FieldLeftColumn>
                            <FieldRightColumn>
                                <NumberInput {...fieldProps} />
                            </FieldRightColumn>
                        </FieldWrapper>
                    );
                }}
            />
        </>
    );
};
