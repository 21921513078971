import type { ParsedUrlQuery } from 'node:querystring';

import type { FinanceURLConfig } from '@config/ad/type';
import { buildLinkWithQueryParameters } from '@domains/shared/helpers/buildLinkWithQueryParameters';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useMemo } from 'react';

interface UseAdFinanceLinkGenerator {
    as: string;
    label?: string;
}

export const useAdFinanceLinkGenerator = (
    params: FinanceURLConfig,
    title?: string,
    propertyPrice?: string,
): UseAdFinanceLinkGenerator => {
    const { lang } = useSiteSettings();
    const linkUrl = useMemo(() => {
        let queryParameters: ParsedUrlQuery = {
            utm_source: params.utmSource,
            utm_medium: params.utmMedium,
            utm_content: params.utmContent,
            utm_campaign: params.utmCampaign,
        };
        if (params.shouldUseAdditionalParams) {
            queryParameters = {
                ...queryParameters,
                ...(propertyPrice && { propertyPrice }),
                ...(title && { title }),
            };
        }

        return buildLinkWithQueryParameters({
            href: params.baseUrl,
            query: queryParameters,
        });
    }, [params, propertyPrice, title]);

    return {
        as: linkUrl.replace('[lang]', lang),
        label: params.utmContent,
    };
};
