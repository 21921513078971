import { gql } from '@urql/core';

export const EXPECTED_BANK_RATE_QUERY_TYPENAME = ['BankRate'] as const;
interface BankRateResponse {
    bankRate: {
        average: number;
        lowest: number;
        __typename: 'BankRate';
    };
}

export const BANK_RATE_QUERY = gql<BankRateResponse>`
    query BankRate {
        bankRate {
            ... on BankRate {
                average
                lowest
                __typename
            }
            ... on ErrorInternal {
                code
                message
            }
        }
    }
`;
