import type { AdFullInformation } from '@domains/ad/types/adFullInformation';

export const getFilteredKeys = (
    fullAdDetails: AdFullInformation,
    allKeys: Record<string, string>,
): Record<string, string> => {
    const result: Record<string, string> = {};

    for (const [key, value] of Object.entries(allKeys)) {
        const hasKeyCorrectValue = fullAdDetails.some((info) => info.label === value && info.values.length > 0);
        if (hasKeyCorrectValue) {
            result[key] = value;
        }
    }

    return result;
};
