import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import { formatPrice } from '@domains/shared/helpers/price/formatPrice';
import type { Locale } from '@lib/i18n/types/locale';
import type { AdvertCharacteristic } from '@type/ad/advertCharacteristic';

export const getPrice = (characteristics: AdvertCharacteristic[], lang: Locale): string | null => {
    const getCharacteristic = getAdCharacteristic(characteristics);

    const priceFromCharacteristic = getCharacteristic('price');
    const isPriceProvided = priceFromCharacteristic?.currency && priceFromCharacteristic?.value;

    return isPriceProvided
        ? formatPrice({
              price: Number(priceFromCharacteristic.value),
              currency: priceFromCharacteristic.currency,
              lang,
              maximumFractionDigits: 0,
          })
        : null;
};
