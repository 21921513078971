import { AD_CONFIG } from '@config/ad/adConfig';
import { SITE_CONFIG } from '@config/siteConfig';
import { getCurrencyIsoCode } from '@domains/shared/helpers/price/getCurrencyIsoCode';
import { useEffect, useState } from 'react';

interface ReturnValue {
    selectedCurrency: string;
    selectedCurrencyCode: string;
    propertyPrice: number;
    handleCurrencyCodeChange: (selection: string) => void;
}

const { currency: DEFAULT_CURRENCY_SYMBOL } = SITE_CONFIG.defaultUnits;
const {
    shouldConvertPriceToLocalCurrency,
    currency: LOCAL_CURRENCY_SYMBOL,
    localCurrencyConversionRates,
} = AD_CONFIG.adMortgageSimulatorConfig || {};

const DEFAULT_CURRENCY_CODE = getCurrencyIsoCode(DEFAULT_CURRENCY_SYMBOL);
const LOCAL_CURRENCY_CODE = LOCAL_CURRENCY_SYMBOL ? getCurrencyIsoCode(LOCAL_CURRENCY_SYMBOL) : undefined;

export const useAdCalculatorConfig = (initialPrice: number): ReturnValue => {
    const [selectedCurrency, setSelectedCurrency] = useState(DEFAULT_CURRENCY_SYMBOL);
    const [selectedCurrencyCode, setSelectedCurrencyCode] = useState(DEFAULT_CURRENCY_CODE);
    const [propertyPrice, setPropertyPrice] = useState(initialPrice);

    const isLocalCurrencyAvailable = LOCAL_CURRENCY_SYMBOL && LOCAL_CURRENCY_CODE;

    const updateCurrencyState = ({ symbol, code }: { symbol: string; code: string }): void => {
        setSelectedCurrency(symbol);
        setSelectedCurrencyCode(code);
    };

    useEffect(() => {
        if (shouldConvertPriceToLocalCurrency && isLocalCurrencyAvailable) {
            updateCurrencyState({ symbol: LOCAL_CURRENCY_CODE, code: LOCAL_CURRENCY_CODE });
        }
    }, [isLocalCurrencyAvailable]);

    useEffect(() => {
        if (selectedCurrencyCode === DEFAULT_CURRENCY_CODE) {
            setPropertyPrice(initialPrice);

            return;
        }

        if (selectedCurrencyCode === LOCAL_CURRENCY_CODE && localCurrencyConversionRates) {
            setPropertyPrice(initialPrice * localCurrencyConversionRates.eur);
        }
    }, [selectedCurrencyCode, initialPrice]);

    const handleCurrencyCodeChange = (codeSelection: string): void => {
        if (codeSelection === DEFAULT_CURRENCY_CODE) {
            updateCurrencyState({ symbol: DEFAULT_CURRENCY_SYMBOL, code: codeSelection });
        }

        if (codeSelection === LOCAL_CURRENCY_CODE) {
            updateCurrencyState({ symbol: LOCAL_CURRENCY_CODE, code: codeSelection });
        }
    };

    return {
        selectedCurrency,
        selectedCurrencyCode,
        propertyPrice,
        handleCurrencyCodeChange,
    };
};
