import { CONTACT_FORM_DIALOG_ID } from '@domains/ad/nexusComponents/ContactForm/consts/contactFormDialogId';
import { ContactFormContext } from '@domains/ad/nexusComponents/ContactForm/contexts/ContactFormContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { Button } from '@nexus/lib-react/dist/core/Button';
import { useDialogState } from '@nexus/lib-react/dist/core/Dialog';
import type { JSX } from 'react';
import { useContext } from 'react';

export const AskAboutPrice = (): JSX.Element => {
    const [t] = useTranslations();
    const { openDialog } = useDialogState(CONTACT_FORM_DIALOG_ID);
    const { setFormType } = useContext(ContactFormContext);

    const handleClick = (): void => {
        setFormType('ask-for-price');
        openDialog();
    };

    return (
        <Button variant="tertiary" onClick={handleClick}>
            {t('frontend.ad.ad-header.ask-for-price')}
        </Button>
    );
};
