import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Chip } from '@nexus/lib-react/dist/core/Chip';

export const HighlightsContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: ${theme.space['12']};
    margin: ${theme.space['24']} ${theme.space['0']};
`;

export const StyledChip = styled(Chip)`
    cursor: default;
`;
