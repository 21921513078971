import { gql } from '@urql/core';

export const USER_FAVORITE_CHECK_QUERY = gql`
    query UserFavoriteCheck($adId: Int64!) {
        userFavoriteCheck(adId: $adId) {
            ... on FavoriteCheck {
                isFavorite
            }
        }
    }
`;
