import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import ArrowRight from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowRight';
import type { JSX, MutableRefObject } from 'react';
import { useContext } from 'react';

import { StyledBox } from './InvestmentUnit.theme';

interface Props {
    isInvestmentUnit: boolean;
    buttonRef?: MutableRefObject<null>;
    href?: string;
    label?: string;
}

export const InvestmentUnit = ({ isInvestmentUnit, buttonRef, href, label = '' }: Props): JSX.Element | null => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { isDesktop, isTablet } = useContext(RWDContext);
    const isMobile = !isDesktop && !isTablet;

    const handleClick = (): void => {
        if (href) {
            trackEvent('parent_investment_link_click', {
                touch_point_button: 'under_gallery',
            });
            window.open(href, '_blank');
        }
    };

    if (!isInvestmentUnit) {
        return null;
    }

    const labelPrefix = isMobile
        ? t('frontend.ad.header.property-investment-part-mobile')
        : t('frontend.ad.header.property-investment-part');

    return (
        <div ref={buttonRef}>
            <StyledBox
                content={
                    <span>
                        {labelPrefix} <strong>{label}</strong>
                    </span>
                }
                variant="action"
                suffixIcon={href ? ArrowRight : undefined}
                onClick={handleClick}
                data-testid="investment-unit"
            />
        </div>
    );
};
