import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

import { FieldDescription, FieldLabel, FieldWrapper } from '../../AdCalculator.theme';

export const StyledFieldWrapper = styled(FieldWrapper)`
    @media ${theme.mediaQueries.smMin} {
        padding-bottom: ${theme.space[24]};
    }
`;

export const StyledFieldLabel = styled(FieldLabel)`
    display: block;
    padding-right: ${theme.space[12]};
`;

export const StyledFieldDescription = styled(FieldDescription)`
    padding-right: ${theme.space[12]};
    padding-bottom: ${theme.space[12]};

    @media ${theme.mediaQueries.smMin} {
        padding-bottom: 0;
    }
`;
