import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Divider } from '@nexus/lib-react/dist/core/Divider';
import { P2 } from '@nexus/lib-react/dist/core/Typography';

export const Container = styled.div`
    width: 100%;
    max-width: 100vw;
    padding: ${theme.space['24']} ${theme.space['24']} ${theme.space['12']} ${theme.space['24']};
    border-radius: ${theme.radii[4]};
    background-color: ${theme.colors.$01_white};
`;

export const DescriptionWrapper = styled.div<{ shouldTrim: boolean }>`
    height: ${({ shouldTrim }): string => (shouldTrim ? '70px' : 'auto')};
    margin: ${theme.space['24']} ${theme.space['0']};
    overflow: hidden;
    font-family: ${theme.fonts.family};
    overflow-wrap: break-word;
`;

export const StyledDivider = styled(Divider)`
    margin: ${theme.space['24']} ${theme.space['0']} ${theme.space['16']};

    @media ${theme.mediaQueries.lgMin} {
        margin-bottom: ${theme.space['24']};
    }
`;

export const Title = styled(P2)`
    margin: ${theme.space['24']} ${theme.space['0']};
`;
