import { SITE_CONFIG } from '@config/siteConfig';
import type { MetadataJson, NumberFound } from 'libphonenumber-js/core';
import { findPhoneNumbersInText } from 'libphonenumber-js/core';

import metadata from './metadata.custom.json';

type CountryCodes = 'PL' | 'RO' | 'PT';

interface ExtractedNumbers {
    type: 'text' | 'number';
    chunk: string;
}

const detectFirstPhoneNumber = (text: string, countryCode: CountryCodes): NumberFound | undefined => {
    const detectedPhoneNumbers = findPhoneNumbersInText(
        text,
        {
            defaultCountry: countryCode,
        },
        metadata as MetadataJson,
    );

    if (Array.isArray(detectedPhoneNumbers) && detectedPhoneNumbers.length > 0) {
        return detectedPhoneNumbers[0];
    }
};

/**
 * findPhoneNumbers logic was originally developed in motors project.
 * It was tested and slightly simplified here - I attach the original description of the solution for greater clarity.
 *
 * We are using libphonenumber-js to match phone numbers. It has support for multiple formats, locales,
 * and default country code. This is a complex functionality which we do not want to create from scratch
 * nor maintain so we want to leverage this library as it has been tested by the community.
 *
 * However, when used to match numbers in a string it has some funky behaviors like:
 * - Numbers separated by "," are matched as one
 * - Only matches the first number found per line
 * - Behavior changes if a default country code is provided or not
 *
 * To work around this in an efficient way we are:
 * - splitting the src text by ","s and searching each segment one by one
 * - each segment may have line breaks, so we check multiple times until no number is found
 * - ensure a default country code is set for the corresponding market
 */

const findPhoneNumbers = (text: string): ExtractedNumbers[] => {
    const chunks: ExtractedNumbers[] = [];
    const { defaultLanguage } = SITE_CONFIG.metadata;
    const countryCode = defaultLanguage.toUpperCase() as CountryCodes;

    const splitByCommas = text.split(/,/gi);

    splitByCommas.map((segment, index) => {
        let lastSliceEnd = 0;

        // copy of the original string is used to replace numbers,
        // this way the numbers can be matched per iteration until there are no more
        let placeholderSegment = segment;

        // only the first number is taken into account in the matched set
        // ensuring numbers are consumed in sequence to avoid some funky edge cases
        let detectedPhoneNumber = detectFirstPhoneNumber(placeholderSegment, countryCode);

        while (detectedPhoneNumber) {
            // keeping track of the original string copy and as number is matched
            // it is replaced and on next iterations next number available is matched
            placeholderSegment =
                placeholderSegment.slice(0, detectedPhoneNumber.startsAt) +
                '-'.repeat(detectedPhoneNumber.endsAt - detectedPhoneNumber.startsAt) +
                placeholderSegment.slice(detectedPhoneNumber.endsAt);

            if (lastSliceEnd !== detectedPhoneNumber.startsAt) {
                chunks.push({
                    type: 'text',
                    chunk: segment.slice(lastSliceEnd, detectedPhoneNumber.startsAt),
                });
            }

            chunks.push({
                type: 'number',
                chunk: segment.slice(detectedPhoneNumber.startsAt, detectedPhoneNumber.endsAt),
            });

            lastSliceEnd = detectedPhoneNumber.endsAt;
            detectedPhoneNumber = detectFirstPhoneNumber(placeholderSegment, countryCode);
        }

        // no numbers are detected and string ends
        if (segment.length > lastSliceEnd) {
            chunks.push({ type: 'text', chunk: segment.slice(lastSliceEnd) });
        }

        // commas segments need to be added again
        if (index < splitByCommas.length - 1) {
            chunks.push({ type: 'text', chunk: ',' });
        }
    });

    // merging adjacent text elements
    return chunks.reduce((mergedChunks: ExtractedNumbers[], chunk) => {
        const previousChunk = mergedChunks[mergedChunks.length - 1];
        if (previousChunk && previousChunk.type === 'text' && chunk.type === 'text') {
            previousChunk.chunk += chunk.chunk;
        } else {
            mergedChunks.push(chunk);
        }

        return mergedChunks;
    }, []);
};

export const phoneNumberParser = (description: string, maskElement: string): string => {
    const extractedNumbers = findPhoneNumbers(description);

    return extractedNumbers.reduce((accumulator, current) => {
        if (current.type === 'number') {
            return (accumulator += maskElement);
        }

        return (accumulator += `${current.chunk}`);
    }, '');
};
