import { toast } from '@domains/shared/components/Toast/toast';
import { useLocalStorageCache } from '@domains/shared/hooks/useLocalStorageCache/useLocalStorageCache';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { CurrentUserContext } from '@lib/currentUser';
import { useTracking } from '@lib/tracking/useTracking';
import { useContext, useEffect } from 'react';

export const AD_SIMILAR_OFFER_REGISTER_PREFIX = 'AD_SIMILAR_OFFER_REGISTER';

interface AdSimilarOfferRegisterData {
    adId: number;
    email: string;
    toastType: 'success' | 'error';
}
const checkIsAdSimilarOfferRegisterData = (data: unknown): data is AdSimilarOfferRegisterData =>
    typeof data === 'object' &&
    data !== null &&
    typeof (data as AdSimilarOfferRegisterData).adId === 'number' &&
    typeof (data as AdSimilarOfferRegisterData).email === 'string' &&
    ['success', 'error'].includes((data as AdSimilarOfferRegisterData).toastType);

/**
 * This hook watch for current ad similar offer and cached register email and adId.
 * If both matches cleans cached data,
 * shows ad similar offer success or error toast,
 * sends tracking data about user registering using short register flow
 */
export const useAdSimilarOfferRegister = (adId: number): void => {
    const [t] = useTranslations();
    const currentUser = useContext(CurrentUserContext);
    const { trackEvent } = useTracking();

    const [getAdSimilarOfferRegister, , removeAdSimilarOfferRegister] = useLocalStorageCache(
        AD_SIMILAR_OFFER_REGISTER_PREFIX,
    );

    useEffect(() => {
        const cacheData = getAdSimilarOfferRegister();
        const savedAdSimilarOfferRegisterData = checkIsAdSimilarOfferRegisterData(cacheData) ? cacheData : undefined;

        if (
            savedAdSimilarOfferRegisterData?.adId === adId &&
            savedAdSimilarOfferRegisterData?.email === currentUser?.email
        ) {
            removeAdSimilarOfferRegister();

            if (savedAdSimilarOfferRegisterData?.toastType === 'success') {
                toast.success(t('frontend.ad.similar-offers-subscription.toast-success'), {
                    dataCy: 'toast.subscription-success',
                });
            } else {
                toast.error(t('frontend.toast.error'));
            }

            trackEvent('favourite_search_save_success', { touch_point_button: 'similar_ads' });
        }
    }, [adId, currentUser, getAdSimilarOfferRegister, removeAdSimilarOfferRegister, t, trackEvent]);
};
