import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { P3, P4 } from '@nexus/lib-react/dist/core/Typography';

export const DescriptionWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: ${theme.space['8']};
    padding: ${theme.space['16']} ${theme.space['12']};
`;

export const PriceWrapper = styled.div`
    display: flex;
    align-items: flex-end;
`;

export const RentContainer = styled(P4)`
    padding-bottom: ${theme.space['4']};
    padding-left: ${theme.space['4']};
    color: ${theme.colors['text-global-secondary']};
`;

export const AdvertAddress = styled(P3)`
    display: -webkit-box;
    overflow: hidden;
    color: ${theme.colors['text-global-secondary']};
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
`;

export const AdvertDetailsWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    max-width: 266px;
    gap: ${theme.space['8']};
`;

export const AdvertDetail = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space['8']};
`;
