import { logError, logWarn } from '@domains/shared/helpers/logger';

interface ShareData {
    title: string;
    text: string;
    url: string;
}

interface HandleShareApi {
    shareData: ShareData;
    shareApiSuccessCallback: () => void;
    nonShareApiCallback: () => void;
    abortCallback?: () => void;
    isDesktop: boolean | null;
}

export const handleShareApi = ({
    shareData,
    shareApiSuccessCallback,
    nonShareApiCallback,
    abortCallback,
    isDesktop,
}: HandleShareApi): void => {
    const isNativeShareApiEnabled = typeof window.navigator.share === 'function';
    const canUseShareApi = isNativeShareApiEnabled && window.navigator.canShare(shareData);

    // on desktop we don't want to use native share API (it's business and UX decision)
    if (isDesktop || !canUseShareApi) {
        nonShareApiCallback();

        return;
    }

    navigator
        .share(shareData)
        .then((): void => shareApiSuccessCallback?.())
        .catch((error): void => {
            const isAbortError = error instanceof DOMException && error.name === 'AbortError';
            const errorMessage = '[handleShareApi] Error occured when trying to use Share API';

            // User closes the sharing component
            if (isAbortError) {
                logWarn(errorMessage, { error });
                abortCallback?.();

                return;
            }

            logError(errorMessage, { error });
            nonShareApiCallback();
        });
};
