import type { InterestRate } from '@config/finance/financeConfig';
import { FINANCE_CONFIG } from '@config/finance/financeConfig';
import { SITE_CONFIG } from '@config/siteConfig';
import { calculateMortgage } from '@domains/shared/helpers/calculateMortgage';
import { getClampedValue } from '@domains/shared/helpers/getClampedValue';
import { formatPrice } from '@domains/shared/helpers/price/formatPrice';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useEffect, useMemo, useState } from 'react';
import type { Control, UseFormClearErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import { useForm } from 'react-hook-form';
import type { SingleValue } from 'react-select';

import { DEFAULT_FIELD_OPTION } from '../consts/fieldOption';
import { FIELDS_NAME } from '../consts/fieldsName';
import type { DefaultValues, InterestRateSelectOption } from '../types';

interface Props {
    propertyPrice: number;
    interestRate: InterestRate;
    selectedCurrency?: string;
}

export interface OwnContributionFieldOptions {
    min: number;
    max: number;
    step: number;
    suffix: string;
}

interface ReturnedValue {
    control: Control<DefaultValues>;
    register: UseFormRegister<DefaultValues>;
    clearErrors: UseFormClearErrors<DefaultValues>;
    setValue: UseFormSetValue<DefaultValues>;
    propertyPriceWatched: number;
    ownContributionPercentWatched: number;
    shouldRecalculateOwnContribution: boolean;
    setShouldRecalculateOwnContribution: Dispatch<SetStateAction<boolean>>;
    ownContributionFieldOptions: OwnContributionFieldOptions;
    ownContributionWatched: number;
    loanTermWatched: number;
    interestRateSelectWatched: InterestRateSelectOption;
    interestRateWatched: number;
    interestRateSelectOptions: InterestRateSelectOption[];
    onChangeInterestRateSelect: (selectedOption: SingleValue<InterestRateSelectOption>) => void;
    formattedLoanAmount: string;
    formattedRepayment: string;
    repayment: number;
    formattedInterest: string;
}

const { currency } = SITE_CONFIG.defaultUnits;

export const useMortgageCalculation = ({
    propertyPrice,
    interestRate,
    selectedCurrency = currency,
}: Props): ReturnedValue => {
    const [t] = useTranslations();
    const { lang } = useSiteSettings();
    const { updateDefaultAdditionalData } = useTracking();
    const { calculator } = FINANCE_CONFIG;
    const initialOwnContributionPercent = calculator ? calculator.ownContributionPercent * 100 : 20;
    const [shouldRecalculateOwnContribution, setShouldRecalculateOwnContribution] = useState(true);
    const interestRateSelectOptions: InterestRateSelectOption[] = useMemo(
        () => [
            {
                label: 'frontend.finance.mortgage-calculator.interest-rate-select-lowest',
                value: String(interestRate.lowest),
                type: 'lowest',
            },
            {
                label: 'frontend.finance.mortgage-calculator.interest-rate-select-average',
                value: String(interestRate.average),
                type: 'average',
            },
            {
                label: 'frontend.finance.mortgage-calculator.interest-rate-select-custom',
                value: '',
                type: 'custom',
            },
        ],
        [interestRate],
    );

    const defaultValues = useMemo(
        () => ({
            [FIELDS_NAME.propertyPrice]: propertyPrice,
            [FIELDS_NAME.ownContribution]: Number((propertyPrice * (initialOwnContributionPercent / 100)).toFixed(2)),
            [FIELDS_NAME.ownContributionPercent]: initialOwnContributionPercent,
            [FIELDS_NAME.loanTerm]: calculator?.loanTerm,
            [FIELDS_NAME.interestRate]: interestRate?.lowest || calculator?.interestRate.lowest,
            [FIELDS_NAME.interestRateSelect]: interestRateSelectOptions[0],
        }),
        [
            calculator?.interestRate.lowest,
            calculator?.loanTerm,
            initialOwnContributionPercent,
            interestRate?.lowest,
            interestRateSelectOptions,
            propertyPrice,
        ],
    );

    const { control, setValue, watch, register, clearErrors, setFocus, reset } = useForm<DefaultValues>({
        defaultValues,
    });

    const propertyPriceWatched = watch(FIELDS_NAME.propertyPrice);
    const ownContributionWatched = watch(FIELDS_NAME.ownContribution);
    const ownContributionPercentWatched = watch(FIELDS_NAME.ownContributionPercent);
    const loanTermWatched = watch(FIELDS_NAME.loanTerm);
    const interestRateWatched = watch(FIELDS_NAME.interestRate);
    const interestRateSelectWatched = watch(FIELDS_NAME.interestRateSelect);

    const clampedPropertyPrice = getClampedValue(
        propertyPriceWatched,
        DEFAULT_FIELD_OPTION[FIELDS_NAME.propertyPrice].min,
        DEFAULT_FIELD_OPTION[FIELDS_NAME.propertyPrice].max,
    );
    const clampedLoanTerm = getClampedValue(
        loanTermWatched,
        DEFAULT_FIELD_OPTION[FIELDS_NAME.loanTerm].min,
        DEFAULT_FIELD_OPTION[FIELDS_NAME.loanTerm].max,
    );
    const clampedInterestRate = getClampedValue(
        interestRateWatched,
        DEFAULT_FIELD_OPTION[FIELDS_NAME.interestRate].min,
        DEFAULT_FIELD_OPTION[FIELDS_NAME.interestRate].max,
    );

    const ownContributionFieldOptions = useMemo(
        () => ({
            ...DEFAULT_FIELD_OPTION[FIELDS_NAME.ownContribution],
            min: propertyPriceWatched * 0.1,
            max: propertyPriceWatched * 0.9,
            step: propertyPriceWatched * 0.01,
            suffix: selectedCurrency,
        }),
        [propertyPriceWatched, selectedCurrency],
    );

    useEffect(() => {
        reset(defaultValues);
    }, [defaultValues, reset]);

    useEffect(() => {
        updateDefaultAdditionalData(
            {
                calc_value_input: propertyPriceWatched,
                calc_deposit_input: ownContributionWatched,
                calc_interest_rate_input: interestRateWatched,
                calc_term_input: loanTermWatched,
            },
            true,
        );
    }, [
        propertyPriceWatched,
        ownContributionWatched,
        interestRateWatched,
        loanTermWatched,
        updateDefaultAdditionalData,
    ]);

    useEffect(() => {
        const ownContributionValue = Number(((propertyPriceWatched * ownContributionPercentWatched) / 100).toFixed(2));

        if (shouldRecalculateOwnContribution) {
            setValue(FIELDS_NAME.ownContribution, ownContributionValue);
        } else {
            const calculatedOwnContributionPercent = getClampedValue(
                (ownContributionWatched / propertyPriceWatched) * 100,
                10,
                90,
            );
            setValue(FIELDS_NAME.ownContributionPercent, Math.round(calculatedOwnContributionPercent));
        }
    }, [
        ownContributionPercentWatched,
        ownContributionFieldOptions,
        propertyPriceWatched,
        shouldRecalculateOwnContribution,
        ownContributionWatched,
        setValue,
        watch,
    ]);

    useEffect(() => {
        const interestRateOption = interestRateSelectOptions.find(
            (option) => Number(option.value) === interestRateWatched,
        );
        setValue(FIELDS_NAME.interestRateSelect, interestRateOption || interestRateSelectOptions[2]);
    }, [interestRateWatched, setValue, t, interestRateSelectOptions]);

    const loanAmount = useMemo(() => {
        const calculatedLoanAmount = clampedPropertyPrice - ownContributionWatched;

        if (calculatedLoanAmount < 0) {
            return 0;
        }

        return calculatedLoanAmount;
    }, [clampedPropertyPrice, ownContributionWatched]);

    const repayment = useMemo(() => {
        const calculatedRepayment = calculateMortgage(
            clampedInterestRate / 100,
            clampedLoanTerm,
            clampedPropertyPrice * (1 - ownContributionPercentWatched / 100),
            0,
            0,
        );

        if (calculatedRepayment < 0 || Number.isNaN(calculatedRepayment)) {
            return 0;
        }

        return calculatedRepayment;
    }, [clampedInterestRate, clampedLoanTerm, clampedPropertyPrice, ownContributionPercentWatched]);

    const interest = useMemo(
        () => repayment * (clampedLoanTerm * 12) - loanAmount,
        [repayment, clampedLoanTerm, loanAmount],
    );

    const onChangeInterestRateSelect = useCallback(
        (selectedOption: SingleValue<InterestRateSelectOption>): void => {
            if (selectedOption && !Number.isNaN(selectedOption.value)) {
                setValue(FIELDS_NAME.interestRate, Number(selectedOption.value));
                setValue(FIELDS_NAME.interestRateSelect, selectedOption);
            }
            if (selectedOption?.type === 'custom') {
                setFocus(FIELDS_NAME.interestRate);
            }
        },
        [setFocus, setValue],
    );

    const repaymentValue = Math.round(repayment);
    const formattedRepayment = formatPrice({
        price: repayment,
        lang,
        shouldFormatAsInteger: true,
        currency: selectedCurrency,
    });
    const formattedLoanAmount = formatPrice({
        price: loanAmount,
        lang,
        shouldFormatAsInteger: true,
        currency: selectedCurrency,
    });
    const formattedInterest = formatPrice({
        price: interest,
        lang,
        shouldFormatAsInteger: true,
        currency: selectedCurrency,
    });

    return {
        control,
        register,
        clearErrors,
        setValue,
        propertyPriceWatched,
        ownContributionPercentWatched,
        shouldRecalculateOwnContribution,
        setShouldRecalculateOwnContribution,
        ownContributionFieldOptions,
        ownContributionWatched,
        loanTermWatched,
        interestRateSelectWatched,
        interestRateWatched,
        interestRateSelectOptions,
        onChangeInterestRateSelect,
        formattedLoanAmount,
        formattedRepayment,
        repayment: repaymentValue,
        formattedInterest,
    };
};
