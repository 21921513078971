import { SITE_CONFIG } from '@config/siteConfig';
import { ButtonVariant } from '@domains/shared/components/Button/buttonVariant';
import { Avatar } from '@domains/shared/components/dataDisplay/Avatar/Avatar';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { faPhoneAlt } from '@fortawesome/free-solid-svg-icons/faPhoneAlt';
import { usePhoneRevealStatistics } from '@lib/tracking/usePhoneRevealStatistics';
import { useTracking } from '@lib/tracking/useTracking';
import type { FC } from 'react';

import { getTrackingTouchPoint } from '../core/ContactForm/helpers/getTrackingTouchPoint';
import { CONTACT_FORM_TOUCH_POINT_BUTTON } from '../types/contactForm';
import type { ContactPersonBannerProps } from '../types/contactPersonBanner';
import {
    AdTypeContainer,
    AvatarContainer,
    ContactButton,
    ContactPersonName,
    ContainerDefault,
    ContainerStandAlone,
    LogotypeContainer,
    PhoneIcon,
    PhoneNumberContainer,
    PhoneNumberDisplay,
} from './ContactPersonBanner.theme';

const ADVERT_TYPE_LABEL: Record<string, string> = {
    private: 'frontend.ad.contact-form.ad-type-private',
    privateBusiness: 'frontend.ad.contact-form.ad-type-business',
    agency: 'frontend.ad.contact-form.ad-type-business',
    developer: 'frontend.ad.contact-form.ad-type-developer',
} as const;

export const ContactPersonBanner: FC<ContactPersonBannerProps> = ({
    advertId,
    advertiserType,
    contactDetails,
    ownerType,
    agencyLogoUrl,
    contactFormVariant,
    onContactButtonClick,
    onPhoneNumberClick,
    additionalTrackingData = {},
}) => {
    const [t] = useTranslations();
    const {
        phoneNumbers: { country, countryCode },
    } = SITE_CONFIG;

    const { name, phones, type: contactDetailsType, imageUrl } = contactDetails;

    const { trackEvent } = useTracking();
    const { updateShowPhoneStatistics } = usePhoneRevealStatistics();

    const isStandAlone = !contactFormVariant;
    const Container = contactFormVariant ? ContainerDefault : ContainerStandAlone;
    let adTypeLabelText = t(ADVERT_TYPE_LABEL[ownerType]);

    if (contactDetailsType === 'private' && advertiserType === 'business') {
        adTypeLabelText = t(ADVERT_TYPE_LABEL.privateBusiness);
    }

    const handleContactButtonClick = (): void => {
        onContactButtonClick?.();
        trackEvent('reply_message_form_click', {
            touch_point_button: CONTACT_FORM_TOUCH_POINT_BUTTON.contactInfo,
        });
    };

    const handlePhoneNumberCallback = (): void => {
        onPhoneNumberClick?.();
        updateShowPhoneStatistics({
            advertId,
        });
        trackEvent('reply_phone_show', {
            ...additionalTrackingData,
            touch_point_button: getTrackingTouchPoint({
                contactFormVariant,
            }),
        });
    };

    return (
        <Container>
            <AvatarContainer>
                <Avatar url={imageUrl} avatarSize={42} />
            </AvatarContainer>
            <ContactPersonName>{name}</ContactPersonName>
            <AdTypeContainer>{adTypeLabelText}</AdTypeContainer>
            {isStandAlone && agencyLogoUrl ? <LogotypeContainer src={agencyLogoUrl} alt={name} /> : null}
            <PhoneNumberContainer>
                {isStandAlone ? <PhoneIcon icon={faPhoneAlt} size="lg" /> : null}
                {phones?.length ? (
                    <PhoneNumberDisplay
                        className="phoneNumber"
                        number={phones[0]}
                        nationalCountryCode={countryCode}
                        country={country}
                        onRevealCallback={handlePhoneNumberCallback}
                        onPhoneNumberClick={(): void => {
                            trackEvent('reply_phone_call', {
                                ...additionalTrackingData,
                            });
                        }}
                        variant={isStandAlone ? 'standAlone' : 'default'}
                    />
                ) : null}
            </PhoneNumberContainer>
            {isStandAlone ? (
                <ContactButton variant={ButtonVariant.Primary} onClick={handleContactButtonClick}>
                    {t('frontend.ad.contact-person-banner.send-message')}
                </ContactButton>
            ) : null}
        </Container>
    );
};

export default ContactPersonBanner;
