import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import { Popover } from '@nexus/lib-react/dist/core/Popover';
import { P3 } from '@nexus/lib-react/dist/core/Typography';

export const StyledPopover = styled(Popover)`
    --bgColor: ${theme.colors['background-global-inverse']};

    max-width: 300px;
    padding: ${theme.space['8']} ${theme.space['16']};

    @media ${theme.mediaQueries.smMin} {
        max-width: 400px;
    }
`;

export const ExternalId = styled(P3)`
    width: 250px;
    margin-top: ${theme.space['8']};
    overflow: hidden;
    color: ${theme.colors['text-global-secondary']};
    font-size: ${theme.fontSizes['14']};
    text-overflow: ellipsis;
    white-space: nowrap;
`;
