export const getButtonLabel = (
    viewType: 'CONTACT_FORM' | 'AD',
    saveSearchExists: boolean,
    isDesktop: boolean,
): string => {
    if (viewType === 'CONTACT_FORM') {
        return 'frontend.ad.subscribe-ad-section.similar-offers-button-contact-form';
    }

    if (saveSearchExists) {
        return isDesktop
            ? 'frontend.ad.similar-offers-subscription.button-disabled-desktop'
            : 'frontend.ad.similar-offers-subscription.button-disabled-mobile';
    }

    return 'frontend.ad.subscribe-ad-section.similar-offers-button-2';
};
