import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import Calculator from '@nexus/lib-react/dist/core/Icon/icons/default/Calculator';
import { Segment, SegmentedControl } from '@nexus/lib-react/dist/core/SegmentedControl';
import { H4 } from '@nexus/lib-react/dist/core/Typography';

export const Title = styled.div`
    display: flex;
    position: relative;
    bottom: ${theme.space[6]};
    flex-direction: column;
    color: ${theme.colors['text-brand-primary']};
    gap: ${theme.space[24]};

    @media ${theme.mediaQueries.mdMin} {
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

export const StyledIcon = styled(Calculator)`
    font-weight: ${theme.fontWeights.bold};
`;

export const Paragraph = styled.p`
    font-size: ${theme.fontSizes.p2};
`;

export const HeadingWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: ${theme.space[8]};
`;

export const Heading = styled(H4)`
    display: inline;
    margin-bottom: 0;
`;

export const StyledSegmentedControl = styled(SegmentedControl)`
    @media ${theme.mediaQueries.md} {
        margin-right: auto;
    }
`;

export const StyledSegment = styled(Segment)`
    min-width: ${theme.sizes[80]};
`;
