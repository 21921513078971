import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import ThumbsDown from '@nexus/lib-react/dist/core/Icon/icons/default/ThumbsDown';
import ThumbsUp from '@nexus/lib-react/dist/core/Icon/icons/default/ThumbsUp';
import { P3 } from '@nexus/lib-react/dist/core/Typography';
import {
    ButtonsWrapper,
    Container,
    ErrorInfo,
    StyledButton,
} from '@widgets/CompareAds/CompareAdsDialog/components/RateButtons/RateButtons.theme';
import type { JSX } from 'react';

export type SelectedRate = 'yes' | 'no' | null;

interface Props {
    selectedRate: SelectedRate;
    onChangeSelectedRate: (rate: SelectedRate) => void;
    showError: boolean;
}

export const RateButtons = ({ selectedRate, onChangeSelectedRate, showError }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();

    const handleClick = (rate: SelectedRate): void => {
        trackEvent('compare_ad_answer', { touch_point_button: rate });
        onChangeSelectedRate(rate);
    };

    const shouldShowError = !selectedRate && showError;

    return (
        <Container>
            <P3 variant="bold">{t('frontend.widget.compare-ads.rate-buttons-heading')}</P3>
            <ButtonsWrapper>
                <StyledButton
                    variant="secondary"
                    size="large"
                    fullWidth
                    prefixIcon={ThumbsDown}
                    onClick={() => handleClick('no')}
                    isSelected={selectedRate === 'no'}
                >
                    {t('frontend.widget.compare-ads.rate-buttons-no')}
                </StyledButton>
                <StyledButton
                    variant="secondary"
                    size="large"
                    fullWidth
                    prefixIcon={ThumbsUp}
                    onClick={() => handleClick('yes')}
                    isSelected={selectedRate === 'yes'}
                >
                    {t('frontend.widget.compare-ads.rate-buttons-yes')}
                </StyledButton>
            </ButtonsWrapper>
            {shouldShowError ? <ErrorInfo>{t('frontend.widget.compare-ads.rate-buttons-error')}</ErrorInfo> : null}
        </Container>
    );
};
