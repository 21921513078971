import { getDescription } from '@domains/ad/components/Table/helpers/getDescription';
import { getDescriptionForRooms } from '@domains/ad/components/Table/tableDescriptionFormaters';
import { Item, ListContainer, StyledCheckIcon } from '@domains/ad/nexusComponents/AdDetails/AdDetails.theme';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { Locale } from '@lib/i18n/types/locale';
import type { AdInformation, ExtendedAdInformation, RoomsValue } from '@type/ad/adDetails';

const checkIsStringArray = (array: RoomsValue[] | string[]): array is string[] => {
    return !array.some((value) => typeof value !== 'string');
};

export const AdDetailItemContent = ({
    adDetail,
    isInvestment,
    lang,
}: {
    adDetail?: AdInformation | ExtendedAdInformation;
    isInvestment: boolean;
    lang: Locale;
}): JSX.Element | null => {
    const [t] = useTranslations();
    // eslint-disable-next-line no-restricted-syntax -- we will update these keys in the future
    const translationPrefix = 'frontend.ad.table-information-';
    const numberOfUserLicenseKey = 'number_of_user_license';
    const stringValuesWithUnits = ['terrain_area', 'dimensions'];

    if (!adDetail || adDetail.values.length === 0) {
        return <Item isNoInformationLabel>{t('frontend.ad-overview.table.no-information')}</Item>;
    }

    if (adDetail.label === numberOfUserLicenseKey) {
        // we have two different types of user licence number: "NUMBER_OF_USER_LICENCE_LABEL::number" or only "number"
        const values = (adDetail.values?.[0] as string | undefined)?.split('::');
        if (values && values.length > 0) {
            return <Item>{values.length === 2 ? values[1] : values[0]}</Item>;
        }

        return null;
    }

    if (stringValuesWithUnits.includes(adDetail.label) && adDetail.values?.length > 0) {
        return (
            <Item>
                {adDetail.values[0] as string}
                &nbsp;
                {adDetail.unit}
            </Item>
        );
    }

    if (checkIsStringArray(adDetail.values)) {
        const descriptionArray = getDescription({
            values: adDetail.values,
            label: adDetail.label,
            unit: adDetail.unit,
            isInvestment,
            lang,
            translationPrefix,
            t,
            shouldHaveNewTranslationKeys: false,
            shouldUseTypologyInsteadOfNumberOfRooms: false,
        })?.split(',');

        if (descriptionArray && descriptionArray.length > 0) {
            return descriptionArray.length === 1 ? (
                <Item>{descriptionArray[0]}</Item>
            ) : (
                <Item>
                    {descriptionArray.map((value) => (
                        <ListContainer key={value.trim()}>
                            <StyledCheckIcon size="icon-small" />
                            {value}
                        </ListContainer>
                    ))}
                </Item>
            );
        } else {
            return <Item isNoInformationLabel>{t('frontend.ad-overview.table.no-information')}</Item>;
        }
    }

    return <Item>{getDescriptionForRooms({ values: adDetail.values, translationPrefix, t })}</Item>;
};
