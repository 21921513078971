import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { P3 } from '@nexus/lib-react/dist/core/Typography';
import {
    Container,
    ErrorInfo,
    StyledTextArea,
} from '@widgets/CompareAds/CompareAdsDialog/components/Message/Message.theme';
import type { SelectedRate } from '@widgets/CompareAds/CompareAdsDialog/components/RateButtons/RateButtons';
import {
    isMessageValid,
    MAX_MESSAGE_LENGTH,
    MIN_MESSAGE_LENGTH,
} from '@widgets/CompareAds/CompareAdsDialog/helpers/isMessageValid';
import type { JSX } from 'react';

interface Props {
    message: string;
    selectedRate: SelectedRate;
    onMessageChange: (text: string) => void;
    showError: boolean;
}

export const Message = ({ message, selectedRate, onMessageChange, showError }: Props): JSX.Element => {
    const [t] = useTranslations();
    const shouldShowError = !isMessageValid(message, selectedRate) && showError;

    return (
        <Container>
            <P3 variant="bold">{t('frontend.widget.compare-ads.message-heading')}</P3>
            <StyledTextArea
                value={message}
                onChange={({ target }) => onMessageChange(target.value)}
                placeholder={t('frontend.widget.compare-ads.message-placeholder')}
                minLength={MIN_MESSAGE_LENGTH}
                maxLength={MAX_MESSAGE_LENGTH}
                showSymbolsCount
                validationStatus={shouldShowError ? 'hasError' : undefined}
            />
            {shouldShowError ? (
                <ErrorInfo>
                    {t('frontend.widget.compare-ads.message-error', { value: `${MIN_MESSAGE_LENGTH}` })}
                </ErrorInfo>
            ) : null}
        </Container>
    );
};
