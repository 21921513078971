import type { AdFullInformation } from '@domains/ad/types/adFullInformation';
import { useSiteSettings } from '@domains/shared/hooks/useSiteSettings/useSiteSettings';
import type { JSX } from 'react';
import React from 'react';

import { AdDetailItem } from './components/AdDetailItem';
import { AdDetailItemContent } from './components/AdDetailItemContent';

interface Props {
    adInformation: AdFullInformation;
    detailsKeys?: Record<string, string>;
    testId: string;
}

export const AdDetailsItems = ({ adInformation, detailsKeys = {}, testId }: Props): JSX.Element | null => {
    const { lang } = useSiteSettings();

    const shouldShowItems = Object.keys(detailsKeys)?.length > 0 && adInformation?.length > 0;

    if (shouldShowItems) {
        const items = Object.values(detailsKeys).map((informationKey, index): JSX.Element => {
            const isLastElement = index === Object.keys(detailsKeys).length - 1;
            const adDetail = adInformation.find((information) => {
                return information.label === informationKey;
            });

            return (
                <AdDetailItem key={`${informationKey}::${index}`} label={informationKey} showDivider={!isLastElement}>
                    <AdDetailItemContent adDetail={adDetail} isInvestment={false} lang={lang} />
                </AdDetailItem>
            );
        });

        return <div data-testid={testId}>{items}</div>;
    }

    return null;
};
