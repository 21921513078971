import { getAdCharacteristic } from '@domains/ad/helpers/getAdCharacteristic';
import { getIdForLocationType } from '@domains/ad/helpers/getIdForLocationType';
import type { Ad } from '@domains/ad/types/Ad';
import { CATEGORY_ID } from '@domains/shared/types/categoryId';
import type { Locale } from '@lib/i18n/types/locale';
import type { Market } from '@type/search/filters/market';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

/**
 * Array with the cities where the feature can be carries out
 *
 * 1 - Poznań
 * 26 - Warszawa
 * 181 - Kielce
 * 166 - Tychy
 * 190 - Lublin
 * 201 - Rzeszów
 * 204 - Białystok
 * 1004 - Łódź
 * 213 - Szczecin
 * 134 - Dąbrowa Górnicza
 * 38 - Kraków
 * 193 - Zielona Góra
 * 40 - Gdańsk
 * 39 - Wrocław
 * 164 - Piotrków Trybunalski
 * 210 - Olsztyn
 * 197 - Siedlce
 * 172 - Katowice
 * 206 - Gdynia
 * 186 - Toruń
 * 207 - Słupsk
 * 198 - Opole
 * 316 - Leszno
 * 178 - Bielsko-Biała
 * 184 - Bydgoszcz
 * 183 - Wałbrzych
 * 169 - Ruda Śląska
 * 140 - Bytom
 * 177 - Chorzów
 * 192 - Gorzów Wielkopolski
 */
const INCLUDED_CITIES = new Set([
    '1',
    '26',
    '181',
    '166',
    '190',
    '201',
    '204',
    '1004',
    '213',
    '134',
    '38',
    '193',
    '40',
    '39',
    '164',
    '210',
    '197',
    '172',
    '206',
    '186',
    '207',
    '198',
    '316',
    '178',
    '184',
    '183',
    '169',
    '140',
    '177',
    '192',
]);

/**
 * A method for checking when an experiment should be assignment
 *
 * @param adData
 * @param lang
 * @param isAdAvmModuleEnabled?
 * @param
 * @returns boolean
 */
export const shouldUseAdAvmModule = (
    adData: Ad,
    lang: Locale,
    isREMT569VariantB: boolean,
    isAdAvmModuleEnabled?: boolean | null,
): boolean => {
    if (!isAdAvmModuleEnabled || lang !== 'pl') {
        return false;
    }

    dayjs.extend(relativeTime);
    const { characteristics, category, createdAt, location } = adData;
    const getData = getAdCharacteristic(characteristics);
    const adMarket = (getData('market')?.value.toUpperCase() as Market) || '';
    const adCategoryId = category.id;
    const adLifespan = dayjs().diff(createdAt, 'day');
    const isIncludedLocation =
        getIdForLocationType(location, 'city')?.some((adCity) => INCLUDED_CITIES.has(adCity)) || false;
    const isSecondaryMarket = adMarket === 'SECONDARY';
    const isApartmentToSale = adCategoryId === CATEGORY_ID.apartmentsForSale;
    const isApartmentToRent = adCategoryId === CATEGORY_ID.apartmentsForRent;
    const isAdExistMoreThanDay = adLifespan >= 1;

    if (isApartmentToRent) {
        return isREMT569VariantB && isIncludedLocation && isAdExistMoreThanDay;
    }

    return isApartmentToSale && isSecondaryMarket && isIncludedLocation && isAdExistMoreThanDay;
};
