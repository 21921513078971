import { EXPERIMENT } from '@domains/ad/consts/experiment';
import {
    Container,
    DescriptionWrapper,
    StyledDivider,
    Title,
} from '@domains/ad/nexusComponents/AdDescription/AdDescription.theme';
import { AdMetadata } from '@domains/ad/nexusComponents/AdMetadata/AdMetadata';
import { phoneNumberParser } from '@domains/ad/shared/helpers/findPhoneNumber/findPhoneNumber';
import type { Ad } from '@domains/ad/types/Ad';
import { RWDContext } from '@domains/shared/contexts/RWDContext';
import { useTrackImpression } from '@domains/shared/hooks/useTrackImpression/useTrackImpression';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useExperiments } from '@lib/experiments/client/ExperimentsProvider';
import { useTracking } from '@lib/tracking/useTracking';
import { Button } from '@nexus/lib-react/dist/core/Button';
import ArrowDown from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowDown';
import ArrowUp from '@nexus/lib-react/dist/core/Icon/icons/default/ArrowUp';
import { H4 } from '@nexus/lib-react/dist/core/Typography';
import type { JSX } from 'react';
import { useContext, useMemo, useRef, useState } from 'react';

interface Props {
    ad: Ad;
}

export const AdDescription = ({ ad }: Props): JSX.Element => {
    const [t] = useTranslations();
    const { trackEvent } = useTracking();
    const { isDesktop } = useContext(RWDContext);
    const [isExpanded, setIsExpanded] = useState(false);
    const shouldTrim = !isExpanded && ad.description.length >= (isDesktop ? 200 : 115);
    const shouldShowExpandButton = shouldTrim || isExpanded;

    const description = useMemo(() => phoneNumberParser(ad.description, ''), [ad.description]);

    const adDescriptionRef = useRef<HTMLDivElement | null>(null);
    useTrackImpression(adDescriptionRef, () => {
        trackEvent('ad_page_impression', {
            touch_point_button: 'description',
        });
    });

    const handleClick = (): void => {
        trackEvent('ad_description_click', {
            touch_point_button: isExpanded ? 'collapse' : 'expand',
        });
        setIsExpanded((state) => !state);
    };

    const { isVariantEnabled } = useExperiments();
    const isEURE25554VariantB = isVariantEnabled(EXPERIMENT.eure25554, 'b');
    const isEURE25554VariantC = isVariantEnabled(EXPERIMENT.eure25554, 'c');

    return (
        <Container ref={adDescriptionRef}>
            {isEURE25554VariantC ? <H4>{ad.title}</H4> : <H4>{t('frontend.ad.content.description')}</H4>}
            {isEURE25554VariantB && <Title>{ad.title}</Title>}
            <DescriptionWrapper
                data-cy="adPageAdDescription"
                data-testid="ad-description-wrapper"
                dangerouslySetInnerHTML={{ __html: description }}
                shouldTrim={shouldTrim}
            />
            {shouldShowExpandButton ? (
                <Button
                    variant="flat"
                    suffixIcon={isExpanded ? ArrowUp : ArrowDown}
                    onClick={handleClick}
                    data-testid="expand-button"
                >
                    {isExpanded
                        ? t('frontend.ad.expandable-content.show-less')
                        : t('frontend.ad.expandable-content.show-more')}
                </Button>
            ) : null}

            {!isEURE25554VariantC && (
                <>
                    <StyledDivider variant="secondary-light" />
                    <AdMetadata ad={ad} />
                </>
            )}
        </Container>
    );
};
