import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';
import HeartFilled from '@nexus/lib-react/dist/core/Icon/icons/default/HeartFilled';

export const ButtonContent = styled.span`
    display: flex;
    gap: ${theme.space['8']};
    align-items: center;
    justify-content: center;
`;

export const StyledHeartFilled = styled(HeartFilled)`
    path {
        fill: ${theme.colors['background-brand-secondary']};
    }
`;
