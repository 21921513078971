import type { AdMortgageSimulatorConfig } from '@config/ad/adConfig';
import { getPublicEnvConfig } from '@domains/shared/helpers/getEnvConfig';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import { useTracking } from '@lib/tracking/useTracking';
import { Button } from '@nexus/lib-react/dist/core/Button';
import type { JSX } from 'react';

import {
    Container,
    Content,
    Header,
    Label,
    LogoSection,
    LogoWrapper,
    Paragraph,
    TextWrapper,
} from './BikSection.theme';
import { useAdFinanceLinkGenerator } from './hooks/useAdFinanceLinkGenerator';

export const BikSection = ({
    shouldUseAdditionalParams,
    utmCampaign,
    utmMedium,
    utmSource,
    callRequestPageUrl,
}: AdMortgageSimulatorConfig): JSX.Element => {
    const { staticAssetsPrefix } = getPublicEnvConfig();
    const { trackEvent } = useTracking();
    const [t] = useTranslations();

    const queryParams = {
        utmSource,
        utmCampaign,
        utmMedium,
        shouldUseAdditionalParams,
    };

    const callRequestUrl = useAdFinanceLinkGenerator({
        baseUrl: callRequestPageUrl || '',
        utmContent: t('frontend.ad.mortgage-section.bik-promotion-lead-cta'),
        ...queryParams,
        utmCampaign: 'bik',
    });

    const handleClick = (): void => {
        trackEvent('finance_lead_form_click', {
            touch_point_button: 'next_to_finance_link',
        });
        window.open(callRequestUrl.as, '_blank');
    };

    return (
        <Container>
            <Header>{t('frontend.widgets.mortgage-calculator.ad-variant-bik-section-header')}</Header>
            <Content>
                <TextWrapper>
                    <Paragraph>{t('frontend.widgets.mortgage-calculator.ad-variant-bik-section-paragraph')}</Paragraph>
                    <div>
                        <Button variant="tertiary" size="small" onClick={handleClick}>
                            {t('frontend.widgets.mortgage-calculator.ad-variant-bik-section-link')}
                        </Button>
                    </div>
                </TextWrapper>
                <LogoSection>
                    <LogoWrapper>
                        <img src={`${staticAssetsPrefix}/images/ad/bikLogoMedium.webp`} width="131" alt="BIK logo" />
                    </LogoWrapper>
                    <Label>{t('frontend.widgets.mortgage-calculator.ad-variant-bik-section-logo-label')}</Label>
                </LogoSection>
            </Content>
        </Container>
    );
};
