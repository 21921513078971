import { useEffect, useState } from 'react';

export const useDeviceOrientation = (): boolean => {
    const [isLandscape, setIsLandscape] = useState(false);

    useEffect(() => {
        if (typeof window !== 'undefined') {
            const mediaQueryList = window.matchMedia('(orientation: landscape)');
            const handleOrientationChange = (event: MediaQueryListEvent | MediaQueryList): void => {
                setIsLandscape(event.matches);
            };

            handleOrientationChange(mediaQueryList);

            if (mediaQueryList.addEventListener) {
                mediaQueryList.addEventListener('change', handleOrientationChange);
            }

            return (): void => {
                // @ts-expect-error Description:
                // Typescript thinks that method 'addEventListener' always exist. Unluckily on Safari 13 it doesnt exist and application is crashing.
                // This is a workaround
                if (mediaQueryList.addEventListener) {
                    mediaQueryList.removeEventListener('change', handleOrientationChange);
                }
            };
        }
    }, []);

    return isLandscape;
};
