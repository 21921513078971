import styled from '@emotion/styled';
import { theme } from '@lib/styles/themes/nexus';

export const Container = styled.div`
    padding-top: ${theme.space[24]};
    border-top: ${theme.borderWidths[1]} ${theme.borderStyles.solid} ${theme.colors['borders-global-tertiary']};
`;

export const MultimediaList = styled.ul`
    display: flex;
    flex-wrap: wrap;
    margin: 0;
    padding: 0;
    gap: ${theme.space[24]};
`;

export const MultimediaItem = styled.li`
    list-style: none;

    @media ${theme.mediaQueries.smMin} {
        padding: ${theme.space[8]} 0;
    }
`;

export const MultimediaItemButton = styled.button`
    display: flex;
    padding: 0;
    border: none;
    background: transparent;
    color: ${theme.colors['text-brand-primary']};
    text-decoration: none;
    cursor: pointer;
    gap: ${theme.space[8]};
`;

export const MultimediaItemLabel = styled.span`
    display: block;
    padding-bottom: ${theme.space[8]};
    border-bottom: ${theme.borderWidths[2]} ${theme.borderStyles.solid} ${theme.colors['borders-brand-primary']};
    font-size: ${theme.fontSizes[14]};
    font-weight: ${theme.fontWeights.bold};
    line-height: ${theme.lineHeights[14]};
`;
