import { useTracking } from '@lib/tracking/useTracking';
import type { JSX } from 'react';

import { Container, StyledLink, StyledMapViewIcon } from './MapLink.theme';

interface Props {
    address?: string;
}

export const MapLink = ({ address }: Props): JSX.Element | null => {
    const { trackEvent } = useTracking();

    if (!address) {
        return null;
    }

    const handleClick = (): void => trackEvent('show_address');

    return (
        <Container data-testid="map-link-container">
            <StyledLink href="#map" onClick={handleClick}>
                <StyledMapViewIcon />
                {address}
            </StyledLink>
        </Container>
    );
};
