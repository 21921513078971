import { useOpenDateFormatter } from '@domains/ad/hooks/useOpenDateFormatter';
import { useTranslations } from '@domains/shared/hooks/useTranslations/useTranslations';
import type { OpenDay } from '@type/ad/openDay';
import type { JSX } from 'react';

import { OpenDayTag as Tag } from '../../AdHeader.theme';

export interface OpenDayTagProps {
    openDay: OpenDay | null;
}

export const OpenDayTag = ({ openDay }: OpenDayTagProps): JSX.Element | null => {
    const [t] = useTranslations();
    const { date: rawDate, timeFrom, timeTo } = openDay || {};
    const formatOpenDay = useOpenDateFormatter();

    if (!rawDate) {
        return null;
    }

    const content = `${t('frontend.ad-header.open-day')}: ${formatOpenDay(rawDate)} ${timeFrom} - ${timeTo}`;

    return <Tag content={content} data-testid="open-day-tag" />;
};
