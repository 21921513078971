import type { SimilarAdsViewType } from '@domains/ad/types/similarAdsViewType';
import type { FC, PropsWithChildren } from 'react';
import { createContext, useState } from 'react';

type ModalType = 'login' | 'registration' | 'none';

interface LoginModalContextProps {
    toggleLoginModalState: (newType: ModalType, viewType: SimilarAdsViewType) => void;
    loginModalState: ModalType;
    viewType: SimilarAdsViewType;
}

export const LoginModalContext = createContext<LoginModalContextProps>({
    loginModalState: 'none',
    toggleLoginModalState: () => undefined,
    viewType: 'AD',
});

export const LoginModalContextProvider: FC<PropsWithChildren> = ({ children }) => {
    const [modalType, setModalType] = useState<ModalType>('none');
    const [viewType, setViewType] = useState<SimilarAdsViewType>('AD');

    const toggleLoginModalState = (newModalType: ModalType, newViewType: SimilarAdsViewType): void => {
        setViewType(newViewType);
        setModalType(newModalType);
    };

    return (
        <LoginModalContext.Provider
            value={{
                loginModalState: modalType,
                toggleLoginModalState: toggleLoginModalState,
                viewType: viewType,
            }}
        >
            {children}
        </LoginModalContext.Provider>
    );
};
