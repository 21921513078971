import { SIMILAR_AD_SEARCH_EXISTS_QUERY } from '@domains/ad/graphql/queries/SimilarAdSearchExistsQuery';
import { useAssertGraphqlResponse } from '@lib/graphql/hooks/useAssertGraphqlResponse';
import { normalizeForbiddenAccessQuery } from '@lib/graphql/normalizeForbiddenAccessQuery';
import type { JSX, PropsWithChildren } from 'react';
import { createContext, useEffect, useState } from 'react';
import { useQuery } from 'urql';

interface Props {
    adId: number;
}
interface SavedSimilarAdsSearchContextProps {
    similarAdsSaveSearchExists: boolean;
    updateSavedSimilarAdsSearchStatus: (status: boolean) => void;
}

export const SavedSimilarAdsSearchContext = createContext<SavedSimilarAdsSearchContextProps>({
    similarAdsSaveSearchExists: false,
    updateSavedSimilarAdsSearchStatus: () => undefined,
});

export const SavedSimilarAdsSearchContextProvider = ({ adId, children }: PropsWithChildren<Props>): JSX.Element => {
    const [isSimilarAdsSaveSearchExists, setIsSimilarAdsSaveSearchExists] = useState(false);

    const [{ data, error: graphqlError, fetching, operation }] = useQuery({
        query: SIMILAR_AD_SEARCH_EXISTS_QUERY,
        variables: { adID: adId },
    });

    const response = useAssertGraphqlResponse({
        fetching,
        graphqlError,
        logErrorPrefix: '[SavedSimilarAdsSearchContext]',
        onTypeMismatch: 'DO_NOTHING',
        operation,
        onUserError: 'CHECK_AND_DO_NOTHING',
        data: normalizeForbiddenAccessQuery(data?.similarAdSearchExists, graphqlError),
        expectedTypenames: ['SearchExists'],
    });

    useEffect(() => {
        setIsSimilarAdsSaveSearchExists(!!response?.exists);
    }, [adId, response]);

    return (
        <SavedSimilarAdsSearchContext.Provider
            value={{
                similarAdsSaveSearchExists: isSimilarAdsSaveSearchExists,
                updateSavedSimilarAdsSearchStatus: setIsSimilarAdsSaveSearchExists,
            }}
        >
            {children}
        </SavedSimilarAdsSearchContext.Provider>
    );
};
